.meal_page
  width: 100%
  overflow: hidden

  &__container
    position: relative
    width: 450px
    margin: 20px auto 40px auto
    animation:
      name: fade-in-up
      duration: 0.3s
      timing-function: ease-in-out

    &__ingredients
      padding-bottom: 20px

      &__title
        color: $dark-text
        font:
          size: 18px
          weight: 600
        margin: 20px 0 10px 0

      &__list
        margin: 0
        padding-inline-start: 20px

        &__ingredient
          color: $dark-text
          font-size: 16px
          margin-bottom: 7px
          list-style: none
          line-height: 20px

          &__amount
            display: flex
            color: $blue
            font:
              weight: 500
            margin-left: 10px
            &>span
              margin-right: 0px !important
            [dir="rtl"] & 
              margin-right: 10px
              margin-left: 0px
            

    &__instruction
      margin-bottom: 35px

      &__title
        color: $dark-text
        font:
          size: 18px
          weight: 600
        margin: 10px 0 5px 0

      &__text
        color: $dark-text
        font:
          size: 16px
          weight: normal
        line-height: 20px
        margin-top: 10px
        white-space: pre-wrap

@media(max-width: $mobile)
  .meal_page
    width: 100%
    padding-bottom: 40px

    &__container
      width: 100%
      padding: 0 10px
      margin: 10px auto 40px auto

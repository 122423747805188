.container {
  display: flex;
  flex-direction: column;
  gap: 24px;
  min-height: 400px;
}

.carouselContaiener {
  position: relative;
  max-width: 588px;
  width: 100%;
  overflow: hidden;

  @media (max-width: 458px) {
    max-width: 100vw;
  }
}

.carouselWrap {
  overflow: hidden;
  margin-left: -50px;

  @media (max-width: 458px) {
    margin-left: -98px;
  }

  [dir='rtl'] & {
    margin-left: unset;
    margin-right: -50px;

    @media (max-width: 458px) {
      margin-left: unset;
      margin-right: -98px;
    }
  }
}

.innerWrap {
  display: flex;
  align-items: center;
  gap: 16px;
  transition: transform 0.5s;
  touch-action: none;
}

.leftGradient {
  position: absolute;
  max-width: 75px;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  z-index: 3;
  background: var(--express-plan-carousel-left-gradient);

  @media (max-width: 458px) {
    max-width: 35px;
  }
}

.rightGradient {
  position: absolute;
  max-width: 75px;
  width: 100%;
  height: 100%;
  right: 0;
  top: 0;
  background: var(--express-plan-carousel-right-gradient);

  @media (max-width: 458px) {
    max-width: 35px;
  }
}

.link {
  color: var(--express-plan-carousel-item-link);
}

.indicatorWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
}

.indicator {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background-color: var(--express-plan-carousel-indicator-inactive);
  border: none;
  margin: 0;
  padding: 0;
  padding: 2;
  text-align: inherit;
  font: inherit;
  appearance: none;
  padding: initial;
}

.indicator:hover {
  cursor: pointer;
}

.activeIndicator {
  width: 12px;
  height: 12px;
  padding: 0;
  background-color: var(--express-plan-carousel-indicator-active);
  transition: ease-in-out 0.3s;
}

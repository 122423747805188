.label {
  font-size: 12.3px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.3;
  letter-spacing: 2px;
  color: var(--primary-text-color);
  margin-left: 0;
  margin-bottom: 16px;
  text-transform: uppercase;
}

.value {
  font-size: 14.3px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.68;
  letter-spacing: 0.1px;
  color: var(--color-blue-2);
  margin-left: 0;
}

[dir='rtl'] {
  .text {
    margin-right: 8px;
    margin-left: 0px;
  }

  .benefitIcon {
    margin-left: 4px;
    margin-right: 0px;
  }

  .benefit {
    margin-left: 8px;
    margin-right: 0px;
  }

  .checkmark {
    right: unset;
    left: 0;
  }
}

.form
  &.has-error
    .form__input__text
      border-color: var(--color-red-4)

  &.with-label
    margin-top: 20px

    .form__input
      &.text
        display: flex
        margin: 5px 0 0

      &--label
        font:
          size: 15px
          weight: 600
        color: $dark-text
        margin-left: 20px

        &.error
          color: $red

      &--text-value
        display: block
        font:
          size: 15px
          weight: 600
        color: $blue
        margin: 10px 20px 25px 20px

  &.small
    display: inline-block
    width: 50%

    .form__input__text
      border-top-right-radius: 0
      border-bottom-right-radius: 0
      border-right-width: 1px

  &.two-third
    display: inline-block
    width: 66%

    .form__input__text
      border-top-left-radius: 0
      border-bottom-left-radius: 0
      border-left-width: 1px
      padding-left: 20px

  &__input
    position: relative

    &.text
      margin: 30px auto 0 auto

    &.mini 
      max-width: 160px

      input::-webkit-outer-spin-button,
      input::-webkit-inner-spin-button
        -webkit-appearance: none
        margin: 0
      
      input[type=number] 
        -moz-appearance: textfield

    input
      padding-right: 60px  

    &__input_img
      position: absolute
      left: 22px
      top: 13px
      width: 16px

    &__text
      &__label
        font:
          size: 14px
          weight: 500
        color: $dark-text
        padding-left: 49px

      &.default
        &:focus, &:active, &:hover
          outline: none

        &::-webkit-input-placeholder
          color: $regular-text

        &:-moz-placeholder
         color: $regular-text
         opacity: 1

        &::-moz-placeholder
         color: $regular-text
         opacity: 1

        &:-ms-input-placeholder
          color: $regular-text

        &::-ms-input-placeholder
          color: $regular-text

        &.first
          width: 60%
          padding: 0 8px 0 50px
          display: inline-block

        &.second
          width: 40%
          display: inline-block
          border-radius: 0
          border-left-width: 1px
          padding: 0 8px 0 15px

.validation_error
  font:
    size: 14px
    weight: normal
  color: $red
  padding-left: 20px

@media(max-width: 450px)
  .form
    &.two-third
      width: 51%

@media(max-width: 390px)
  .form
    &__input
      &__text
        &.default
          width: 100%

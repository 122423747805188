.checkmark {
  stroke: #fff;
  animation:
    fill_blue 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
  background: var(--success-icon-background) !important;
}

.circle {
  stroke: none;
}

@keyframes fill_blue {
  100% {
    background: var(--success-icon-background) !important;
  }
}

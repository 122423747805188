.benefit {
  display: flex;
  align-items: flex-start;
}

.benefitSvg {
  height: 14px;
  width: 14px;
  margin-right: 4px;
}

.benefitText {
  color: var(--program-workout-active-benefit-text);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 14px;
}

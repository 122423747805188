.input {
  line-height: normal;
}

.selected {
  background: rgba(255, 158, 0, 0.07) !important;
}

.checkbox {
  color: var(--color-grey-8);
  &:before {
    border: 2px solid var(--color-grey-6);
    background: var(--primary-background-color);
  }

  &:hover:before {
    border: 2px dotted var(--primary-accent-color);
  }

  &:after {
    border: solid var(--primary-accent-color);
  }
}

.input:checked + .checkbox {
  &:before {
    border-color: var(--primary-accent-color);
  }
}

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  .percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    padding-top: 133px;
    z-index: 301;

    font-size: 14.3px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 0.25px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);

    svg {
      transform: rotate(0);
      position: relative;
      margin-top: -10px;
      margin-left: 6px;
    }
  }

  .svgContainer {
    position: absolute;
    width: 130px;
  }

  svg {
    transform: rotate(127deg);
    position: absolute;
    top: 0;
    left: 0;
  }

  svg:first-child > circle {
    fill: none;
    stroke: url(#baseBar);
    stroke-width: 22px;
    stroke-dasharray: 250;
    stroke-opacity: 0.25;
  }

  svg:last-child > circle {
    fill: none;
    stroke: url(#progressBar);
    stroke-width: 22px;
    stroke-dasharray: 452;
    stroke-dashoffset: 452;
    stroke-opacity: 1;
  }

  .heart {
    position: absolute;
    z-index: 300;
    left: 12px;
    top: 83px;
  }

  .lightning {
    position: absolute;
    z-index: 300;
    left: 4px;
    top: 30px;
  }

  .flame {
    position: absolute;
    z-index: 300;
    left: 48px;
  }

  .skull {
    position: absolute;
    z-index: 300;
    left: 96px;
    top: 30px;
  }

  .shield {
    position: absolute;
    z-index: 300;
    left: 85px;
    top: 83px;
  }
}

.prob_zone
  width: 100%
  margin: 50px 0

  &__table
    display: table
    width: 100%

    &--left
      display: table-cell
      vertical-align: middle

    &--right
      display: table-cell
      vertical-align: middle
      text-align: left
      width: 160px

      &__human
        position: relative

      &__mask
        position: absolute
        left: 0
        top: 0
        opacity: 0
        transition: all $animation-speed $animation-type

        &.active
          opacity: 1

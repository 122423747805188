.base {
  box-shadow:
    0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 6px 10px 0 rgba(0, 0, 0, 0.14);
  border: none;
  font-size: 14.3px;
  line-height: 1.4;
  letter-spacing: 0.25px;
  color: var(--color-white);
  border-radius: 0;
  text-align: start;
  padding: 14px 16px;
  padding-top: calc(env(safe-area-inset-top) + 14px);

  @media (min-width: 461px) {
    border-radius: 4px;
  }
}

.success {
  composes: base;

  background-color: var(--color-blue-2);
}

.error {
  composes: base;

  background: var(--color-red);
}

.wrapper {
  margin-bottom: 12px;
  text-align: left;
}

.primerContainer {
  margin-top: -10px;
  padding: 26px 16px 24px 16px;
  width: 100%;
  min-height: 272px;
  background-color: #f3f8f8;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  border: solid 2px #f0f0f0;
}

.primerButton {
  outline: none;
  border-color: transparent;
  padding: 8px 16px;
  border-radius: 6px;
  background-color: #dff8f5;
  width: 100%;
  min-height: 48px;

  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #464646;
  text-align: left;
  [dir='rtl'] & {
    display: flex;
  }
}

.imageContainer {
  margin-right: 16px;
  [dir='rtl'] & {
    margin-left: 16px;
    margin-right: 0px;
  }
}

.primerButton:hover {
  cursor: pointer;
  background-color: rgb(181, 233, 226);
  outline: 2px solid rgb(181, 233, 226);
}

.disabledActivePrimerButton {
  outline: none;
  border-color: transparent;
  padding: 8px 16px;
  border-radius: 6px;
  background-color: #dff8f5;
  width: 100%;
  min-height: 48px;

  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #464646;
  text-align: left;
}

.loadingPrimerButton {
  outline: none;
  border-color: transparent;
  padding: 8px 16px;
  border-radius: 6px;
  background-color: #fafafa;
  width: 100%;
  min-height: 48px;

  text-align: center;
}

.disabledPrimerButton {
  outline: none;
  border-color: transparent;
  padding: 8px 16px;
  border-radius: 6px;
  background-color: #fafafa;
  opacity: 0.8;
  width: 100%;
  min-height: 48px;

  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #464646;
  text-align: left;
}

.primerContainerHidden {
  display: none;
}

.progressContainer {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  gap: 2px;

  @media (max-width: 475px) {
    padding: 0 24px;
  }
}

.pill {
  flex: 1 0 0;
  height: 4px;
  border-radius: 1px;
  background-color: var(--workouts-progress-bar-pill-bg);
}

.activePill {
  background-color: var(--workouts-progress-bar-active-pill-bg);
}

[dir='rtl'] {
  .MuiListItemSecondaryAction-root {
    right: unset;
    left: 8px;
  }
  .MuiListItem-secondaryAction {
    text-align: right;
    padding-left: 48px;
    padding-right: 16px;
  }

  .MuiTextField-root,
  .MuiFormControl-root {
    label {
      text-align: right;
      right: 28px;
      left: unset;
      transform-origin: top right;
    }
    legend {
      text-align: right;
    }
  }

  .MuiListItemText-root {
    text-align: right;
    margin-right: 0px;
  }

  .MuiButton-startIcon {
    margin-left: 8px;
    margin-right: -4px;
  }
}

.container {
  position: relative;
  min-height: 40px;
  box-shadow: var(--offer-banner-boxshadow);
  background: var(--offer-banner-background);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14.3px;
  font-weight: 500;
  line-height: 1.68;
  letter-spacing: 0.1px;
  color: var(--offer-banner-text);
  padding: 8px 16px;
  padding-top: calc(env(safe-area-inset-top) + 8px);
  margin-bottom: calc(env(safe-area-inset-top) * -1);
}

.icon {
  margin-right: 16px;
  color: var(--offer-banner-text);
}

.link {
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}

.wrapper {
  position: fixed;
  z-index: 9999;
  inset: 0px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: var(--workouts-preview-bg-fog);

  @media (max-width: 475px) {
    position: fixed;
    overflow: hidden;
  }
}

.container {
  display: flex;
  flex-direction: column;
  padding: 16px 24px 24px 24px;
  text-align: left;
  border-radius: 16px;
  background: var(--workouts-preview-bg);
  box-shadow: var(--workouts-preview-bs);
  max-width: 414px;
  animation: slideIn 0.3s forwards;

  @media (max-width: 475px) {
    position: fixed;
    height: 92%;
    bottom: 0;
    left: 0;
    border-radius: 0;
    border-top-left-radius: 16px;
    border-top-right-radius: 16px;
    max-width: unset;
  }
}

.mobileHandleContainer {
  width: 100%;
  height: 22px;
}

.mobileHandle {
  height: 6px;
  width: 40px;
  border-radius: 57px;
  margin: 0 auto;
  background-color: var(--workouts-preview-mobile-notch-bg);
}

.modalClosed {
  animation: slideOut 0.2s forwards;
}

.content {
  display: flex;
  flex-direction: column;

  @media (max-width: 475px) {
    width: 100%;
    height: 100%;
    overflow-y: scroll;
    margin-bottom: 88px;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.content::-webkit-scrollbar {
  display: none;
}

.title {
  margin: 0;
  color: var(--workouts-preview-title);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px; /* 130% */
  letter-spacing: 0.5px;

  [dir='rtl'] & {
    text-align: right;
  }
}

.benefitsWrapper {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  margin: 10px 0 16px;
  max-width: 400px;
  flex-wrap: wrap;
}

.heroVideo {
  max-height: 224px;
  height: 100%;
  background-color: var(--workouts-preview-video-bg);
  object-fit: cover;
  border-radius: 6px;
  width: 100%;
}

.titleSmall {
  margin: 0;
  color: var(--workouts-preview-title-small);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-top: 16px;
  text-transform: lowercase;

  [dir='rtl'] & {
    text-align: right;
  }
}

.titleSmall::first-letter {
  text-transform: uppercase;
}

.light {
  font-weight: 400;
}

.description {
  margin: 0;
  color: var(--workouts-preview-description);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;
  margin-top: 8px;
  white-space: pre-line;

  @media (min-width: 475px) {
    max-height: 200px;
    overflow-y: scroll;
    /* Hide scrollbar for IE, Edge and Firefox */
    -ms-overflow-style: none;
    scrollbar-width: none;
  }

  [dir='rtl'] & {
    text-align: right;
  }
}

/* Hide scrollbar for Chrome, Safari and Opera */
.description::-webkit-scrollbar {
  @media (min-width: 475px) {
    display: none;
  }
}

@keyframes slideIn {
  from {
    transform: translateY(100%); /* Start from bottom */
  }
  to {
    transform: translateY(0); /* Slide to top */
  }
}

@keyframes slideOut {
  from {
    transform: translateY(0); /* Start from top */
  }
  to {
    transform: translateY(100%); /* Slide to bottom */
  }
}

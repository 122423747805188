.mediaContainer {
  position: relative;
  display: flex;
  width: 100%;
  margin-top: 12px;
  border-radius: 16px;
  min-height: 486px;

  @media (max-width: 475px) {
    border-radius: 0;
    min-height: 432px;
  }
}

.video {
  width: 100%;
  min-height: 432px;
  height: 100%;
  object-fit: cover;
  background-color: var(--workouts-media-bg);
  border-radius: 16px;

  @media (max-width: 475px) {
    border-radius: 0;
  }
}

.image {
  width: 100%;
  min-height: 432px;
  height: 100%;
  object-fit: cover;
  background-color: var(--workouts-media-bg);
  border-radius: 16px;

  @media (max-width: 475px) {
    border-radius: 0;
  }
}

.timerOverlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;

  @media (max-width: 475px) {
    border-radius: 0;
  }
}

.timerOverlayText {
  margin: 0;
  color: #eef7f6;
  font-size: 82px;
  font-style: normal;
  font-weight: 700;
  line-height: 88px;
  letter-spacing: 0.5px;
}

.container {
  background: var(--workouts-rest-bg);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;

  @media (max-width: 476px) {
    position: fixed;
    justify-content: flex-start;
  }
}

.topContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 366px;
  width: 100%;

  @media (max-width: 476px) {
    padding: 0 24px;
    margin-top: 30%;
  }

  @media (max-width: 375px) {
    margin-top: 10%;
  }
}

.bannerVisible {
  @media (max-width: 375px) {
    margin-top: 3%;
  }
}

.title {
  color: var(--workouts-rest-title);
  margin: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.5px;
}

.countdown {
  color: var(--workouts-rest-countdown);
  margin: 0;
  font-size: 82px;
  font-style: normal;
  font-weight: 700;
  line-height: 88px;
  letter-spacing: 0.5px;
  margin: 2px 0 24px;
}

.buttons {
  display: flex;
  flex-direction: row;
  gap: 16px;
  width: 100%;
}

button.addSecondButton {
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.5px;
  padding: 9px 16px;
  color: var(--workouts-rest-seconds-btn);
  background-color: transparent;
  border: 1px solid var(--workouts-rest-seconds-btn-border);
  text-transform: none;
  min-height: 64px;
}

button.skipButton {
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.5px;
  padding: 9px 16px;
  color: var(--workouts-rest-skip-btn);
  padding: 9px 16px;
  background: var(--workouts-rest-skip-bg);
  box-shadow: var(--workouts-rest-skip-bs);
  min-height: 64px;
}

button.skipButton:hover {
  background: var(--workouts-button-bg-hover);
  box-shadow: var(--workouts-button-boxshadow-hover);
}

button.skipButton:active,
button.skipButton:focus {
  background: var(--workouts-button-bg-active);
  box-shadow: var(--workouts-button-boxshadow-active);
}

button.skipButton:disabled {
  background: var(--workouts-button-disabled-bg);
  color: var(--workouts-button-disabled-text);
}

.bottomContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 64px;
  width: 100%;

  @media (max-width: 476px) {
    margin-top: 97px;
    position: fixed;
    bottom: 0;
  }
}

.progressBlock {
  display: flex;
  align-items: flex-start;
  max-width: 366px;
  width: 100%;

  @media (max-width: 476px) {
    padding: 0 24px;
  }
}

.progressText {
  color: var(--workouts-rest-progress-txt);
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;
}

.infoBlock {
  display: flex;
  max-width: 366px;
  width: 100%;
  margin-top: 4px;

  @media (max-width: 476px) {
    padding: 0 24px;
  }
}

.titleBlock {
  flex: 70;
  flex-shrink: 0;
}

.secondBlock {
  flex: 30;
  flex-shrink: 0;

  [dir='rtl'] & {
    text-align: left;
  }
}

.exercisetitle {
  color: var(--workouts-rest-exercise-title);
  margin: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.5px;
  text-align: left;

  [dir='rtl'] & {
    text-align: right;
  }
}

.duration {
  color: var(--workouts-rest-duration);
  margin: 0;
  text-align: right;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;

  [dir='rtl'] & {
    text-align: left;
  }
}

.restVideo {
  max-width: 414px;
  max-height: 268px;
  min-height: 268px;
  width: 100%;
  height: 100%;
  margin-top: 16px;
  border-radius: 12px;
  background-color: var(--workouts-rest-video-bg);
  object-fit: cover;

  @media (max-width: 476px) {
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }

  @media (max-width: 375px) {
    max-height: 202px;
    min-height: 202px;
  }
}

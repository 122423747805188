.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  .headerInformation {
    display: flex;

    .icon {
      height: 44px;
      width: 44px;
    }

    .text {
      flex: 1;
      margin-left: 8px;

      [dir='rtl'] & {
        margin-left: 0;
        margin-right: 8px;
      }
    }

    .info {
      margin: 0;
      font-size: 14.3px;
      line-height: 1.4;
      letter-spacing: 0.25px;
      color: var(--meal-info-text);

      img {
        margin-right: 4px;

        [dir='rtl'] & {
          margin-left: 4px;
          margin-right: 0;
        }
      }

      .fastingSmall {
        margin-right: 4px;

        [dir='rtl'] & {
          margin-left: 4px;
          margin-right: 0;
        }
      }
    }

    .title {
      margin: 0;
      font-size: 20.4px;
      font-weight: 500;
      letter-spacing: 0.25px;
      color: var(--meal-title);
    }
  }

  .benefitContainer {
    margin-top: 8px;
    display: flex;

    .benefit {
      display: flex;
      align-items: center;
      font-size: 12px;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #333;

      .benefitImage {
        width: 14px;
        height: 14px;
      }
    }
  }
}

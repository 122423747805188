.container {
  display: flex;
  align-items: flex-start;
}

.title {
  margin: 0 !important;
  font-size: 14.3px !important;
  font-weight: normal !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.4 !important;
  letter-spacing: 0.25px !important;
}

.context {
  font-size: 20.4px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
}

.icon {
  height: 44px;
  width: 44px;
}

.header {
  padding-left: 8px;
}

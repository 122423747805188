.offer_page
  width: 100%
  overflow: hidden

  &__container
    position: relative
    width: 450px
    margin: 0 auto 30px auto
    animation:
      name: fade-in-up
      duration: 0.3s
      timing-function: ease-in-out

  &__link
    text-decoration: none

    &:hover, &:focus, &:active
      text-decoration: none

  .header_wrapper
    position: relative

    .offer_header_image
      background: image('offers/monaco-team-offer-top.png') no-repeat center center
      background-size: contain
      width: 100%
      min-height: 90px

    .offer_header_text
      margin-top: 0.5em

  .offer-wrapper
    margin: 0 auto
    width: 100%
    max-width: 800px

  .offer_list_wrapper
    text-align: left
    position: relative

    .offer_list_image
      background: image('offers/rolands-admidins-offer-bottom.png') no-repeat center center
      background-size: contain
      display: inline-block
      width: 30%
      position: absolute
      top: 0
      bottom: 0
      left: 0

    ul.offer_list
      display: inline-block
      margin-left: 30%

      li
        margin-bottom: 20px
        list-style: none
        position: relative

      li:before
        content: ''
        display: block
        width: 1.5em
        height: 1.5em
        background: image('svg/check_mark.svg') no-repeat center center
        background-size: contain
        position: absolute
        left: -2em

  &__discount_20
    .discount-text
    margin-bottom: 0
    font-size: 18px

    .line-through
      margin-top: 0.5em

    .header_wrapper
      margin: 0

      .offer_header_image
        background: image('offers/monaco-team-offer-discount-20-top.png') no-repeat center bottom
        min-height: 130px
        background-size: contain
        width: 60%
        margin-top: 1em

      .offer_header_text
        margin: 0
        padding: 8px 16px
        color: var(--color-white)

        &__title_top
          background: var(--color-black)
          font-size: 20px

        &__title_bottom
          background: $bright-red
          margin-top: -5px
          margin-bottom: 1em
          transform: rotate(-1deg)
          font-size: 24px
          width: 90%

    .offer_list_wrapper
      position: relative
      margin-top: 45px

      .offer_list_image
        background: image('offers/rolands-admidins-with-drinks.png') no-repeat center center
        background-size: contain
        display: inline-block
        width: 120px
        height: 420px
        position: absolute
        top: 0
        bottom: 0
        left: 0

      ul.offer_list
        display: inline-block
        margin-top: 92px
        margin-left: 105px
        border: 3px solid black
        padding-left: 0.5em
        padding-right: 1em

        li
          margin: 1em 0 1em 0
          display: flex
          align-items: flex-start

          .offer_list_item_content
            display: inline-block
            width: 80%
            font-size: 14px

          .offer_list_item_image
            display: inline-block
            width: 20%
            min-height: 60px
            margin-right: 1em

          &.list_1 .offer_list_item_image
            background: image('offers/discount_twenty/list_01_img.png') no-repeat center center
            background-size: contain

          &.list_2 .offer_list_item_image
            background: image('offers/discount_twenty/list_02_img.png') no-repeat center center
            background-size: contain

          &.list_3 .offer_list_item_image
            background: image('offers/discount_twenty/list_03_img.png') no-repeat center center
            background-size: contain

          &.list_4 .offer_list_item_image
            background: image('offers/discount_twenty/list_04_img.png') no-repeat center center
            background-size: contain

        li:before
          content: ""
          background: none

    .offer_list_chat_bubble_wrapper
      width: 100%
      padding-left: 130px
      margin-bottom: -100px

    .offer_list_chat_bubble
      width: 100%
      background-color: var(--color-grey-25)
      border-radius: 5px
      border: 2px solid rgba(178, 178, 178, .3)
      display: inline-block
      padding: 10px 18px
      font-weight: bold
      position: relative
      margin-bottom: -110px

    .offer_list_chat_bubble::before
      background-color: var(--color-grey-25)
      content: "\00a0"
      display: block
      width: 20px
      height: 16px
      position: absolute
      bottom: 20px
      left: -12px
      transform: rotate(29deg) skew(-35deg)
      border-left: 2px solid rgba(178, 178, 178, .3)
      border-bottom: 2px solid rgba(178, 178, 178, .3)

    .offer_list_chat_bubble--red
      background-color: $bright-red
      border: 2px solid rgba(128, 0, 0, .3)
      font-size: 14px
      color: var(--color-white)

    .offer_list_chat_bubble--red::before, .offer_list_chat_bubble--red::after
      background-color: $bright-red !important
      border-color: rgba(128, 0, 0, .3) !important

    .disclaimer_wrapper
      .disclaimer
        width: 70%

    .reject-button
      padding: 0

  &__pre_unsubscribe
    .next-step-wrapper
      font-size: 20px
      font-weight: bold

    .reject-button
      margin-top: 0

    .header_wrapper
      margin: 0

      .offer_header_image
        background: image('offers/monaco-team-offer-discount-20-top.png') no-repeat center bottom
        min-height: 130px
        background-size: contain
        width: 60%
        margin-top: 1em

      .offer_header_text
        margin: 0
        padding: 8px 16px
        color: var(--color-white)
        text-transform: uppercase

        &__title_top
          background: var(--color-black)
          font-size: 20px
          width: 70%

        &__title_middle
          background: var(--color-red-7)
          margin-top: -5px
          transform: rotate(-1deg)
          font-size: 20px
          width: 95%

        &__title_bottom
          background: $bright-red
          margin-bottom: 3em
          transform: rotate(1deg)
          font-size: 24px
          width: 80%

    .offer_list_wrapper
      ul.offer_list
        li
          &.list_1 .offer_list_item_image
            background-image: image('offers/pre_unsubscribe/list_1.png')

          &.list_2 .offer_list_item_image
            background-image: image('offers/pre_unsubscribe/list_2.png')

          &.list_3 .offer_list_item_image
            background-image: image('offers/pre_unsubscribe/list_3.png')

.offer_banner
  position: sticky
  top: 0
  background: var(--primary-accent-color-dk)
  z-index: 5
  animation:
    name: fade-in
    duration: .3s
    timing-function: ease-in-out

  &__container
    display: table
    max-width: 450px
    width: 100%
    min-height: 35px
    height: auto
    margin: 0 auto

    .BannerMail
      display: table-cell
      width: 36px
      height: 31px

    &__text
      display: table-cell
      font:
        size: 16px
        weight: 600
      color: var(--color-white)
      margin: 0
      vertical-align: middle
      padding-left: 10px

      &__link
        font:
          style: italic
        text-decoration: underline
        white-space: nowrap

.offer__side-image
  position: absolute
  width: 140px
  height: auto
  top: 0

@media (max-width: 460px)
  .offer__side-image
    position: absolute
    width: 140px
    height: auto
    top: 0


.alRight
  text-align: right

@media(max-width: 520px)
  .offer_banner

    &__container
      padding: 5px 10px

      .BannerMail
        margin-top: 0

      &__text
        padding: 3px 0 3px 7px

  .offer_page
    width: 100%
    padding-bottom: 30px

    &__container
      margin: 0 auto 50px auto

    &__full
      padding-bottom: 0px

@media(max-width: $mobile)
  .offer_page
    width: 100%
    padding-bottom: 50px

    &__full
      padding-bottom: 0px

    &__container
      width: 100%
      padding: 0 10px
      margin: 0 auto 50px auto

    &__pre_unsubscribe
      .offer_list_wrapper
      .offer_list_chat_bubble
        width: 65%

    &__pre_unsubscribe
      .offer_list_wrapper
      .offer_list_chat_bubble_wrapper
        top: -3.5em

    &__discount_20
      .offer_list_wrapper
      margin-top: 25px

      ul.offer_list

        .offer_list_item_image
          min-width: 32px
          margin-right: 0.5em !important

      .offer_list_chat_bubble_wrapper
        padding: 0
        margin: 0

      .offer_list_chat_bubble
        margin: 0

      .offer_list_chat_bubble::before
        bottom: -12px
        left: 60px
        transform: rotate(-60deg) skew(-35deg)

//milita
.offer_page--milita
  overflow: visible

  .offer_list_wrapper
    text-align: center
    max-width: 375px
    margin: 0 auto
    width: 100%

  .offer_list_chat_bubble
    font-size: 16px
    padding: 10px 5px 10px 10px
    width: 200px

    &:before
      width: 12px
      height: 10px
      bottom: -7px
      left: 25px
      transform: rotate(-60deg) skew(-35deg)

.offer__side-image
  position: absolute
  width: 140px
  height: auto
  top: 0

  &.offer__side-image--milita
    left: -48px
    top: 0

  &.offer__side-image--paula
    right: -35px
    top: -18px

.header_wrapper--milita
  margin: 0 auto

.offer_header_image--milita_default
  width: 100%
  object-fit: cover

.offer_header_image--milita
  width: 100%
  background-image: image('offers/monaco-team-offer-discount-20-milita-top.png') !important
  background-size: contain

.offer_list_image--milita
  background-image: image('offers/milita-offer-bottom.jpg')
  background-repeat: no-repeat
  background-position: center center
  background-size: contain
  display: inline-block
  width: 30%
  position: absolute
  top: 0
  bottom: 0
  left: 0

.offer_page__pre_unsubscribe--milita
  overflow: visible

.offer_list--milita-list
  .list_1
    .offer_list_item_image
      background-image: image('offers/milita/list_milita_1.png') !important
      background-size: contain

  .list_2
    .offer_list_item_image
      background-image: image('offers/milita/list_milita_2.png') !important
      background-size: contain

  .list_3
    .offer_list_item_image
      background-image: image('offers/milita/list_milita_3.png') !important
      background-size: contain


.offer_list_chat_bubble_wrapper--milita.offer_list_chat_bubble_wrapper--milita.offer_list_chat_bubble_wrapper--milita
  margin: 0 auto 35px auto
  padding: 0
  width: 100%
  text-align: center

.offer_list_chat_bubble_wrapper--milita.offer_list_chat_bubble_wrapper--milita.offer_list_chat_bubble_wrapper--milita .offer_list_chat_bubble
  width: 100%
  max-width: 375px
  text-align: left
  margin: 0 auto

.offer_list--milita
  margin-left: 0 !important
  margin-right: 0 !important
  width: 100%
  max-width: 375px
  text-align: left

.alRight
  text-align: right

@media(max-width: $mobile)
  .offer_header_image--milita
    margin-top: 0 !important
    min-height: 92px !important

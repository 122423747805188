$curve: cubic-bezier(0.650, 0.000, 0.450, 1.000)

.workout_page
  width: 100%
  overflow: hidden

  &__pre_workout
    position: relative
    width: 450px
    margin: 90px auto 40px auto
    animation:
      name: fade-in-up
      duration: 0.3s
      timing-function: ease-in-out

    &__timer_title
      font:
        size: 24px
        weight: 300
      color: $blue
      text-align: center

    &__timer
      position: relative
      display: block
      margin: 20px auto
      padding: 19px 0 16px 0
      width: 100px
      background: none
      text-align: center
      color: $blue
      font:
        size: 56px
        weight: 500
      animation: countdown 1s ease-in-out

      &.first, &.second
        display: none

  &__container
    position: relative
    width: 450px
    margin: 20px auto 40px auto
    animation:
      name: fade-in-up
      duration: 0.3s
      timing-function: ease-in-out

    &__img
      height: auto
      width: 100%
      border-radius: 10px

    &__description
      color: $dark-text
      font:
        size: 16px
        weight: normal
      line-height: 20px
      margin-top: 15px

    &__exercise
      margin-bottom: 35px

      &__title
        color: $dark-text
        font:
          size: 18px
          weight: 600
        margin: 20px 0 10px 0

      &__list
        margin: 0
        padding-inline-start: 20px

        &__title
          color: $dark-text
          font-size: 16px
          margin-bottom: 7px
          list-style: none
          line-height: 20px

          &__count
            color: $blue

          &:before
            content: "• "
            color: var(--color-orange)
            font-size: 42px
            line-height: 20px
            vertical-align: middle

        &__preview
          padding: 7px
          text-decoration: none
          white-space: nowrap

          span
            margin-left: 3px
            font-size: 12px
            color: $blue

          .Eye
            width: 17px
            vertical-align: middle

    &.post-workout
      margin-top: 0
      color: $dark-text

      .top-img
        display: block
        width: 100%
        margin: 0 auto

      .title
        font:
          size: 24px
          weight: 600
        margin: 5px 0 0
        text-align: center

      .text
        font:
          size: 16px
          weight: normal
        line-height: 20px
        margin: 15px 0 25px 0

  &__exercise
    position: relative
    display: block
    opacity: 1

    &__timer
      color: $blue
      font:
        size: 18px
      margin: 10px 0
      text-align: center

      &__main
        position: relative
        display: inline-block
        font:
          size: 28px
          weight: 600
        margin: 0 10px 0 5px

      &__img
        display: block
        width: 48px
        height: auto
        margin: 0 auto 5px auto


    &.entered
      animation:
        name: fade-in-up
        duration: 0.3s
        timing-function: ease-in-out

    &.exiting
      animation:
        name: fade-out-down
        duration: 0.3s
        timing-function: ease-in-out

    &__video
      width: 100%
      border-radius: 10px
      box-shadow: none
      border: none

    &__desc
      color: $dark-text
      font:
        size: 16px
        weight: normal
      line-height: 20px
      margin: 15px 0 35px 0
      white-space: pre-wrap

  &__difficulty-wrapper
    display: flex

  &__difficulty
    width: 33%
    text-align: center
    padding: 0 10px

    &__icon-wrapper
      width: 59px
      margin: 0 auto 10px auto

    &__icon
      width: 59px
      height: 59px
      padding-top: 100%
      position: relative

      img
        position: absolute
        left: 0
        top: 0
        right: 0
        bottom: 0
        margin: auto
        width: 100%

    .form__submit_block__button.large, .form__submit_block.large
      width: 100%
      height: 100%

@media(max-width: 520px)
  .workout_page
    &__container
      margin-bottom: 90px

    &__exercise
      padding-bottom: 10px

      &.pre_workout, &.post_workout
        padding-bottom: 55px

@media(max-width: $mobile)
  .workout_page
    width: 100%
    padding-bottom: 95px

    &__container
      width: 100%
      padding: 0 10px
      margin: 0 auto 0 auto

      .form__submit_block.text-large.left
        position: relative
        background: none
        bottom: auto
        left: auto
        right: auto
        padding: 0
        z-index: 1
        box-shadow: none

    &__pre_workout
      width: 100%
      padding: 0 10px
      margin-top: 150px

      &__timer_title
        font:
          size: 20px

$curve: cubic-bezier(0.650, 0.000, 0.450, 1.000)

.checkmark__circle
  stroke-dasharray: 166
  stroke-dashoffset: 166
  stroke-width: 2
  stroke-miterlimit: 10
  stroke: $green
  fill: none
  animation: stroke .6s $curve forwards

.checkmark
  width: 56px
  height: 56px
  border-radius: 50%
  display: block
  stroke-width: 2
  stroke: var(--color-white)
  stroke-miterlimit: 10
  margin: 14px auto
  box-shadow: inset 0 0 0 $green
  animation: fill_green .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both

.checkmark__check
  transform-origin: 50% 50%
  stroke-dasharray: 48
  stroke-dashoffset: 48
  animation: stroke .3s $curve .8s forwards

.container {
  margin: 24px 0 0 0;
}

.title {
  font-size: 14.3px;
  line-height: 1.4;
  letter-spacing: 0.25px;
  color: var(--color-black-opacity6);
  margin-bottom: 12px;
}

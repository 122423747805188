.container {
  margin: 0 auto;
  max-width: 516px;
  padding: 28px 28px 140px 28px;
  text-align: center;
  font-style: normal;
  letter-spacing: 0.5px;
  font-weight: 400;
  line-height: 24px;
  font-size: 16px;

  @media (max-width: 460px) {
    padding: 54px 28px 85px;
  }
}

.title {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 16px;
}

.subtitle {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  margin-bottom: 32px;
}

.bold {
  font-weight: 700;
}

.commentWrapper {
  margin-top: 16px;
  text-align: right;
}

.comment {
  width: 100%;
}

.commentLength {
  margin: 0;
  color: var(--workouts-unsub-reason-length-text);
  padding-top: 4px;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.5px;
}

.maxCapacity {
  color: var(--workouts-unsub-reason-length-text-invalid);
}

.mfStyle > div.focusedInput > fieldset {
  border-color: var(--workouts-unsub-reason-input-border) !important;
}

.mfStyle > label.focusedLegend {
  color: var(--workouts-unsub-reason-input-legend);
}

.buttons {
  margin-top: 16px;

  & > div {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.submitButton button {
  min-height: 40px;

  &:disabled {
    opacity: 0.5;
  }
}

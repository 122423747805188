.cardContainer {
  position: relative;
  min-height: 204px;
  background: #fafafa;
  border-radius: 3px;
  border: solid 1px rgba(0, 0, 0, 0.12);
  padding: 16px;
  padding-bottom: 0px;

  .button {
    margin-top: 10px;
  }

  .button > div > button:focus {
    background: var(--primary-submit-button-background) !important;
  }

  .cardWrapper {
    display: flex;
    min-height: 130px;

    .leftSide {
      margin-right: 5px;
      padding-top: 12px;
      flex: 2;
      font-weight: normal;
      font-stretch: normal;
      font-style: normal;
      letter-spacing: 0.25px;
      color: rgba(0, 0, 0, 0.87);

      [dir='rtl'] & {
        margin-left: 5px;
        margin-right: 0;
      }

      @media (max-width: 420px) {
        flex: 1;
      }

      .cardTitle {
        font-size: 14.3px;
        line-height: 1.4;
      }

      .cardTimer {
        margin: 0;
        font-size: 20.4px;
        font-weight: 500;
        line-height: normal;
      }

      .cardText {
        margin: 8px 0 0;
        font-size: 14.3px;
        line-height: 1.4;
        position: relative;

        .congratz {
          margin-left: 20px;
          color: #03cea4;
          font-weight: 500;

          [dir='rtl'] & {
            margin-left: 0;
            margin-right: 20px;
          }
        }

        svg {
          position: absolute;
        }
      }
    }

    .rightSide {
      flex: 1;
      position: relative;
      align-items: center;
      justify-content: center;
    }
  }

  .semiBold {
    font-weight: 500;
  }
}

.cardContainer.doneEating {
  border-radius: 3px;
  border-style: solid;
  border-width: 2px;
  border-image-source: linear-gradient(242deg, #03cea4, #29abe2);
  border-image-slice: 1;
  background-image: linear-gradient(to bottom, #fafafa, #fafafa),
    linear-gradient(242deg, #03cea4, #29abe2);
}

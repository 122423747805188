.container {
  cursor: pointer;
  overflow: hidden;
  width: 100%;
  height: 42px;
  border-radius: 20px;
  position: relative;
  border: 1px solid var(--color-grey-9);
  margin-top: 10px;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: 0 7px 20px 0 rgba(187, 187, 187, 0.5);
    background-color: rgba(187, 187, 187, 0.3);
  }

  &:before {
    content: '';
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 9px 5px 0 5px;
    border-color: var(--color-grey-10) transparent transparent transparent;
    position: absolute;
    right: calc(50% - 55px);
    top: 50%;
    margin-top: -4px;
    z-index: 1;
  }
}

.select {
  appearance: none;
  font-weight: 700;
  width: calc(100% + 15px);
  height: 42px;
  background-color: transparent;
  color: var(--color-grey-10);
  text-transform: capitalize;
  font-size: 13px;
  padding: 5px 10px;
  cursor: pointer;
  border: var(--color-yellow-7);
  outline: none;
  padding-left: calc(50% - 30px);
  position: relative;
  z-index: 2;
  line-height: 1.2;
}

.loader {
  height: 28px;
  position: absolute;
  top: 6px;
  left: 50%;
  margin-left: -15px;
}

.loader > circle {
  stroke: var(--color-grey-10) !important;
}

.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background-color: var(--workouts-first-overlay-bg);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 0 20px;
}

.middleContent {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  max-width: 382px;
  width: 100%;
}

.title {
  margin: 0;
  color: var(--workouts-first-overlay-text);
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.5px;
}

.countdown {
  margin: 0;
  color: var(--workouts-first-overlay-countdown);
  font-size: 82px;
  font-style: normal;
  font-weight: 700;
  line-height: 88px;
  letter-spacing: 0.5px;
  margin-top: 8px;
}

.progress {
  margin: 0;
  color: var(--workouts-first-overlay-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.5px;
  margin-top: 16px;
  margin-bottom: 16px;
}

.exercise {
  margin: 0;
  color: var(--workouts-first-overlay-text);
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.5px;
}

.duration {
  margin: 0;
  color: var(--workouts-first-overlay-text);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-top: 4px;
}

.buttonContiner {
  max-width: 382px;
  width: 100%;
}

.container {
  background: var(--workouts-unsub-bg);
  min-height: 100vh;
  padding-bottom: 98px;
  overflow: hidden;
}

.content {
  max-width: 540px;
  margin: 0 auto;
  transition: 0.25s opacity ease-out;

  &.isModalOpened {
    opacity: 0;
  }
}

.art {
  position: relative;
  height: 280px;
  max-width: 440px;
  margin: 0 auto;

  @media (max-width: 440px) {
    height: 250px;
  }
}

.artPositioned {
  position: absolute;
  top: 0;
  bottom: 0;
  right: -65px;
  left: -35px;
  display: flex;
  justify-content: space-between;

  &:after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    height: 65px;
    background: var(--workouts-unsub-art-shadow);
    z-index: 3;
  }
}

.question {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  z-index: 2;

  svg {
    height: 96px;

    [dir='rtl'] & {
      transform: scaleX(-1);
    }
  }
}

.after {
  position: relative;
  z-index: 1;
}

.before {
  position: relative;
  z-index: 1;
}

.image {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.areYouSure {
  max-width: 414px;
  margin: 0 auto;
  margin-top: 16px;
  padding: 0 24px;
}

.title {
  color: var(--workouts-unsub-text-light);
  text-align: center;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 8px;
}

.subtitle {
  color: var(--workouts-unsub-text-light);
  text-align: center;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
}

.buttons {
  margin-top: 24px;

  & > div {
    margin-bottom: 24px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.submitButton button {
  min-height: 40px;
}

.warning {
  max-width: 414px;
  margin: 0 auto;
  margin-top: 64px;
  padding: 0 24px;
}

.warningContent {
  padding: 40px 24px 24px 24px;
  border-radius: 12px;
  background: var(--workouts-unsub-warning-bg);
  position: relative;
}

.warningIcon {
  position: absolute;
  top: -38px;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
}

.warningPoint {
  color: var(--workouts-unsub-text-light);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  display: flex;
  flex-wrap: nowrap;
  align-items: flex-start;
  gap: 12px;
  margin-bottom: 16px;

  &:first-child {
    margin-top: 24px;
  }

  &:last-child {
    margin-bottom: 0;
  }

  .warningPointIcon {
    flex: 0 0 auto;
    padding-top: 3px;
  }
}

.program_page
  &__link
    text-decoration: none

    &:hover, &:focus, &:active
      text-decoration: none

  &__container
    width: 450px
    margin: 0 auto

    &.top
      width: 460px

    &__item
      position: relative
      display: block
      width: 100%
      background: var(--color-grey-5)
      background: linear-gradient(to bottom, var(--color-grey-5) 0%,var(--color-grey-5) 100%)
      box-shadow: 0px 2px 0.94px 0.06px rgba(0, 0, 0, 0.13)
      border-radius: 10px
      margin: 13px 0
      cursor: pointer

      &__meal_img
        padding-bottom: 10px
        text-align: center

        img
          border-radius: 10px
          max-width: 422px
          width: 100%
          height: auto

      &.updated
        .program_page__container__item__img_block
          position: relative
          visibility: hidden
          animation:
            name: bounce-in
            duration: 1s
            delay: 0.5s
            timing-function: ease-in-out
            fill-mode: forwards

      &__checked
        position: absolute
        top: 23px
        right: 15px

        &__icon
          width: 46px
          height: 37px

      &__img_block
        display: table-cell
        width: 88px

        &__img
          padding: 10px 12px
          vertical-align: middle
          width: 86px

      &__info
        display: table-cell
        vertical-align: middle
        padding: 10px 65px 10px 0

        &__header
          font:
            size: 18px
            weight: 600
          color: $dark-text
          margin: 5px 0

          &__time
            font:
              weight: 300

          &__title
            display: block
            font:
              size: 14px
              weight: normal
            margin-top: 5px


        &__sub
          color: $dark-text
          font-size: 14px
          margin: 0

      &__cover
        position: absolute
        right: 0
        top: -9px

      &:hover
        background: var(--color-grey-30)

      &__preview
        position: relative
        padding-bottom: 12px
        text-align: center

        &__img
          border-radius: 10px
          max-width: 422px
          width: 100%

        &__play_btn
          position: absolute
          left: 0
          right: 0
          top: 93px
          margin: 0 auto
          width: 78px

    &__wrap
      position: relative

      &:before
        content: ''
        top: 0
        left: 0
        position: absolute
        width: 12px
        height: 100px
        box-shadow: inset 5px 0px 10px 0px rgba(255,255,255,0.8)
        z-index: 10

      &:after
        content: ''
        top: 0
        right: 0
        position: absolute
        width: 12px
        height: 100px
        box-shadow: inset -5px 0px 10px 0px rgba(255,255,255,0.8)
        z-index: 10

    &__calendar
      position: relative
      margin: 7px 0 26px 0
      overflow-x: auto
      overflow-y: hidden
      -ms-overflow-style: -ms-autohiding-scrollbar
      -webkit-overflow-scrolling: touch
      white-space: nowrap
      user-select: none
      animation:
        name: fade-in
        duration: 0.3s
        timing-function: ease-in-out

      .js &
        -ms-overflow-style: -ms-autohiding-scrollbar
        &::-webkit-scrollbar
          display: none

      &__content
        float: left
        transition: transform 0.5s ease-in-out
        position: relative

        &.no-transition
          transition: none

      &__split
        outline: none
        text-decoration: none
        // Reset the font size
        display: inline-block
        width: 82px
        margin: 0 5px
        height: 86px

      &__item
        width: 100%
        position: relative
        border: 2px solid $grey
        border-radius: 10px
        margin: 0 5px
        cursor: pointer
        transition: all $animation-speed $animation-type

        &:hover
          border-color: var(--color-grey-24)

        &__day
          display: block
          color: $dark-text
          margin: 5px 0 1px 0
          font:
            size: 16px
          text-align: center

        &__date
          display: block
          color: $dark-text
          font:
            size: 35px
            weight: 500
          text-align: center

        &__progress
          display: flex
          justify-content: space-around
          margin: 0 0 2px 3px

          &.hidden
            opacity: 0

          &__item
            position: relative
            display: inline-block
            background: var(--color-grey-31)
            width: 16px
            text-align: center
            height: 16px
            margin-left: -2px
            border-radius: 50%

            svg
              margin-bottom: 3px

        &.current.selected, &.current
          .program_page__container__calendar__item__progress__item
            transform: scale(0)
            animation:
              name: pop-in
              duration: 0.3s
              timing-function: ease-in-out
              fill-mode: forwards

.pn-Advancer
  appearance: none
  background: transparent
  padding: 0
  border: 0
  position: absolute
  top: 0
  bottom: 0
  z-index: 999
  cursor: pointer
  transition: all $animation-speed $animation-type

  &_Left
    left: -29px


  &_Right
    right: -29px

  &_Icon
    width: 20px
    height: 44px

  &:focus
    outline: 0

.program_page__container__calendar
  &[data-overflowing="right"] + .program_page__container__buttons
    .pn-Advancer_Left
      opacity: 0
    .pn-Advancer_Right
      opacity: 1

  &[data-overflowing="both"] + .program_page__container__buttons
    .pn-Advancer_Left, .pn-Advancer_Right
      opacity: 1

  &[data-overflowing="left"] + .program_page__container__buttons
    .pn-Advancer_Left
      opacity: 1
    .pn-Advancer_Right
      opacity: 0

@for $i from 1 to 6
  .program_page__container__calendar__item .program_page__container__calendar__item__progress__item:nth-child(#{$i})
    z-index: 20 - $i
  .content 
    padding: 1.5em

@media(max-width: $mobile)
  .program_page
    &__container
      width: 100%
      padding: 0 10px

      &.top
        margin-top: 8px
        width: 100%
        overflow-y: auto
        overflow-x: hidden

      &__item
        margin: 13px 0

        &__meal_img
          padding: 0 12px 12px 12px
          width: 100%

        &.animated
          .program_page__container__item__img_block__img
            animation:
              name: pop-in
              duration: 0.3s
              timing-function: ease-in-out
              fill-mode: forwards


        &:hover, &:active, &:focus
          background: var(--color-grey-5)

        &__checked
          top: 10px
          right: 12px

          img
            width: 25px

        &__img_block
          width: 53px

          &__img
            width: 53px

        &__info
          z-index: 2

          &__header
            font:
              size: 16px
            margin-bottom: 7px

          &__sub
            font-size: 13px


        &__cover
          top: -5px
          height: 55px
          z-index: 1

        &__preview
          width: 100%
          padding: 0 12px 12px 12px

          &__img
            width: 100%

          &__play_btn
            position: absolute
            top: 30%
            width: 54px

      &__wrap
        &:before, &:after
          height: 0

      &__calendar
        margin: 0px 0 0px 0

        &__split
          display: inline-block
          width: 82px
          margin: 0 5px
          height: 86px

        &__item
          margin: 0 2px
          width: 82px
          height: 86px

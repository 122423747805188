.container {
  background: var(--primary-background-color);
  max-width: 450px;
  width: 100%;
  margin: 0 auto;
  border-radius: 6px;
  padding: 25px 0 90px 0;
  margin-top: 15px;
}

.header {
  text-align: center;
  color: var(--primary-title-color);
  font-size: 20px;
  font-weight: 500;
  margin: 0 15px;

  @media (max-width: 460px) {
    font-size: 18px;
  }
}

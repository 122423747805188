.input {
  appearance: none;
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-title-color);
  height: 42px;
  width: 100%;
  padding: 0 15px 0 50px;
  border: 2px solid var(--color-grey-6);
  border-radius: 20px;
  outline: none;

  &.small {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    border-right-width: 1px;
  }

  &.two-third {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0;
  }
}

.unit {
  position: absolute;
  font-size: 14px;
  font-weight: 400;
  color: var(--primary-title-color);
  top: 13px;
  right: 8px;
  text-align: end;
}

.label {
  display: block;
  white-space: nowrap;
  font-size: 15px;
  font-weight: 600;
  color: var(--primary-title-color);
  margin-left: 20px;
}

.phoneInput > label {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 90%;
  height: 100%;
}

.btn
  &__inline-link
    font:
      size: 15px
      weight: 600
    text-decoration: none
    color: $blue
    padding-top: 2px
    transition: all $animation-speed $animation-type

    &.left
      float: left

    &.right
      float: right

    &.block
      width: 100%

    &:hover
      text-decoration: underline

    &__img
      vertical-align: middle
      margin-right: 6px
      width: 16px

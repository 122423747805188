.label {
  font-size: 20.4px;
  font-weight: 500;
  letter-spacing: 0.25px;
  color: var(--color-black-opacity8);
  margin-bottom: 24px;
}

.image {
  padding: 0 4px;
  max-width: 100%;
  height: auto;
  margin-bottom: 30px;
}

.sliderContainer {
  padding: 0 20px;
  margin-bottom: 30px;
}

.slider {
  & > span:last-child {
    width: 20px;
    height: 20px;
    margin-top: -9px;
    margin-left: 0;
  }

  & > span > span {
    left: calc(-50% + 4px);
  }

  & > span > span > span > span {
    color: var(--slider-span-lable-color);
  }
}

.title {
  color: var(--primary-title-color);
}

.time {
  color: var(--primary-title-color);
}

.number {
  width: 44px;
  height: 44px;
  object-fit: contain;
  border-radius: 3px;
  background-color: var(--color-yellow-3);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  font-weight: 900;
  font-stretch: condensed;
  color: var(--color-blue-8);
}

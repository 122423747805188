.link {
  color: var(--link-button-text) !important;
  text-decoration: none;
  font-size: 14.3px !important;
  font-weight: 500 !important;
  letter-spacing: 1.25px !important;
  background-color: var(--link-button-background) !important;

  &:hover {
    color: var(--link-button-text-hover) !important;
    border-color: var(--link-button-border-hover) !important;
    background-color: var(--link-button-background-hover) !important;
    text-decoration: underline;
  }
}

.block {
  display: block;
  border: none;
  border-radius: 20px;
  padding: 10px 10px 9px 10px;
  margin-top: 10px;
  cursor: pointer;
  float: none;
  text-align: center;
}

.noDecoration {
  text-decoration: none;
  outline: none;
}

.shopping {
  composes: noDecoration;

  & > button {
    color: var(--link-button-shopping-text) !important;
    font-size: 14px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: 1.05px;
    width: unset;

    &:hover {
      color: var(--link-button-shopping-text) !important;
      background: none !important;
    }
  }
}

.block {
  flex: 1;
  width: 100%;
}

.blocky {
  composes: link;

  color: var(--color-global-white) !important;

  &:hover {
    color: var(--color-global-white) !important;
  }
}

.disabledLink {
  pointer-events: none;
}

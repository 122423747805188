.label {
  font-size: 15px;
  font-weight: 600;
  color: var(--primary-title-color);
  margin-left: 20px;
}

.value {
  display: block;
  font-size: 15px;
  font-weight: 600;
  color: var(--primary-accent-color);
  margin: 10px 20px 25px 20px;
}

.checkmark {
  stroke: var(--color-blue-2);
  animation:
    fill_blue 0.4s ease-in-out 0.4s forwards,
    scale 0.3s ease-in-out 0.9s both;
  box-shadow: inset 0 0 0 var(--color-blue-2);
}

.circle {
  stroke: var(--color-blue-2);
}

@keyframes fill_blue {
  100% {
    box-shadow: inset 0px 0px 0px 30px var(--color-blue-2);
  }
}

.carouselItem {
  position: relative;
  display: inline-flex;
  flex-basis: 33.3%;
  flex-grow: 0;
  flex-shrink: 0;
  min-width: 0;
  flex-direction: column;
  width: 100%;
  max-height: 280px;
  padding: 16px;
  border-radius: 12px;
  background: var(--express-plan-carousel-item-bg);
  text-align: left;
  white-space: normal;
  overflow: hidden;
  border: none;
  appearance: none;
  user-select: none;

  [dir='rtl'] & {
    text-align: right;
  }
}

.carouselItem:hover {
  cursor: pointer;
}

.gradient {
  position: absolute;
  height: 74px;
  width: 100%;
  bottom: 0;
  background: var(--express-plan-carousel-item-inactive-gradient);
}

.activeItem {
  flex-basis: auto;
  max-width: 232px;
  min-height: 363px;
  max-height: none;
  transition: ease-in 0.3s;
}

.image {
  object-fit: cover;
  max-width: 154px;
  width: 100%;
  transition: transform 0.3s ease-in-out;
}

.activeImage {
  max-width: none;
}

.title {
  margin: 0;
  padding-top: 16px;
  padding-bottom: 4px;
  color: var(--express-plan-carousel-item-title);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.activeTitle {
  font-size: 16px;
}

.text {
  margin: 0;
  color: var(--express-plan-carousel-item-text);
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;
}

.activeText {
  font-size: 14px;
}

.text + .text {
  padding-top: 8px;
}

.text + .text.activeText {
  padding-top: 24px;
}

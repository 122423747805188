.background {
  background: url('../assets/images/login_bg_diet.jpg') no-repeat center center
    fixed;
  background-size: cover;
  background-image: image-set(
    url('../assets/images/login_bg_diet.jpg') 1x,
    url('../assets/images/login_bg_diet@2x.jpg') 2x,
    url('../assets/images/login_bg_diet@3x.jpg') 3x
  );
}

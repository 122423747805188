$curve: cubic-bezier(0.650, 0.000, 0.450, 1.000)

.errormark__circle
  stroke-dasharray: 166
  stroke-dashoffset: 166
  stroke-width: 2
  stroke-miterlimit: 10
  stroke: $red
  fill: none
  animation: stroke .6s $curve forwards

.errormark
  width: 56px
  height: 56px
  border-radius: 50%
  display: block
  stroke-width: 2
  stroke: var(--color-white)
  stroke-miterlimit: 10
  margin: 14px auto
  box-shadow: inset 0px 0px 0px $red
  animation: fill_red .4s ease-in-out .4s forwards, scale .3s ease-in-out .9s both

.errormark_line-1, .errormark_line-2
  stroke-dasharray: 48
  stroke-dashoffset: 48
  stroke-width: 2px

.errormark_line-1
  animation: stroke .3s $curve .8s forwards

.errormark_line-2
  animation: stroke .3s $curve 1s forwards

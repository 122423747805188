.link {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.block {
  display: block;
  border: none;
  border-radius: 20px;
  padding: 10px 10px 9px 10px;
  margin-top: 10px;
  cursor: pointer;
  float: none;
  text-align: center;
}

.noDecoration {
  text-decoration: none;
}

.disabledLink {
  pointer-events: none;
}

.form
  &__submit_block
    display: block

    &__button
      font:
        size: 13px
        weight: 700
        family: 'Poppins', sans-serif
      color: var(--primary-submit-button-text)
      background: var(--primary-submit-button-background)
      border: none
      border-radius: 20px
      padding: 15px 10px 13px 10px
      margin-top: 10px
      cursor: pointer
      box-shadow: var(--primary-submit-box-shadow)
      transition: background $animation-speed $animation-type

      &:hover
        background: var(--primary-submit-button-background-hover)
        box-shadow: var(--primary-submit-box-shadow-hover)

      &:active, &:focus
        background: var(--primary-submit-button-background-active)
        outline: none
        box-shadow: var(--primary-submit-box-shadow-active)

      &.large, &.large-fixed, &.large-in-group
        width: 100%  

      &.top
        margin-top: 0
        margin-bottom: 15px

      &.link
        display: block
        text-align: center
        text-decoration: none

      &.loading
        background: var(--primary-submit-button-background-loading) !important
        padding: 5px 10px 2px 10px
        box-shadow: none
        min-height: 42px

        svg
          height: 28px

        &:hover, &:focus, &:active
          background: var(--primary-submit-button-background-loading) !important
          box-shadow: none

      &.left
        display: inline-block
        text-align: left
        margin: 0

#load
  width: 31px
  animation: loading 3s linear infinite

#loading-inner
  stroke-dashoffset: 0
  stroke-dasharray: 300
  stroke-width: 10
  stroke-miterlimit: 10
  stroke-linecap: round
  animation: loading-circle 1.3s linear infinite
  stroke: var(--color-white)
  fill: transparent

@media(max-width: $mobile)
  .form
    &__submit_block
      &.large-fixed
        position: fixed
        background: var(--color-white)
        padding: 7px
        margin: 0
        bottom: calc(60px + env(safe-area-inset-bottom))
        left: 0
        right: 0
        z-index: 1000
        box-shadow: 0px -0px 10px 3px rgba(0,0,0,0.15)

      .form__submit_block__button.large-fixed
        margin: 0

      &.large-in-group
        .form__submit_block__button
          margin: 0

.container {
  display: flex;
  justify-content: center;
  margin: 0 auto 24px;
  color: var(--global-statistics-text);
  text-transform: uppercase;
}

.item {
  display: flex;
  margin: 0 8px;
}

.item:first-child {
  flex: 1;
  justify-content: flex-end;
}

.item:last-child {
  flex: 1;
  justify-content: flex-start;
}

.itemContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.weight {
  font-size: 16px;
  font-weight: bold;
}

.unit {
  font-size: 10px;
  font-weight: normal;
}

.label {
  font-size: 10px;
}

.input {
  border: solid 2px var(--color-grey-7);
  color: var(--color-white);
  background: transparent;
  height: unset;

  // Remove spinners on Safari, Chrome
  & input[type='number']::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  // Remove spinners on Firefox
  & input[type='number'] {
    -moz-appearance: textfield;
  }
}

.padded {
  margin-bottom: 32px !important;
}

.unit {
  color: var(--color-white);
}

.label {
  color: var(--color-white);
}

.error {
  padding: 0;
}

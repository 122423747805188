@import 'variables'
@import 'animations'
@import 'helpers'

// Components
@import 'components/auth/LoginPage.sass'
@import 'components/auth/RenewPasswordPage.sass'
@import 'components/auth/FacebookMergePage.sass'

@import 'components/common/styles/BrowserSupport.sass'
@import 'components/common/styles/Button.sass'
@import 'components/common/styles/CheckBox.sass'
@import 'components/common/styles/DropDownInput.sass'
@import 'components/common/styles/ErrorIcon.sass'
@import 'components/common/styles/FlashMessage.sass'
@import 'components/common/styles/LinkButton.sass'
@import 'components/common/styles/LoadingAnimation.sass'
@import 'components/common/styles/LoadingProgress.sass'
@import 'components/common/styles/Navbar.sass'
@import 'components/common/styles/PaymodeBtn.sass'
@import 'components/common/styles/ProblemZones.sass'
@import 'components/common/styles/SelectButtons.sass'
@import 'components/common/styles/SliderInput.sass'
@import 'components/common/styles/SubmitButton.sass'
@import 'components/common/styles/SuccessIcon.sass'
@import 'components/common/styles/TextInput.sass'
@import 'components/common/styles/TimerButton.sass'

@import 'components/meal_survey/MealSurveyPage.sass'

@import 'components/Offer/Offer.sass'

@import 'components/program/_partials/SinglePageHeader.sass'
@import 'components/program/modals/modals.sass'
@import 'components/program/MealPage.sass'
@import 'components/program/ProgramPage.sass'
@import 'components/program/ShoppingListPage.sass'

@import 'components/workout/WorkoutPage.sass'

@import 'components/user/ProfilePage.sass'

*
  box-sizing: border-box
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0)
  -moz-tap-highlight-color: rgba(0, 0, 0, 0)

body
  font-family: sans-serif
  -webkit-font-smoothing: antialiased
  font-display: swap

  &.monacofit
    font-family: "Poppins", sans-serif

  &.diet, &.keto, &.ketogo, &.fasting, &.healthgenom
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif

    button,
    input,
    optgroup,
    select,
    textarea
      font-family: "Helvetica Neue", Helvetica, Arial, sans-serif

input
  line-height: normal

.container
  max-width: 980px
  width: 100%
  margin: 0 auto

.form
  &.inline
    display: inline-block

  &.left
    float: left

.clear
  clear: both

.text-center
  text-align: center
  text-align: -webkit-center
  text-align: -moz-center

.text-justify
  text-align: justify

.light-text
  color: $light-text

.gold-text
  color: var(--color-yellow-8)

.discount-text
  font-weight: bold
  color: $bright-red

.line-through
  position: relative
  display: inline-block

  &__red
    border-color: $bright-red

  &:before
    position: absolute
    content: ""
    left: 0
    top: 50%
    right: 0
    border-top: 1px solid
    border-color: inherit
    -webkit-transform: rotate(-5deg)
    -moz-transform: rotate(-5deg)
    -ms-transform: rotate(-5deg)
    -o-transform: rotate(-5deg)
    transform: rotate(-5deg)

body.modal-open
  overflow: hidden
  position: fixed
  top: 0
  bottom: 0
  left: 0
  right: 0

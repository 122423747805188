@media (max-width: 460px) {
  .fixed {
    position: fixed;
    background: var(--color-white);
    padding: 7px;
    margin: 0;
    bottom: calc(60px + env(safe-area-inset-bottom));
    left: 0;
    right: 0;
    z-index: 1000;
    box-shadow: 0px -0px 10px 3px rgba(0, 0, 0, 0.15);
  }
}

.dumbbell_block
  position: relative
  width: 56px
  height: 90px
  margin: 55px auto 35px auto
  animation: 
    name: fade-in
    duration: 0.5s
    timing-function: ease-in-out


  .dumbbell
    position: absolute
    animation: 
      name: dumbell-jump
      duration: 2s
      timing-function: ease-in-out
      iteration-count: infinite

    .handle
      width: 56px
      height: 6px
      background: $green
      border-radius: 4px
    
    .weight1
      position: absolute
      width: 10px
      height: 30px
      border-radius: 10px
      background: $green
      border: 2px solid white

      &.left
        left: 10px
        top: -12px
      
      &.right
        right: 10px
        top: -12px

    .weight2
      position: absolute
      width: 8px
      height: 24px
      border-radius: 10px
      background: $green
      border: 2px solid white

      &.left
        left: 3px
        top: -9px
      
      &.right
        right: 3px
        top: -9px


  @keyframes dumbell-jump
    0%
      top: 0
      transform: rotate(0deg)
    21%
      top: 80px
      transform: rotate(45deg)
    50%
      top: 0px
    75%
      top: 80px
      transform: rotate(135deg)
    100%
      top: 0
      transform: rotate(360deg)

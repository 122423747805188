.wrapper {
  flex-shrink: 0;
  scroll-snap-align: end;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 76px;
  margin: 0 5px;
  user-select: none;
  overflow: hidden;
}

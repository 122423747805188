.wrapper {
  background: var(--navbar-background);

  svg {
    color: var(--navbar-svg) !important;
  }
}

.background {
  width: unset;
  height: unset;
  box-shadow: none;
}

:global(.MuiBottomNavigationAction-root.Mui-selected).selected {
  color: var(--color-white);
  padding-top: 0px;

  svg {
    color: var(--color-white) !important;
  }
}

:global(
    .MuiBottomNavigationAction-root.MuiBottomNavigationAction-iconOnly
  ).button {
  padding-top: 0px;
}

:global(.MuiBottomNavigationAction-root).root {
  padding: 0px;
  min-width: 64px;
}

@media (max-width: 767px) {
  .background {
    background: var(--navbar-background);
    padding-bottom: env(safe-area-inset-bottom);
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 9;
  }

  .wrapper {
    position: relative;
  }
}

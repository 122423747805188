.container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: rgba(255, 255, 255, 0.85);
  backdrop-filter: blur(12.5px);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  padding: 0 20px;

  @media (max-width: 476px) {
    position: fixed;
  }
}

.content {
  display: flex;
  flex-direction: column;
  text-align: center;
  width: 100%;
  max-width: 414px;
  padding: 32px 24px 24px 24px;
  align-items: center;
  background-color: #fff;
  border-radius: 12px;
  box-shadow: 0px 12px 24px 0px rgba(207, 207, 207, 0.24);

  @media (max-width: 476px) {
    position: fixed;
    bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.title {
  margin: 0;
  color: #000;
  font-size: 24px;
  font-style: normal;
  font-weight: 700;
  line-height: 32px;
  letter-spacing: 0.5px;
}

.donePercentage {
  margin: 0;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
  color: #29998c;
  margin-top: 24px;
}

.exercisesToGo {
  margin: 0;
  color: #666;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-top: 4px;
}

.buttonContainer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 24px;
  gap: 16px;
}

.buttonContainer > div {
  width: 100%;
  margin-top: 0;
}

.resumeButton {
  width: 100%;
  min-height: 64px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.5px;
}

.restartButton {
  width: 100%;
  min-height: 64px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.5px;
}

.buttonNormalize {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  text-align: inherit;
  font: inherit;
  border-radius: 0;
  appearance: none;
}

.quitButton {
  width: 100%;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.5px;
  color: #bbb;
  min-height: 38px;
  transition: ease-in-out 0.2s;
}

.quitButton:hover {
  cursor: pointer;
  color: rgba(187, 187, 187, 0.7);
}

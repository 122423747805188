.paymode-btn
  border-radius: 10px
  width: 100%
  box-shadow: none
  border: 2px solid var(--color-grey-6)
  background: var(--color-white)
  color: $dark-text
  padding: 10px
  cursor: pointer
  box-shadow: 0 7px 20px 0 rgba(155, 155, 155, 0.2)
  transition: all $animation-speed $animation-type

  &:hover, &:active, &:focus
    background: $green
    border-color: $green
    color: var(--color-white)
    box-shadow: 0 7px 20px 0 rgba(82, 204, 122, 0.2)
    outline: none

  &__img
    display: block
    margin: 0 auto 7px auto

  &__label
    font:
      size: 14px
      weight: 600

.paymode-block
  max-width: 50%
  width: 100%
  float: left
  padding: 7px

  &.last
    max-width: 100%

    .paymode-btn
      display: block
      width: 50%
      margin: 0 auto

@media(max-width: $mobile)
  .paymode-block, .paymode-block.last
    width: 100%
    float: none
    max-width: 100%

    .paymode-btn
      width: 100%

      &__img
        width: auto
        height: 48px

.input {
  background: var(--text-input-backgrond);
  height: unset;

  input {
    border-color: var(--text-input-border) !important;
    color: var(--text-input-text) !important;
  }

  & > div {
    &:hover {
      border-color: var(--text-input-border-hover) !important;
    }

    &:focus,
    &:active {
      border-color: var(--text-input-border-active) !important;
    }
  }

  // Remove spinners on Safari, Chrome
  & input[type='number']::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  // Remove spinners on Firefox
  & input[type='number'] {
    -moz-appearance: textfield;
  }
}

.padded {
  margin-bottom: 32px !important;
}

.unit {
  color: var(--color-global-white);
}

.label {
  color: var(--color-global-white);
}

.error {
  padding: 0;

  & > div > fieldset {
    border-color: var(--error-color) !important;
  }
}

.badge {
  display: inline-flex;
  padding: 2px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 32px;
  background: var(--badge-bg);
  color: var(--badge-color);
  font-size: 12px;
  font-style: normal;
  font-weight: 600;
  line-height: 16px;
  letter-spacing: 0.5px;
  margin: 0 8px;
}

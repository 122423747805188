// TEXT
$dark-text: #2e3033
$regular-text: #737980
$light-text: #b8c2cc

$green: #52cc7a
$blue: #0081ff
$red: #cc5252
$bright-red: #ff002a

$grey: #ebeef0

$dark-grey: #191c21

// ANIMATIOn
$animation-speed: 0.2s
$animation-type: ease-in-out

// BREAKING POINT
$mobile: 460px

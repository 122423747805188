.btn
  &__default
    display: inline-block
    font:
      size: 14px
      weight: 500
    color: $dark-text
    border-radius: 20px
    border: 0
    min-height: 55px
    min-width: 110px
    background: $grey
    padding: 0 20px
    cursor: pointer
    transition: all $animation-speed $animation-type

    &:hover
      color: var(--color-white)
      background: $blue
      box-shadow: 0 7px 20px 0 rgba(lighten($blue, 20%), 0.5)

      img
        filter: brightness(0) invert(1)

    &:active, &:focus
      outline: none

    &.fb
      color: var(--color-white)
      background: var(--color-blue-4)
      width: 100%
      margin-top: 10px
      text-transform: capitalize
      font:
        size: 14px
        weight: 700
      height: auto
      padding: 15px 10px 12px

      &:hover
        background: var(--color-blue-5)

    &.blue
      font:
        size: 15px
        weight: 600
      width: 100%
      background: none
      display: block
      border: none
      border-radius: 20px
      padding: 0 5px 0 5px
      margin-top: 25px
      cursor: pointer
      float: none
      text-align: center
      transition: all $animation-speed $animation-type

      .icon
        width: 22px
        margin-right: 3px
        height: auto
        vertical-align: sub

      &:hover
        background: none
        box-shadow: none

        img
          filter: none

    &.top
      margin-top: 0

.tos_page
  .btn
    &__default
      &.blue
        margin: 0
        text-align: right

$borderRadius: 8px;
$maxWidth: 450px;

.surveyStep {
  padding: 28px 28px 0 28px;
  max-width: $maxWidth;
  margin: 0 auto;
  display: flex;
  flex-direction: column;

  @media (min-width: 768px) {
    min-height: calc(100vh - 56px);
  }
}

.stepContentContainer {
  flex: 1 1 100%;
}

.stepContent {
  padding-bottom: 160px;
}

.stepSubmit {
  padding: 32px 28px;
  position: fixed;
  bottom: 56px;
  left: 0;
  right: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, #fff 28.5%);
}

.stepSubmitButton {
  margin: 0 auto;
  max-width: $maxWidth;
}

.surveyStep :global(.MuiButton-contained.Mui-disabled) {
  background-color: var(--genom-plus-survey-btn-disabled-bg);
  color: var(--genom-plus-survey-btn-disabled-color);
}

.back,
.back:focus,
.back:focus-visible,
.back:focus-within {
  border: 0;
  padding: 0;
  margin: 0;
  background: none;
  display: flex;
  align-items: center;
  color: var(--genom-plus-survey-back-color);
  font-family: Helvetica Neue;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 24px;
  cursor: pointer;
  [dir='rtl'] & {
    svg {
      transform: scaleX(-1);
    }
  }
}

.progressLabel {
  color: var(--genom-plus-survey-progress-label-color);
  font-family: Helvetica Neue;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.5px;
  margin-bottom: 8px;
}

.progressBar {
  padding: 4px;
  background: var(--genom-plus-survey-progress-bar-bg);
  border-radius: $borderRadius;
  margin-bottom: 16px;
  [dir='rtl'] & {
    transform: scaleX(-1);
  }
}

.progressBar :global(.MuiLinearProgress-root) {
  height: 8px;
  border-radius: $borderRadius;
  background-color: transparent;
}

.progressBar :global(.MuiLinearProgress-bar1Determinate) {
  border-radius: $borderRadius;
}

.title {
  color: var(--genom-plus-survey-title-color);
  font-family: Helvetica Neue;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.5px;
  margin-bottom: 24px;
}

.subtitle {
  color: var(--genom-plus-survey-subtitle-color);
  font-family: Helvetica Neue;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;
  margin-bottom: 24px;
  margin-top: -12px;
}

.text {
  color: var(--genom-plus-survey-text-color);
  font-family: Helvetica Neue;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
  margin-bottom: 5px;
}

.questionBlock {
  margin-bottom: 32px;

  &:last-child {
    margin-bottom: 0;
  }
}

.bold {
  font-weight: 700;
}

.email {
  color: var(--genom-plus-survey-email-color);
  font-family: Helvetica Neue;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.5px;
}

.contentCompleted {
  text-align: center;
}

.completedArt {
  padding: 32px 0 24px 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  animation: fadeIn 0.75s ease-out;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.flash_message
  position: fixed
  top: 10px
  right: 10px
  z-index: 10000 // so that above modals.sass->.modal
  max-width: 350px
  word-wrap: break-word

  &__msg
    position: relative
    background: $dark-text
    font:
      size: 16px
      weight: normal
    color: var(--color-white)
    padding: 10px 20px
    border-radius: 20px
    margin-bottom: 7px
    border: 1px solid darken($dark-text, 7)
    animation:
      name: fade-in-up
      duration: 0.3s
      timing-function: ease-in-out

@media(max-width: $mobile)
  .flash_message
    position: relative
    top: auto
    right: 0
    left: 0
    max-width: none
    width: 100%

    &__msg
      border-radius: 0
      padding: 10px
      margin: 0
      animation:
        name: fade-in
        duration: 0.3s
        timing-function: ease-in-out
      text-align: center

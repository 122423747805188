.container {
  display: flex;
  max-width: 366px;
  width: 100%;
  margin-top: 24px;
  gap: 40px;
  align-items: center;
  justify-content: center;
}

.buttonContainer {
  flex: 1 1 0;
}

.buttonNormalize {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  text-align: inherit;
  font: inherit;
  border-radius: 0;
  appearance: none;
}

.mainButton {
  display: flex;
  width: 96px;
  height: 64px;
  padding: 9px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  background: linear-gradient(264deg, #4ec5ba 0%, #2da092 100%);
  box-shadow: 0px 2px 4px 0px rgba(105, 159, 153, 0.4);
  transition: ease-in-out 0.2s;

  [dir='rtl'] & {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
}

.mainButton:hover {
  cursor: pointer;
  background: linear-gradient(262deg, #2a9d8f, #1d746a);
}

.buttonImage {
  width: 40px;
  height: 40px;
  object-fit: cover;
  flex-shrink: 0;
}

.skipButton {
  display: flex;
  width: 64px;
  height: 64px;
  padding: 4px;
  justify-content: center;
  align-items: center;
  border-radius: 58px;
  background: #f7f7f7;
  transition: ease-in-out 0.1s;

  [dir='rtl'] & {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
}

.skipButton:hover {
  cursor: pointer;
  background: rgba(182, 182, 182, 0.2);
}

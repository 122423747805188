.link {
  text-decoration: none;
}

.container {
  display: flex;
  flex-direction: column;
  margin-bottom: 40px;
  cursor: pointer;
}

.button {
  color: var(--button-text) !important;
  border-color: currentColor !important;
  font-size: 14.3px !important;
  font-weight: 500 !important;
  letter-spacing: 1.25px !important;
  background-color: var(--button-background) !important;

  &:hover {
    color: var(--button-text-hover) !important;
    background-color: var(--button-background-hover) !important;
    text-decoration: underline;
    border-color: currentColor !important;
    background-color: var(--button-background-hover) !important;
  }
}

.right {
  composes: button;

  color: var(--secondary-button-text) !important;
  border-color: var(--secondary-button-border) !important;
  align-self: flex-end;

  &:hover {
    color: var(--secondary-button-text) !important;
    border-color: var(--secondary-button-border) !important;
    background-color: var(--secondary-button-background-hover) !important;
  }
}

.contained {
  composes: button;

  &:hover {
    color: var(--color-global-black);
  }
}

.ketoButton {
  composes: button;

  border: none !important;
  color: var(--tertiary-button-contained-text) !important;
  background: var(--tertiary-button-contained-background) !important;
  box-shadow: var(--tertiary-button-contained-box-shadow) !important;

  &:hover {
    color: var(--tertiary-button-contained-text-hover) !important;
    background: var(--tertiary-button-contained-background-hover) !important;
    box-shadow: var(--tertiary-button-contained-box-shadow) !important;
  }
}

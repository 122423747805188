.bg__video
  position: fixed
  background: $dark-text
  top: 50%
  left: 50%
  min-width: 100%
  min-height: 100%
  width: auto
  height: auto
  z-index: -101
  transform: translateX(-50%) translateY(-50%)

  #bgvid
    position: fixed
    top: 50%
    left: 50%
    min-width: 100%
    min-height: 100%
    width: auto
    height: auto
    z-index: -100
    transform: translateX(-50%) translateY(-50%)
    background-size: cover

.bg__image
  z-index: -1
  position: fixed
  background-size: cover
  left: 0
  right: 0
  top: 0
  bottom: 0

.tos_btn
  display: block
  color: var(--color-white)
  padding: 10px 20px
  text:
    align: center
    decoration: underline
  cursor: pointer

  &:hover
    text:
      decoration: none

.login_form
  position: relative
  width: 420px
  margin: 50px auto 0 auto
  background: var(--color-white)
  border-radius: 6px
  padding: 15px 50px 20px 50px
  box-shadow: 0 2px 3px 0 rgba(0,0,0,.2)
  animation:
    name: fade-in-up
    duration: 0.5s
    timing-function: ease-in-out

  .Logo
    display: block
    margin: 10px auto 30px auto

  &__title
    font:
      size: 22px
      weight: 500
    color: $dark-text
    text-align: center

  &__social
    text-align: center
    margin: 20px 0

    &__title
      font:
        size: 14px
        weight: 500
      color: $light-text
      margin: 0 0 15px 0
      text-align: center

@media(max-width: 458px)
  .login_form
    margin-top: 15px
    width: 350px
    padding: 15px 20px 40px 20px

    .Logo
      width: 100%

  .tos_page
    margin: 0
    border-radius: 0

    ol
      -webkit-padding-start: 15px

@media(max-width: 390px)
  .login_form
    width: 100%

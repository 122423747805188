.browser-sup
  position: relative
  background: var(--color-red-4)
  border: 1px solid var(--color-red-5)
  max-width: 420px
  width: 100%
  text-align: center
  border-radius: 16px
  padding: 10px
  color: var(--color-white)
  font-size: 14px
  line-height: 17px
  margin: 10px auto -40px

@media(max-width: 458px)
  .browser-sup
    margin-bottom: 0
    max-width: 350px

.container {
  cursor: unset;
  overflow: unset;
  width: 100%;
  height: unset;
  border-radius: unset;
  position: relative;
  border: unset;
  border-color: var(--language-dropdown--border);
  background-color: var(--language-dropdown--background);
  margin: 16px 0;
  transition: all 0.2s ease-in-out;

  &:hover {
    box-shadow: none;
    background-color: none;
    border-color: var(--language-dropdown--border-hover);
  }

  &:before {
    content: none;
  }
}

.select {
  appearance: unset;
  font-weight: unset;
  width: 100%;
  height: unset;
  background-color: unset;
  color: unset;
  text-transform: unset;
  font-size: unset;
  padding: unset;
  cursor: unset;
  border: unset;
  outline: unset;
  padding-left: unset;
  position: unset;
  z-index: unset;
}

.loader {
  top: 15px;
}

.fullWidth {
  width: 100%;
}

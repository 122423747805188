.container {
  display: flex;
  align-items: center;
  justify-content: center;
}

.pausedBackground {
  max-width: 482px;
  height: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -1;
}

.wrapper {
  display: flex;
  flex-direction: column;
  max-width: 516px;
  text-align: center;
  align-items: center;
  padding: 48px 24px 32px;

  @media (max-width: 460px) {
    padding: 48px 24px 32px;
  }
}

.pauseImage {
  width: 96px;
  height: 96px;
  object-fit: cover;
}

.title {
  padding: 24px 0 8px;
  margin: 0;
  color: #000;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.5px;
}

.subTitle {
  margin: 0;
  color: #666;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.question {
  padding: 48px 0 12px;
  margin: 0;
  color: #000;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 24px; /* 150% */
  letter-spacing: 0.5px;
}

.buttonWrapper {
  width: 100%;
}

.container {
  display: flex;
  align-items: flex-start;
}

.title {
  margin: 0;
  font-size: 14.3px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.4;
  letter-spacing: 0.25px;
}

.context {
  font-size: 20.4px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.25px;
}

.icon {
  height: 44px;
  width: 44px;
}

.header {
  padding-left: 8px;
}

.base {
  box-shadow: var(--flash-message-box-shadow);
  border: none;
  font-size: 14.3px;
  line-height: 1.4;
  letter-spacing: 0.25px;
  color: var(--color-white);
  border-radius: 0;
  text-align: start;
  padding: 14px 16px;
  padding-top: calc(env(safe-area-inset-top) + 14px);

  @media (min-width: 461px) {
    border-radius: 4px;
  }
}

.success {
  composes: base;

  background-color: var(--flash-message-success);
}

.error {
  composes: base;

  background: var(--flash-message-error);
}

.button {
  text-decoration: none;
  color: var(--primary-accent-color) !important;

  &:hover {
    color: var(--primary-accent-color) !important;
    text-decoration: underline;
  }
}

.right {
  align-self: flex-end;
}

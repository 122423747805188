.header {
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;

  .headerInformation {
    display: flex;

    .icon {
      height: 44px;
      width: 44px;
    }

    .text {
      flex: 1;
      margin-left: 8px;
    }

    .info {
      margin: 0;
      font-size: 14.3px;
      line-height: 1.4;
      letter-spacing: 0.25px;
      color: var(--meal-info-text);

      img {
        margin-right: 4px;
      }

      .fastingSmall {
        margin-right: 4px;
      }
    }

    .title {
      margin: 0;
      font-size: 20.4px;
      font-weight: 500;
      letter-spacing: 0.25px;
      color: var(--meal-title);
    }
  }
}

.link {
  text-decoration: none;
}

.container {
  display: flex;
  flex-direction: column;
  cursor: pointer;
}

.container:last-child {
  margin-bottom: 40px;
}

.opacityContainer {
  composes: container;

  opacity: 0.5;
}

.header {
  display: flex;
  align-items: flex-start;
  margin-bottom: 8px;
}

.counter {
  border-radius: 3px;
  background-color: var(--meal-lock-icon-background);
  height: 44px;
  width: 44px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: var(--meal-lock-icon);
  font-size: 24px;
  font-weight: 900;
}

.icon {
  height: 44px;
  width: 44px;
}

.text {
  flex: 1;
  margin-left: 8px;
}

.info {
  margin: 0;
  font-size: 14.3px;
  line-height: 1.4;
  letter-spacing: 0.25px;
  color: var(--meal-info-text);
}

.title {
  margin: 0;
  font-size: 20.4px;
  font-weight: 500;
  letter-spacing: 0.25px;
  color: var(--meal-title);
}

.benefits {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
}

.benefitIcon {
  height: 14px;
  position: relative;
  top: 3px;
  margin-right: 4px;
}

.benefit {
  font-size: 12px;
  color: var(--meal-benefit-text);
  vertical-align: middle;
  margin-right: 8px;
  white-space: nowrap;
}

.imageContainer {
  position: relative;
  height: 173px;
  border-radius: 3px;
  background: var(--meal-image-container-background);
}

.image {
  width: 100%;
  height: 100%;
  max-height: 173px;
  object-fit: cover;
  border-radius: 3px;
}

.checkmark {
  position: absolute;
  right: 0;
  margin-right: 8px;
  margin-bottom: 8px;
  bottom: 0;
}

.newLinkButton {
  all: unset;
  cursor: pointer;
  width: 100%;
}

.breakfastOverlay {
  position: absolute;
  left: 0;
  top: 0;
  z-index: 6;
  width: 100%;
  height: 100%;
  max-height: 173px;
  object-fit: cover;
  border-radius: 3px;
}

.exclamation {
  position: absolute;
  z-index: 7;
  bottom: 0;
  right: 0;
  display: inline-flex;
  padding: 4px 12px;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
  background: rgba(255, 255, 255, 0.9);
  color: #000;
  margin: 0;
  margin-right: 8px;
  margin-bottom: 8px;
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 18px;
  letter-spacing: 0.5px;
}

@import '~assets/style/helpers.scss';

.container {
  @include retina-image('program/background/diet', 'jpg');
  background-position: center top;
  background-repeat: no-repeat;
  object-fit: contain;
  background-size: 482px 170px;
  padding-top: env(safe-area-inset-top);
  padding-bottom: calc(env(safe-area-inset-bottom) + 56px);
}

.gradient {
  display: block;
  max-width: 482px;
  margin: 0 auto;
  position: relative;

  &:before,
  &:after {
    content: '';
    position: absolute;
    top: 0;
    height: 100vh;
    width: 16px;
    z-index: 1;
  }

  &:before {
    left: -1px;
    background: linear-gradient(
      90deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0.001) 100%
    );
  }

  &:after {
    right: -1px;
    background: linear-gradient(
      270deg,
      #ffffff 0%,
      rgba(255, 255, 255, 0.001) 100%
    );
  }
}

@media (max-width: 460px) {
  .gradient {
    display: none;
  }
}

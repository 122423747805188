.slider_input
  position: relative
  text-align: center
  margin: 20px 0 40px 0

  &__img
    width: 100%
    max-width: 449px
    padding: 0 10px

  &--label
    text-align: left
    display: block
    font-size: 15px
    font-weight: 600
    color: $dark-text
    margin: 0 0 10px 20px

  &__slider
    width: 100%

    &--percent
      position: absolute
      bottom: 25px
      color: $blue
      font:
        size: 20px
        weight: 600

    &--input
      margin-top: 20px
      width: 100%
      transform: scale(-1)
      appearance: none
      height: 10px
      background: $grey
      outline: none
      border-radius: 10px
      cursor: pointer

      &:hover
        background: darken($grey, 2)

      &::-webkit-slider-thumb
        border: none
        transform: rotateX(180deg)
        background: image('registration/thumb.svg')
        width: 30px
        height: 30px
        appearance: none
        cursor: pointer

.container {
  list-style-type: '';
  list-style: none;
}

.selected {
  background-color: var(--check-box-selected-background) !important;
}

.customSizeSpan {
  margin-right: 25px;
  word-wrap: break-word;
}

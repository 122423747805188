.overlay {
  max-width: 482px;

  &::before,
  &::after {
    content: none;
  }

  @media (max-width: 460px) {
    &::before,
    &::after {
      content: '';
    }

    &:before {
      left: 0;
      background: var(--primary-calendar-mobile-background-left);
    }

    &:after {
      right: 0;
      background: var(--primary-calendar-mobile-background-right);
    }
  }
}

.container {
  max-width: 482px;
  margin: 0;
  padding: 12px 0 16px;
}

.button {
  margin: 0 25px;
}

.icon {
  width: 15px;
  height: 24px;
  fill: var(--primary-calendar-button) !important;

  &:hover {
    fill: var(--primary-calendar-button);
  }
}

.container {
  background: none;
  background-size: 100%;
  background-repeat: no-repeat;
  border: none;
  border-radius: 0;
  height: 70px;
  width: 70px;
  overflow: visible;

  &:before {
    content: '';
    height: 10px;
    width: 100%;
    background-color: var(--color-grey-16);
    border-radius: 2px 2px 0 0;
  }
}

.done {
  background-clip: content-box;
  background-position: center bottom;
  background-image: url('~assets/images/program/calendar/past/resting.svg');

  &:hover {
    border-color: transparent;
  }

  @media (hover: hover) {
    &:hover {
      background-image: url('~assets/images/program/calendar/past/hover.svg');
      box-shadow: none;
      filter: none;
    }
  }

  &:focus,
  &.isSelected {
    border: none;
    background: none;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-image: url('~assets/images/program/calendar/past/selected.svg');
    box-shadow: none;
    filter: drop-shadow(0 1px 5px var(--color-black-opacity5));
  }

  &:before {
    background: none;
  }
}
.today,
.future {
  background-clip: content-box;
  border: solid 1px rgba(0, 0, 0, 0.12);
  background-color: var(--color-grey-25);
  border-radius: 3px;

  &:hover {
    border-color: transparent;
  }

  @media (hover: hover) {
    &:hover {
      box-shadow: none;
      padding: 0;
      border: solid 1px rgba(0, 0, 0, 0.24);
      background-color: var(--diet-grey-color-2);
    }
  }

  &:focus,
  &.isSelected {
    background-clip: content-box;
    border: none;
    padding: 1px;
    filter: drop-shadow(0 1px 5px var(--color-black-opacity5));
    background-color: var(--color-grey-27);
  }
}

.today {
  &:before {
    background: var(--color-yellow-2);
  }
}

.today {
  &:focus,
  &.isSelected {
    border: none;
    padding: 1px;
  }
}

.dayName {
  font-size: 12px;
  margin-top: 6px;
  color: var(--color-grey);
  text-transform: capitalize;
}

.date {
  font-size: 14px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--colord-grey);
  width: 100%;
  padding: 0 4px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

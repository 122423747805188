.fb_text
  font:
    size: 14px
    weight: normal
  text-align: center

  &__error
    color: var(--color-red-3)

  &__info
    color: var(--color-grey-2)

.merge_buttons
  padding-top: 20px

.merge_text
  text-align: center

  &__disclaimer
    color: var(--color-grey-3)
    font-size: 12px

  &__create
    color: var(--color-grey-2)
    font-size: 14px
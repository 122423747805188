.container {
  width: 100%;
}

.selected {
  background: rgba(255, 158, 0, 0.07) !important;
}

.radio:checked + .label {
  background: var(--color-blue);
  border-color: var(--color-blue-3);
  color: var(--color-white);
  text-shadow: 0px 1px 2px var(--color-black-opacity6);
}

.label.checked {
  background: var(--color-blue);
  border-color: var(--color-blue-3);
  color: var(--color-white);
  text-shadow: 0px 1px 2px var(--color-black-opacity6);
}

.select_label {
  display: block;
  font-size: 15px;
  font-weight: 600;
  color: var(--primary-title-color);
  margin: 0 0 5px 20px;
}

.inputs {
  display: flex;

  & > label {
    flex: 1;
    width: unset;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.container {
  min-height: 100vh;

  // Target Safari only by checking support of an Apple-specific property.
  // This way, we fix the vh units issue, which Safari does not respect.
  @supports (-webkit-touch-callout: none) {
    min-height: -webkit-fill-available;
  }
}

.background {
  background: url('../assets/images/login_bg_monacofit.jpg') no-repeat center
    center fixed;
  background-size: cover;
}

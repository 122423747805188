.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: var(--diary-background);
  border-radius: 12px;
  margin: 100px auto;
  max-width: 420px;
  background-clip: padding-box;
  border: 8px solid transparent;
  padding: 72px 16px 48px;
  outline: none;
}

.calendar {
  position: absolute;
  top: -52px;
  width: 104px;
  height: 104px;
  border-radius: 3px;
  box-shadow: var(--diary-calendar-boxshadow);
  background-color: var(--diary-calendar-background);
  border-top: 15px solid var(--diary-calendar-border);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 8px 3px 7px;
}

.dayName {
  font-size: 16px;
  color: var(--color-global-grey-2);
}

.date {
  font-size: 22px;
  font-weight: bold;
  color: var(--color-global-grey-2);
  white-space: nowrap;
}

.events {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.event {
  width: 15px;
  height: 15px;
  border-radius: 2px;
  background-color: var(--diary-event);
}

.text {
  font-size: 16.4px;
  line-height: 1.47;
  letter-spacing: 0.15px;
  text-align: center;
  color: var(--diary-text);
}

.question {
  font-size: 20.4px;
  font-weight: 500;
  letter-spacing: 0.25px;
  text-align: center;
  color: var(--diary-text);
}

.inputs {
  max-width: 140px;
}

.input {
  margin-bottom: 28px !important;
  width: 140px;

  // Remove spinners on Safari, Chrome
  & input[type='number']::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  // Remove spinners on Firefox
  & input[type='number'] {
    -moz-appearance: textfield;
  }
}

.dayName {
  text-transform: capitalize;
}

.workoutButton {
  margin-top: 32px;

  @media (max-width: 475px) {
    background: var(--workout-button-container-bg);
    position: fixed;
    width: 100%;
    bottom: 0;
    padding: 16px 24px 24px;
    margin-left: -24px;
    margin-top: 24px;

    [dir='rtl'] & {
      margin-left: 0;
      margin-right: -24px;
    }
  }
}

.transparentBg {
  @media (max-width: 475px) {
    background: transparent;
  }
}

button.submitButton {
  width: 100%;
  color: var(--workouts-button-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.5px;
  background: var(--workouts-button-bg);
  padding: 9px 16px;
  text-align: center;
  box-shadow: var(--workouts-button-boxshadow);
  min-height: 64px;
}

button.submitButton:hover {
  background: var(--workouts-button-bg-hover);
  box-shadow: var(--workouts-button-boxshadow-hover);
}

button.submitButton:active,
button.submitButton:focus {
  background: var(--workouts-button-bg-active);
  box-shadow: var(--workouts-button-boxshadow-active);
}

button.submitButton:disabled {
  background: var(--workouts-button-disabled-bg);
  color: var(--workouts-button-disabled-text);
}

div.spinner {
  color: var(--workouts-spinner);
}

.buttonImage {
  width: 24px;
  height: 24px;
  object-fit: cover;
  margin-right: 6px;

  [dir='rtl'] & {
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
}

.wrapper {
  background: var(--mf-dark-orange-color);
}

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  max-width: 675px;
}

.logo {
  width: 174px;
}

a + ul {
  margin-left: 32px !important;
}

.link {
  color: var(--color-white);
}

.link:focus {
  outline: 0;
}

.active {
  color: #60bd78;
}

@media (max-width: 630px) {
  a + ul {
    margin-left: 0 !important;
  }
}

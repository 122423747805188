.fit
  &__nav
    width: 100%

    &__container
      margin: 0 auto

      &__logo
        height: 29px
        margin-top: 5px
        box-shadow: none

      &__menu
        list-style: none
        float: right
        margin: 10px 0 0
        padding-inline-start: 0

        &__btn
          display: inline-block

          &:first-child > &__link
            margin-left: 0

          &__link
            font-size: 14px
            text-decoration: none
            border-radius: 13px
            padding-top: 5px 0
            margin-left: 16px
            transition: all $animation-speed $animation-type

            &.disabled
              pointer-events: none
              opacity: .7

            &__img
              vertical-align: middle
              margin-right: 4px
              margin-top: -4px
              width: 16px
              height: 16px

            &__txt
              display: inline-block

@media(max-width: 610px)
  .fit
    &__nav
      position: fixed
      bottom: 0
      left: 0
      right: 0
      height: 60px
      z-index: 999
      height: auto
      padding-bottom: env(safe-area-inset-bottom)

      &__container
        width: 100%

        &__logo
          display: none

        &__menu
          padding-inline-start: 0
          width: 100%
          margin-top: 4px
          margin-bottom: 4px
          display: flex

          &__btn
            flex: 1
            padding: 0 5px

            &__link
              background: none
              display: inline-block
              width: 100%
              font-size: 11px
              padding: 5px 0
              margin: 0
              text-align: center

              &__img
                display: block
                width: 24px
                height: 24px
                margin: 0 auto 5px auto

              &:hover
                background: none

              &.active
                background: none
                background: linear-gradient(to bottom, var(--color-grey-13) 1%,var(--color-grey-15) 100%)

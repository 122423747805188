@use '../../../../src/assets/style/theme/fitdiet/_animations.sass' as a;

.container {
  background: var(--primary-background-color);
}

.title {
  color: var(--primary-title-color);
}

.value {
  display: block;
  font-size: 21px;
  font-weight: 600;
  color: var(--primary-accent-color);
  text-align: center;
}

.description {
  display: block;
  font-size: 12px;
  font-weight: normal;
  color: var(--primary-title-color);
  text-align: center;
}

.slider {
  position: absolute;
  width: 38px;
  right: 56%;
  bottom: 135px;
  color: var(--primary-accent-color);
  font-size: 18px;
  font-weight: 600;
}

.arrow {
  position: absolute;
  top: 2px;
  left: -15px;
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 7px 0 7px 13px;
  border-color: transparent transparent transparent var(--primary-accent-color);
}

.input {
  &::-webkit-slider-thumb {
    background: var(--primary-accent-color);
    border: 4px solid var(--primary-background-color);
  }

  &::-moz-range-thumb {
    background: var(--primary-accent-color);
    border: 4px solid var(--primary-background-color);
  }

  &::-moz-range-track {
    background: var(--primary-accent-color);
    width: 100%;
    height: 20px;
    border-radius: 10px;
  }

  &::-ms-thumb {
    background: var(--primary-accent-color);
    border: 4px solid var(--primary-background-color);
  }

  &::-webkit-slider-runnable-track {
    background: var(--primary-accent-color);
  }

  &:focus::-webkit-slider-runnable-track {
    background: var(--primary-accent-color);
  }

  &:active &::-webkit-slider-thumb {
    background: var(--primary-accent-color);
  }
}

.form
  &__input
    &__checkbox
      &:not(:checked), &:checked
        display: inline-block
        position: absolute
        left: -9999px

        & + .form__input__checkbox__label
          display: inline-block
          font:
            size: 14px
            weight: 500
          position: relative
          padding: 3px 0 3px 2.4em
          cursor: pointer

          &:before
            content: ''
            position: absolute
            left: 0
            top: 0
            width: 1.25em
            height: 1.25em
            border-radius: 4px

          &:after
            content: ''
            position: absolute
            display: block
            top: 2px
            left: 8px
            width: 3px
            height: 12px
            border-width: 0 3px 3px 0
            border-radius: 0
            transition: all $animation-speed $animation-type

      &:not(:checked)
        & + .form__input__checkbox__label:after
          opacity: 0
          transform: scale(0)
          transform: rotate(55deg)

      &:checked
        & + .form__input__checkbox__label
          &:after
            opacity: 1
            transform: scale(1)
            transform: rotate(45deg)

      // Disabled checkbox
      &:disabled:not(:checked), &:disabled:checked
        & + .form__input__checkbox__label:before
          box-shadow: none
          border-color: var(--color-grey-9)
          background-color: var(--color-grey-17)

      &:disabled
        & + .form__input__checkbox__label
          color: var(--color-grey-18)

        &:checked
          & + .form__input__checkbox__label:after
            color: var(--color-grey-19)
      // *****

.form
  &.center
    margin: 30px 0 0 40px
  &.block
    .form__input.checkbox
      margin: 17px 0 17px 90px

      &.problem_zone
        margin-left: 40px

      &.gdpr
        margin-left: 20px
        color: $dark-text

        .form__input__checkbox__label
          color: $dark-text

          &:before
            border: 2px solid $dark-text

    .form__input__checkbox__label__desc
      font-size: 13px
      display: block
      padding: 3px 0 0 32px
      color: $regular-text
      cursor: pointer

  &.product
    .form__input__checkbox
      &:not(:checked)
        & + .form__input__checkbox__label
          text-decoration: red line-through

@media(max-width: $mobile)
  .form
    &.center
      margin: 30px 0 0 0

    &.block
      .form__input.checkbox
        margin: 17px 0 17px 30px

:root {
  // Primary/Secondary colors
  --primary-background-color: white;
  --primary-title-color: #2e3033;
  --primary-text-color: #bbbbbb;
  --primary-accent-color: #0081ff;
  --primary-accent-color-dk: #8aa126;
  --secondary-accent-color: #52cc7a;

  // Global-colors
  --color-global-white: white;
  --color-global-black: black;
  --color-global-grey: #2e3033;
  --color-global-grey-2: #333333;
  --color-global-text-opacity-8: rgba(0, 0, 0, 0.87);
  --color-global-text-opacity-6: rgba(0, 0, 0, 0.6);
  --color-global-text-opacity-7: rgba(0, 0, 0, 0.7);
  --color-global-text-opacity-3: rgba(0, 0, 0, 0.38);

  // Warning
  --global-warning-color: #b00020;

  // Start: Global-variables
  --global-statistics-text: #464646;
  // TOS
  --primary-tos-text: var(--color-global-grey);
  --primary-tos-background: var(--color-global-white);
  // Testamonial
  --global-testamonial-text: var(--color-global-text-opacity-8);
  --global-testamonial-email-text: var(--color-global-text-opacity-3);
  --global-testamonial-question-text: var(--color-global-text-opacity-6);

  // End: Global-variables

  // SubmitButton
  --primary-submit-button-background: #52cc7a;
  --primary-submit-button-text: white;
  --primary-submit-button-background-hover: #36b55f;
  --primary-submit-button-background-active: #36b55f;
  --primary-submit-button-background-loading: #ced5da;
  --primary-submit-box-shadow: 0px 7px 20px 0px rgba(82, 204, 122, 0.5);
  --primary-submit-box-shadow-hover: 0px 7px 20px 0px rgba(82, 204, 122, 0.7);
  --primary-submit-box-shadow-active: 0px 7px 20px 0px rgba(82, 204, 122, 0.4);
  --secondary-submit-button-text: #0081ff;

  // START: Offer Templates
  // PremiumUpsell
  --premium-upsell-title: var(--color-global-text-opacity-8);
  --premium-upsell-price-text: var(--color-global-text-opacity-6);
  --premium-upsell-recommendation-text: var(--color-global-text-opacity-6);
  --premium-upsell-timer-text: #369ef3;
  --premium-upsell-testimonial-subtitle: var(--color-global-text-opacity-8);
  --premium-upsell-testimonial-text: var(--color-global-grey-2);
  --premium-upsell-main-title-background: linear-gradient(
    to bottom,
    #eeeeee,
    #ffffff
  );
  --premium-upsell-include-background: #fafafa;
  --premium-upsell-include-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --premium-upsell-include-text-background: #ededed;
  --premium-upsell-include-title: var(--color-global-text-opacity-8);
  --premium-upsell-include-text: var(--color-global-black);
  --premium-upsell-include-image-background: #f8f8f8;
  // END: Offer Templates

  // MealNutrients
  --meal-nutrients-text: var(--color-global-grey-2);

  // ShoppingListPage
  --shopping-native-select-border: #bbbbbb;

  // Global colors
  --color-white: white;
  --color-black: black;

  --color-black-opacity3: rgba(0, 0, 0, 0.38);
  --color-black-opacity5: rgba(0, 0, 0, 0.5);
  --color-black-opacity6: rgba(0, 0, 0, 0.6);
  --color-black-opacity7: rgba(0, 0, 0, 0.7);
  --color-black-opacity8: rgba(0, 0, 0, 0.87);

  --color-yellow: #ffc90d;
  --color-yellow-2: #ffb100;
  --color-yellow-3: #ffd445;
  --color-yellow-4: #ff9e00;
  --color-yellow-6: #ffe6be;
  --color-yellow-7: navajowhite;
  --color-yellow-8: #e2c391;
  --color-yellow-9: #fdbe13;

  --color-red: #fa0000;
  --color-red-2: #b00020;
  --color-red-3: #d0021b;
  --color-red-4: #cc5252;
  --color-red-5: #c13939;
  --color-red-6: #ff0c3e;
  --color-red-7: #9b0417;

  --color-orange: #ff7d00;
  --color-orange-2: #f6f4f3;

  --color-blue: #0081ff;
  --color-blue-2: #6daedb;
  --color-blue-3: #0079f0;
  --color-blue-4: #4267b2;
  --color-blue-5: #3b5998;
  --color-blue-6: #1d70b4;
  --color-blue-7: #369ef3;
  --color-blue-8: #016fcf;

  --color-grey: #333333;
  --color-grey-2: #2e3033;
  --color-grey-3: #b8c2cc;
  --color-grey-4: #d6d6d6;
  --color-grey-5: #ededed;
  --color-grey-6: #ebeef0;
  --color-grey-7: #a6a6a6;
  --color-grey-8: #737980;
  --color-grey-9: #bbbbbb;
  --color-grey-10: #4f4f4f;
  --color-grey-11: #323232;
  --color-grey-12: #5e5e5e;
  --color-grey-13: #262626;
  --color-grey-14: #2f2f2f;
  --color-grey-15: #525252;
  --color-grey-16: #464646;
  --color-grey-17: #ddd;
  --color-grey-18: #aaa;
  --color-grey-19: #999;
  --color-grey-20: #ccc;
  --color-grey-21: #e6e6e6;
  --color-grey-22: #f5faff;
  --color-grey-23: #e5e5e5;
  --color-grey-24: #ffe7f2;
  --color-grey-25: #fafafa;
  --color-grey-26: #646464;
  --color-grey-27: #fff8e0;
  --color-grey-28: #979797;
  --color-grey-29: #cccccc;
  --color-grey-30: #d4d4d4;
  --color-grey-31: #c3bfbf;
  --color-grey-32: #d0d0d0;
  --color-gray-33: #e6f0ef;
  --color-gray-34: #f0f4f4;
  --color-gray-35: #c8c8c8;
  --color-gray-36: #dcefec;

  --color-green: #74c882;

  // Product specific colors:
  --mf-dark-orange-color: #221a17;
  --mf-green-color: #36b55f;
  --mf-lighter-black-color: #191c21;

  //SubscriptionText
  --subscription-warning-text: var(--global-warning-color);
  --subscription-link: var(--primary-accent-color);

  --chatline-link: var(--color-red-2);

  // PostPayment
  --payment-failed-text: var(--color-global-grey);
  --payment-failed-background: var(--color-global-white);
  --payment-failed-modal-background: var(--color-global-text-opacity-6);
  --payment-failed-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
  --payment-failed-close-icon-hover: #ced5da;

  --premium-sub-warning-background: #fff;
  --premium-sub-warning-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --premium-sub-warning-exit-icon-fill: #646464;
  --premium-sub-warning-text-color: var(--color-global-grey);

  // PremiumSubscription
  --premium-block-border: #ebeef0;
  --premium-block-title: var(--color-global-grey);
  --premium-block-text: var(--color-global-grey);
  --premium-block-text-subscribed: #a6a6a6;

  --unsubscribed-modal-bg-color: var(--primary-background-color);
  --discount-modal-bg-color: var(--primary-background-color);

  --unsubscribev3-modal-background: var(--color-global-white);
  --unsubscribev3-modal-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
  --unsubscribev3-modal-close-svg-hover: #ced5da;
  --unsubscribev3-modal-title: var(--color-global-grey);
  --unsubscribev3-modal-subtitle: var(--color-global-grey);
  --unsubscribev3-modal-paragraph: var(--color-global-grey);
  --unsubscribev3-modal-title-warning: var(--global-warning-color);
  --unsubscribev3-modal-fullprice-warning: var(--global-warning-color);

  // Quiz
  --quiz-title-color: var(--color-global-grey);
  --quiz-question-color: var(--color-global-grey);
  --quiz-answer-color: var(--color-global-grey);
  --quiz-answer-border: #ebeef0;
  --quiz-selected-answer-color: var(--color-global-white);
  --quiz-selected-answer-border: #0079f0;
  --quiz-selected-answer-background: #0081ff;

  --objection-text: var(--color-global-grey-2);
  --objection-title: var(--color-global-text-opacity-8);
  --objection-background: var(--premium-upsell-include-image-background);
  --objection-specialist-text: var(--color-global-text-opacity-6);
  --objection-link-button: var(--primary-accent-color);
  --objection-bubble-background: var(--premium-upsell-main-title-background);

  --quiz-modal-background: var(--color-global-white);
  --quiz-modal-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
  --quiz-modal-button-color: var(--color-global-grey);

  // ShoppingList Offer
  --shopping-list-offer-title: var(--color-global-grey);
  --shopping-list-offer-title-accent: var(--color-global-grey);
  --shopping-list-offer-price: var(--color-global-text-opacity-6);
  --shopping-list-offer-list-item: var(--color-global-grey);

  // FatBurnerPage
  --fat-burnet-page-title: var(--color-global-text-opacity-8);
  --fat-burner-page-support: var(--color-global-text-opacity-3);
  --fat-burner-chat-link: var(--primary-accent-color);

  // FatBurnerSuccessPage
  --fat-burner-success-page-title: var(--color-global-text-opacity-8);
  --fat-burner-success-page-text: var(--color-global-text-opacity-3);
  --fat-burner-success-page-link: var(--primary-accent-color);

  // FatBuner Offer
  --fat-burner-offer-text: var(--color-black-opacity6);
  --fat-burner-offer-title: var(--color-black-opacity8);
  --fat-burner-offer-delivery: #369ef3;
  --fat-burner-offer-image-background: #f8f8f8;
  --fat-burner-offer-main-title-background: linear-gradient(
    to bottom,
    #eeeeee,
    #ffffff
  );

  // FatBurnerProfileBlock
  --fat-burner-profile-block-title: var(--color-global-text-opacity-8);
  --fat-burner-profile-block-text: var(--color-global-text-opacity-6);
  --fat-burner-profile-block-border: #d6d6d6;

  // GenomPlusBlock
  --genom-plus-block-title: var(--color-global-text-opacity-8);
  --genom-plus-block-text: var(--color-global-text-opacity-6);
  --genom-plus-block-border: #d6d6d6;

  // AnnualProOffer
  --annual-pro-offer-background: #fff;
  --annual-pro-offer-box-shadow: 0 5px 15px 0 rgba(0, 0, 0, 0.5);
  --annual-pro-offer-warning-background: #ea1010;
  --annual-pro-offer-warning-text: rgba(255, 255, 255, 0.87);
  --annual-pro-offer-title: var(--color-global-grey);
  --annual-pro-offer-price: var(--color-global-grey);
  --annual-pro-offer-price-small: #b8c2cc;

  // AnnualCongratsView
  --annual-pro-congrats-background: #fff;
  --annual-pro-congrats-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --annual-pro-congrats-modal-shadow: red;
  --annual-pro-congrats-title: var(--color-global-grey);
  --annual-pro-congrats-text: var(--color-global-grey);

  // ShoppingListFree
  --shopping-list-free-text: var(--color-global-grey);
  --shopping-list-free-offer-text: var(--color-global-grey);
  --shopping-list-free-offer-price: var(--color-global-text-opacity-6);

  // TOSLinkBlock
  --tos-link-block-divider: #ebeef0;
  --tos-link-block-text: #0081ff;

  // CardSaveConfirmation
  --card-save-confirmation-background: #ffffff;
  --card-save-confirmation-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --card-save-confirmation-close-icon: #646464;
  --card-save-confirmation-title: var(--color-global-grey);
  --card-save-confirmation-text: var(--color-global-grey);

  // UnsubscribeReason
  --unsubscribe-reason-title: #ea1010;
  --unsubscribe-reason-subtitle: #666;
  --unsubscribe-reason-infoblock-bg: #f7f7f7;
  --unsubscribe-reason-infoblock-border: #bbb;
  --unsubscribe-reason-infotext: #000;
  --unsubscribe-reason-secondary-title: #000;
  --unsubscribe-reason-listitem-text: #000;
  --unsubscribe-reason-listitem-bg: #fbcfcf;
  --unsubscribe-reason-radiobutton-default: #bbb;
  --unsubscribe-reason-button-text: #fff;
  --unsubscribe-reason-button-bg: #ea1010;
  --unsubscribe-reason-button-boxshadow: 0px 2px 4px 0px
    rgba(105, 159, 153, 0.4);
  --unsubscribe-reason-button-bg-hover: #9b0415;
  --unsubscribe-reason-button-boxshadow-hover: 0px 2px 4px 0px
    rgba(105, 159, 153, 0.7);
  --unsubscribe-reason-button-bg-active: #9b0415;
  --unsubscribe-reason-button-boxshadow-active: 0px 2px 4px 0px
    rgba(105, 159, 153, 0.4);
  --unsubscribe-reason-button-disabled-bg: #fbcfcf;
  --unsubscribe-reason-button-disabled-text: rgba(70, 70, 70, 0.6);
  --unsubscribe-reason-spinner: rgba(70, 70, 70, 0.6);
  --unsubscribe-reason-length-text: #666;
  --unsubscribe-reason-max-capacity: #a12027;
  --unsubscribe-reason-input-border: #000000;
  --unsubscribe-reason-input-legend: #000000;

  // Workouts offer
  --workouts-offer-video-container-bg: #13302d;
  --workouts-offer-content-container-bg: linear-gradient(
    180deg,
    rgba(20, 49, 46, 0) 0,
    #13302d 70px
  );
  --workouts-offer-text-light: #fff;
  --workouts-offer-title: #fff;
  --workouts-offer-text-accent: #b2dbd8;
  --workouts-offer-benefit: #24423f;
  --workouts-offer-benefit-descr: #fff;
  --workouts-offer-full-plan-text: #000;
  --workouts-offer-full-plan-price: #666;
  --workouts-offer-full-plan-price-accent: #228176;
  --workouts-offer-card-bg: #fff;
  --workouts-offer-card-box-shadow: 0px 4px 16px 0px rgba(88, 88, 88, 0.32);
  --workouts-offer-card-text: #0b3e53;
  --workouts-offer-card-name: #b2dbd8;
  --workouts-offer-lost-weight-bg: #264b57;

  // Workouts profile block
  --workouts-profile-border: #d6d6d6;
  --workouts-profile-title: var(--color-global-text-opacity-8);
  --workouts-profile-subtitle: var(--color-global-text-opacity-6);

  // Workouts unsubscribe
  --workouts-unsub-info: #666;
  --workouts-unsub-cta-bg: #eef7f6;
  --workouts-unsub-shadow: linear-gradient(
    0deg,
    #eef7f6 0%,
    rgba(238, 247, 246, 0) 100%
  );
  --workouts-unsub-cta-accent: #228176;
  --workouts-unsub-bg: #264b57;
  --workouts-unsub-text-light: #fff;
  --workouts-unsub-warning-bg: #1f3d47;
  --workouts-unsub-art-shadow: linear-gradient(
    0deg,
    #264b57 14.23%,
    rgba(38, 75, 87, 0) 99.53%
  );
  --workouts-unsub-reason-length-text: #666;
  --workouts-unsub-reason-length-text-invalid: #a12027;
  --workouts-unsub-reason-input-border: #000000;
  --workouts-unsub-reason-input-legend: #000000;

  // Common
  --copy-text-alert-bg: #3fc585;
  --copy-text-alert-color: #fff;
  --badge-bg: #2a9d8f;
  --badge-color: #fff;
}

body.diet {
  // Primary/Secondary colors
  --primary-background-color: #f6f4f3;
  --primary-title-color: rgba(0, 0, 0, 0.87);
  --primary-text-color: rgba(0, 0, 0, 0.6);
  --primary-accent-color: #ffc90d;
  --secondary-accent-color-dk: #00409e;
  --secondary-accent-color-lt: #e3f1f8;

  // Slider
  --slider-span-lable-color: rgba(0, 0, 0, 0.87);

  // SubmitButton
  --primary-submit-button-background: #ffc90d;
  --primary-submit-button-text: rgba(0, 0, 0, 0.87);
  --primary-submit-button-background-hover: rgb(178, 140, 9);
  --primary-submit-button-background-active: rgb(178, 140, 9);
  --primary-submit-button-background-loading: #ced5da;
  --primary-submit-box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2),
    0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  --primary-submit-box-shadow-hover: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
    0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  --primary-submit-box-shadow-active: 0px 5px 5px -3px rgba(0, 0, 0, 0.2),
    0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12);
  --secondary-submit-button-text: #ffc90d;
  --secondary-submit-button-text-hover: #ffc90d;
  --secondary-submit-button-border: rgba(255, 201, 13, 0.5);
  --secondary-submit-button-border-hover: #ffc90d;
  --secondary-submit-button-background: transparent;
  --secondary-submit-button-background-hover: rgba(255, 201, 13, 0.04);
  --tertiary-submit-button-text: #ffc90d;

  // Global colors
  --color-white: white;
  --color-black: black;

  --color-black-opacity3: rgba(0, 0, 0, 0.38);
  --color-black-opacity5: rgba(0, 0, 0, 0.5);
  --color-black-opacity6: rgba(0, 0, 0, 0.6);
  --color-black-opacity7: rgba(0, 0, 0, 0.7);
  --color-black-opacity8: rgba(0, 0, 0, 0.87);

  --color-yellow: #ffc90d;
  --color-yellow-2: #ffb100;
  --color-yellow-3: #ffd445;
  --color-yellow-4: #ff9e00;
  --color-yellow-6: #ffe6be;
  --color-yellow-7: navajowhite;
  --color-yellow-8: #e2c391;
  --color-yellow-9: #fdbe13;

  --color-red: #b00020;
  --color-red-2: #b00020;
  --color-red-3: #d0021b;
  --color-red-4: #cc5252;
  --color-red-5: #c13939;
  --color-red-6: #ff0c3e;
  --color-red-7: #9b0417;

  --color-orange: #ff7d00;
  --color-orange-2: #f6f4f3;

  --color-blue: #0081ff;
  --color-blue-2: #6daedb;
  --color-blue-3: #0079f0;
  --color-blue-4: #4267b2;
  --color-blue-5: #3b5998;
  --color-blue-6: #1d70b4;
  --color-blue-7: #369ef3;
  --color-blue-8: #016fcf;

  --color-grey: #333333;
  --color-grey-2: #2e3033;
  --color-grey-3: #b8c2cc;
  --color-grey-4: #d6d6d6;
  --color-grey-5: #ededed;
  --color-grey-6: #ebeef0;
  --color-grey-7: #a6a6a6;
  --color-grey-8: #737980;
  --color-grey-9: #bbbbbb;
  --color-grey-10: #4f4f4f;
  --color-grey-11: #323232;
  --color-grey-12: #5e5e5e;
  --color-grey-13: #262626;
  --color-grey-14: #2f2f2f;
  --color-grey-15: #525252;
  --color-grey-16: #464646;
  --color-grey-17: #ddd;
  --color-grey-18: #aaa;
  --color-grey-19: #999;
  --color-grey-20: #ccc;
  --color-grey-21: #e6e6e6;
  --color-grey-22: #f5faff;
  --color-grey-23: #e5e5e5;
  --color-grey-24: #ffe7f2;
  --color-grey-25: #fafafa;
  --color-grey-26: #646464;
  --color-grey-27: #fff8e0;
  --color-grey-28: #979797;
  --color-grey-29: #cccccc;
  --color-grey-30: #d4d4d4;
  --color-grey-31: #c3bfbf;
  --color-grey-32: #d0d0d0;

  --color-green: #74c882;

  // Modals - start

  --premium-sub-warning-background: #f6f4f3;
  --premium-sub-warning-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --premium-sub-warning-exit-icon-fill: #646464;
  --premium-sub-warning-text-color: var(--color-global-text-opacity-8);

  --unsubscribev3-modal-background: #f6f4f3;
  --unsubscribev3-modal-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --unsubscribev3-modal-close-svg-hover: #ced5da;
  --unsubscribev3-modal-title: var(--color-black-opacity8);
  --unsubscribev3-modal-subtitle: var(--color-black-opacity8);
  --unsubscribev3-modal-paragraph: var(--color-black-opacity8);
  --unsubscribev3-modal-title-warning: var(--global-warning-color);
  --unsubscribev3-modal-fullprice-warning: var(--global-warning-color);

  // Modals - end

  // Product specific colors:
  --diet-grey-color-1: #f5f5f5;
  --diet-grey-color-2: #fffee9;

  //SubscriptionText
  --subscription-warning-text: var(--global-warning-color);
  --subscription-link: var(--primary-accent-color);

  --unsubscribed-modal-bg-color: var(--primary-background-color);
  --discount-modal-bg-color: var(--primary-background-color);

  --chatline-link: var(--secondary-accent-color-dk);
  // Quiz
  --quiz-title-color: var(--color-global-text-opacity-6);
  --quiz-question-color: var(--color-global-text-opacity-8);
  --quiz-selected-answer-background: rgba(255, 158, 0, 0.07);

  --objection-text: var(--color-global-grey-2);
  --objection-title: var(--color-global-text-opacity-8);
  --objection-background: var(--premium-upsell-include-image-background);
  --objection-specialist-text: var(--color-global-text-opacity-6);
  --objection-link-button: var(--primary-accent-color);
  --objection-bubble-background: var(--premium-upsell-main-title-background);

  --quiz-modal-background: var(--color-orange-2);
  --quiz-modal-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --quiz-modal-button-color: var(--color-global-text-opacity-8);

  // ChatPage
  --chatline-link: var(--secondary-accent-color-dk);

  // PostPayment
  --payment-failed-text: var(--color-global-text-opacity-8);
  --payment-failed-background: #f6f4f3;
  --payment-failed-mui-button-text: var(--color-global-text-opacity-8);
  --payment-failed-mui-button-background: #fafafa;
  --payment-failed-mui-button-background-hover: rgba(0, 0, 0, 0.04);

  // PremiumSubscription
  --premium-block-border: #d6d6d6;
  --premium-block-title: var(--color-global-text-opacity-8);
  --premium-block-text: var(--color-global-text-opacity-6);

  // ShoppingList Offer
  --shopping-list-offer-title: var(--color-global-text-opacity-8);
  --shopping-list-offer-title-accent: #369ef3;
  --shopping-list-offer-price: var(--color-global-text-opacity-6);
  --shopping-list-offer-list-item: var(--color-global-text-opacity-8);

  // FatBurnerPage
  --fat-burner-chat-link: var(--primary-accent-color);
  --fat-burner-success-page-link: var(--primary-accent-color);

  // AnnualProOffer
  --annual-pro-offer-background: #f6f4f3;
  --annual-pro-offer-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --annual-pro-offer-warning-background: #d84f4f;
  --annual-pro-offer-warning-text: rgba(255, 255, 255, 0.87);
  --annual-pro-offer-title: var(--color-global-text-opacity-8);
  --annual-pro-offer-price: var(--color-global-text-opacity-8);
  --annual-pro-offer-price-small: var(--color-global-text-opacity-6);
  --annual-pro-offer-background-layer: var(--color-orange-2);

  // AnnualCongratsView
  --annual-pro-congrats-background: #f6f4f3;
  --annual-pro-congrats-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --annual-pro-congrats-title: var(--color-global-text-opacity-8);
  --annual-pro-congrats-text: var(--color-global-text-opacity-8);

  --meal-change-dot: #cccccc;
  --meal-change-dot-selected: #ff9e00;

  // ShoppingListFree
  --shopping-list-free-text: var(--color-global-text-opacity-8);
  --shopping-list-free-offer-text: var(--color-global-text-opacity-6);
  --shopping-list-free-offer-price: var(--color-global-text-opacity-6);

  // TOSLinkBlock
  --tos-link-block-divider: #d6d6d6;
  --tos-link-block-text: #ffc90d;

  // CardSaveConfirmation
  --card-save-confirmation-background: #f6f4f3;
  --card-save-confirmation-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --card-save-confirmation-close-icon: #646464;
  --card-save-confirmation-title: var(--color-global-text-opacity-8);
  --card-save-confirmation-text: var(--color-global-text-opacity-8);

  --tos-button: var(--primary-accent-color);

  // UnsubscribeReason
  --unsubscribe-reason-title: #2884aa;
  --unsubscribe-reason-subtitle: #666;
  --unsubscribe-reason-infoblock-bg: #f7f7f7;
  --unsubscribe-reason-infoblock-border: #bbb;
  --unsubscribe-reason-infotext: #000;
  --unsubscribe-reason-secondary-title: #000;
  --unsubscribe-reason-listitem-text: #000;
  --unsubscribe-reason-listitem-bg: #f5eacd;
  --unsubscribe-reason-radiobutton-default: #bbb;
  --unsubscribe-reason-button-text: #464646;
  --unsubscribe-reason-button-bg: #ffca36;
  --unsubscribe-reason-button-boxshadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --unsubscribe-reason-button-bg-hover: #e2b12a;
  --unsubscribe-reason-button-boxshadow-hover: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --unsubscribe-reason-button-bg-active: #a47c0d;
  --unsubscribe-reason-button-boxshadow-active: 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --unsubscribe-reason-button-disabled-bg: #ffe59e;
  --unsubscribe-reason-button-disabled-text: rgba(70, 70, 70, 0.6);
  --unsubscribe-reason-spinner: rgba(70, 70, 70, 0.6);
  --unsubscribe-reason-length-text: #666;
  --unsubscribe-reason-max-capacity: #a12027;
}

body.keto {
  // Primary/Secondary colors
  --primary-background-color: var(--color-global-white);
  --primary-title-color: rgba(0, 0, 0, 0.87);
  --primary-text-color: rgba(0, 0, 0, 0.6);
  --primary-accent-color: #2a9d8f;

  // Slider
  --slider-span-lable-color: #fff;

  // Colors
  --color-keto-accent: #2a9d8f;
  --color-keto-accent-2: #264653;
  --color-keto-accent-opacity: rgba(98, 2, 238, 0);
  --color-keto-accent-opacity-2: rgba(187, 187, 187, 0.12);
  --color-keto-accent-opacity-3: rgba(42, 157, 143, 0.2);
  --color-keto-error: #a12027;
  --color-keto-divider: #d6d6d6;

  --color-keto-grey: #bbbbbb;
  --color-keto-grey-2: #909090;
  --color-keto-grey-3: #646464;
  --color-keto-grey-4: #979797;
  --color-keto-grey-5: #848789;
  --color-keto-gray-6: #dcefec;
  --color-keto-gray-7: #f0f4f4;

  --color-keto-white: #f6f4f3;
  --color-keto-white-2: #f5f5f5;
  --color-keto-white-3: #f8f8f8;

  // MODALS: Start
  // Modal Modal.module.
  --modal-background: var(--color-global-white);

  // Modal - DiaryUnskippable
  --diary-text: var(--color-global-text-opacity-8);
  --diary-background: var(--color-global-white);
  --diary-event: var(--color-keto-accent);
  --diary-calendar-background: #d4ebe9;
  --diary-calendar-boxshadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
  --diary-calendar-border: var(--color-keto-accent);

  // Moadal - PlanUpdate
  --plan-update-title-text: var(--color-global-text-opacity-8);
  --plan-update-text: var(--color-global-text-opacity-8);
  --plan-update-background: var(--color-global-white);
  --plan-update-boxshadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --plan-update-close-icon-active: var(--color-keto-grey-3);

  // Modal - CookieSettings
  --cookie-settings-background: var(--color-global-white);
  --cookie-settings-boxshadow: 0 -11px 15px -7px rgba(0, 0, 0, 0.2),
    0 -9px 46px 8px rgba(0, 0, 0, 0.12), 0 -24px 38px 3px rgba(0, 0, 0, 0.14);
  --cookie-settings-boxshadow-mobile: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --cookie-settings-link-text: var(--color-keto-accent);

  // Modal - Testimonial
  --testimonial-title-text: var(--color-global-text-opacity-8);
  --testimonial-text: var(--color-global-text-opacity-8);
  --testimonial-background: var(--color-global-white);
  --testimonial-boxshadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --testimonial-close-icon-active: var(--color-keto-grey-3);
  // Modal - Pregnant
  --pregnant-subtext: var(--color-global-text-opacity-8);

  --unsubscribev3-modal-background: #f6f4f3;
  --unsubscribev3-modal-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --unsubscribev3-modal-close-svg-hover: #ced5da;
  --unsubscribev3-modal-title: var(--color-black-opacity8);
  --unsubscribev3-modal-subtitle: var(--color-black-opacity8);
  --unsubscribev3-modal-paragraph: var(--color-black-opacity8);
  --unsubscribev3-modal-title-warning: var(--global-warning-color);
  --unsubscribev3-modal-fullprice-warning: var(--color-keto-error);

  // MODALS: End

  // Navbar
  --navbar-background: linear-gradient(
    82deg,
    #264653 27%,
    #2a9d8f 59%,
    #e9c46a
  );
  --navbar-svg: rgba(255, 255, 255, 0.54);

  // Calendar
  --primary-calendar-header: var(--color-keto-accent);
  --primary-calendar-button: var(--color-keto-accent);
  --primary-calendar-event-background: var(--color-keto-accent);
  --primary-calendar-event-text: #ffcc70;
  --primary-calendar-selected-background: var(--color-keto-accent-opacity-3);
  --primary-calendar-selected-boxshadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
  --primary-calendar-resting-background: #fafafa;
  --primary-calendar-resting-background-hover: rgba(42, 157, 143, 0.1);
  --primary-calendar-resting-border: rgba(0, 0, 0, 0.12);
  --primary-calendar-resting-border-hover: rgba(0, 0, 0, 0.24);
  --primary-calendar-past-bg-image: url('~assets/images/program/calendar/keto/past/resting.svg');
  --primary-calendar-past-bg-image-hover: url('~assets/images/program/calendar/keto/past/hover.svg');
  --primary-calendar-past-bg-image-selected: url('~assets/images/program/calendar/keto/past/selected.svg');
  --primary-calendar-mobile-background-left: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 1%,
    rgba(255, 255, 255, 0.99) 100%
  );
  --primary-calendar-mobile-background-right: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 1%,
    rgba(255, 255, 255, 0.99) 100%
  );
  --secondary-calendar-header: var(--color-keto-accent-2);

  // TOS
  --tos-button: var(--color-keto-grey);

  // Loading
  --loading-text: var(--color-keto-accent);

  // Flash message
  --flash-message-text: var(--color-global-white);
  --flash-message-error: var(--color-keto-error);
  --flash-message-success: var(--color-keto-accent);

  // Button
  --button-text: var(--color-keto-grey);
  --button-text-hover: var(--color-keto-grey-2);
  --button-border: var(--color-keto-grey);
  --button-border-hover: var(--color-keto-grey-2);
  --button-background: var(--color-keto-accent-opacity);
  --button-background-hover: var(--color-keto-accent-opacity-2);
  --secondary-button-text: var(--color-keto-accent);
  --secondary-button-border: rgba(42, 157, 143, 0.5);
  --secondary-button-background-hover: rgba(42, 157, 143, 0.04);

  --tertiary-button-contained-text: var(--color-global-white);
  --tertiary-button-contained-text-hover: var(--color-global-white);
  --tertiary-button-contained-background: linear-gradient(
    262deg,
    #54ccc2,
    #2a9d8f
  );
  --tertiary-button-contained-background-hover: linear-gradient(
    262deg,
    #2a9d8f,
    #1d746a
  );
  --tertiary-button-contained-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --tertiary-button-contained-shadow-hover: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);

  // Language dropdown
  --language-dropdown-border: rgba(0, 0, 0, 0.23);
  --language-dropdown-background: var(--color-keto-white);
  --language-dropdown-background-hover: #433e3f;

  // TextInput
  --text-input-backgrond: transparent;
  --text-input-text: var(--color-global-black);
  --text-input-border: rgba(25, 25, 25, 0.32);
  --text-input-border-hover: #433e3f;
  --text-input-border-active: #2a9d8f;

  // LinkButton
  --link-button-text: var(--color-keto-grey);
  --link-button-text-hover: var(--color-keto-grey-2);
  --link-button-border: var(--color-keto-grey);
  --link-button-border-hover: var(--color-keto-grey-2);
  --link-button-background: var(--color-keto-accent-opacity);
  --link-button-background-hover: var(--color-keto-accent-opacity-2);
  // LinkButton-shopping
  --link-button-shopping-text: var(--color-keto-accent);

  // SuccessIcon
  --success-icon-background: linear-gradient(
    45deg,
    #264653 0%,
    #2a9d8f 44%,
    #e9c46a
  );

  // SubmitButton
  --primary-submit-button-background: linear-gradient(262deg, #54ccc2, #2a9d8f);
  --primary-submit-button-text: var(--color-global-white);
  --primary-submit-button-background-loading: #ced5da;
  --primary-submit-button-background-hover: linear-gradient(
    262deg,
    #2a9d8f,
    #1d746a
  );
  --primary-submit-button-background-active: linear-gradient(
    262deg,
    #257f77 99%,
    #0c433c 0%
  );
  --primary-submit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --primary-submit-box-shadow-hover: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --primary-submit-box-shadow-active: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --secondary-submit-button-text: var(--color-keto-grey);
  --secondary-submit-button-text-hover: var(--color-keto-grey-2);
  --secondary-submit-button-border: var(--color-keto-grey);
  --secondary-submit-button-border-hover: var(--color-keto-grey-2);
  --secondary-submit-button-background: var(--color-keto-accent-opacity);
  --secondary-submit-button-background-hover: var(
    --color-keto-accent-opacity-2
  );
  --tertiary-submit-button-text: var(--color-keto-accent);

  // Meal
  --meal-title: var(--color-global-text-opacity-8);
  --meal-info-text: var(--color-global-text-opacity-8);
  --meal-benefit-text: var(--color-global-grey-2);
  --meal-image-container-background: rgba(42, 157, 143, 0.04);
  --meal-lock-icon: var(--color-global-black);
  --meal-lock-icon-background: var(--color-keto-accent);

  // MealChange
  --meal-change-title: var(--color-global-text-opacity-8);
  --meal-change-background: var(--color-keto-white);
  --meal-change-dot: #cccccc;
  --meal-change-dot-selected: var(--color-keto-accent);
  --meal-change-fade-left: linear-gradient(
    to right,
    #ffffff 0%,
    rgba(255, 255, 255, 0.001) 100%
  );
  --meal-change-fade-right: linear-gradient(
    to left,
    #ffffff 0%,
    rgba(255, 255, 255, 0.001) 100%
  );

  // ChangePrompt
  --change-prompt-text: var(--color-global-text-opacity-8);
  --change-prompt-background: var(--color-keto-white);
  --change-prompt-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);

  // ChatPage
  --chat-page-header-backgroud: var(--color-keto-accent);
  --chat-page-footer-backgroud: var(--color-keto-gray-6);
  --chat-page-header-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --chat-page-body-top-fade: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.8) 25%,
    rgba(255, 255, 255, 0.01) 100%
  );
  --chat-page-footer-border: #a6a6a6;
  --chat-page-input-text: var(--color-global-text-opacity-6);
  --chat-page-input-border: rgba(0, 0, 0, 0.32);
  --chat-page-issue-background: var(--color-keto-error);
  --chat-page-issue-border: var(--color-keto-error);
  --chat-page-send-icon: var(--color-keto-accent);

  // ChatLine
  --chat-line-author-text: rgba(0, 0, 0, 0.38);
  --chat-line-user-background: var(
    --color-keto-gray-6
  ); //rgba(42, 157, 143, 0.1);
  --chat-line-admin-background: var(--color-keto-white-3);
  --chat-line-timestamp: var(--color-keto-grey-5);

  // Avatar
  --chat-avatar-user-text: var(--color-keto-accent-2);
  --chat-avatar-user-background: rgba(42, 157, 143, 0.1);
  --chat-avatar-admin-background: var(--color-keto-white-2);

  // SystemMessage
  --system-message-text: var(--color-global-text-opacity-6);

  // Testamonial
  --testamonial-header-text: var(--color-global-text-opacity-8);
  --testamonial-text: var(--color-global-text-opacity-8);
  --testamonial-link-text: var(--color-keto-accent);

  // ShoppingList
  --shopping-list-header-icon: var(--color-keto-accent-2);
  --shopping-list-product-icon: var(--color-keto-accent-2);
  --shopping-list-subtitle-text: var(--color-global-text-opacity-3);
  --shopping-list-item-text: var(--color-global-black);
  --shopping-list-unit-text: var(--color-global-black);

  // SelectButton
  --select-buttons-selected-background: var(--color-keto-accent-opacity-3);

  // ProfilePage
  --profile-page-svg-fill: var(--color-keto-accent-2);

  // GoalBlock
  --goal-block-title-text: var(--color-global-text-opacity-8);

  // AccountBlock
  --account-block-title-text: var(--color-global-text-opacity-8);
  --account-block-link-text: var(--color-keto-accent);

  // EditAccountPage
  --edit-account-page-border: var(--account-block-border);

  // UnsubscribeBlock
  --unsubscribe-block-link: var(--color-keto-accent);
  --unsubscribe-block-warning-text: var(--color-keto-error);

  // CheckBox
  --check-box-selected-background: var(--color-keto-accent-opacity-3);

  // MealSurvey
  --meal-survey-loading-text: var(--color-keto-accent);
  --meal-survey-progress-bar-title-text: var(--color-keto-accent-2);

  // MedicalAgreement
  --medical-agreement-disagree-title-text: var(--color-keto-error);

  // OfferBanner
  --offer-banner-text: var(--color-keto-error);
  --offer-banner-background: #ffcc70;
  --offer-banner-boxshadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14);

  // SubTosPage
  --sub-tos-text: var(--color-global-grey-2);
  --sub-tos-subtitle-text: var(--color-global-text-opacity-8);
  --sub-tos-background: #f6f4f3;

  // Templates: Start
  // Pre_unsubscribe_v3

  // Sub_expired
  --sub-expired-thumb-up: var(--color-keto-accent);
  --sub-expired-thumb-down: var(--color-keto-grey-4);
  --sub-expired-button-background: #fafafa;
  // Templates: End

  //SubscriptionText
  --subscription-warning-text: var(--global-warning-color);
  --subscription-link: var(--primary-accent-color);

  --unsubscribed-modal-bg-color: var(--primary-background-color);
  --discount-modal-bg-color: var(--primary-background-color);

  --chatline-link: #2a9d8f;

  // PostPayment
  --payment-failed-text: var(--color-global-text-opacity-8);
  --payment-failed-background: #f6f4f3;
  --payment-failed-mui-button-text: var(--color-global-text-opacity-8);
  --payment-failed-mui-button-background: #fafafa;
  --payment-failed-mui-button-background-hover: rgba(0, 0, 0, 0.04);

  // Quiz
  --quiz-title-color: var(--color-global-text-opacity-6);
  --quiz-question-color: var(--color-global-text-opacity-8);
  --quiz-selected-answer-background: rgba(255, 158, 0, 0.07);

  --objection-text: var(--color-global-grey-2);
  --objection-title: var(--color-global-text-opacity-8);
  --objection-background: var(--premium-upsell-include-image-background);
  --objection-specialist-text: var(--color-global-text-opacity-6);
  --objection-link-button: var(--primary-accent-color);
  --objection-bubble-background: var(--premium-upsell-main-title-background);

  --quiz-modal-background: var(--color-orange-2);
  --quiz-modal-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --quiz-modal-button-color: var(--color-global-text-opacity-8);

  // PremiumSubscription
  --premium-block-border: var(--color-keto-divider);
  --premium-block-title: var(--color-global-text-opacity-8);
  --premium-block-text: var(--color-global-text-opacity-6);

  // ShoppingList Offer
  --shopping-list-offer-title: var(--color-global-text-opacity-8);
  --shopping-list-offer-title-accent: var(--primary-accent-color);
  --shopping-list-offer-price: var(--color-global-text-opacity-6);
  --shopping-list-offer-list-item: var(--color-global-text-opacity-8);

  --premium-upsell-timer-text: var(--primary-accent-color);

  // FatBuner Offer
  --fat-burner-offer-delivery: var(--primary-accent-color);
  --fat-burner-chat-link: var(--primary-accent-color);
  --fat-burner-success-page-link: var(--primary-accent-color);

  // AnnualProOffer
  --annual-pro-offer-background: #f6f4f3;
  --annual-pro-offer-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --annual-pro-offer-warning-background: #d84f4f;
  --annual-pro-offer-warning-text: rgba(255, 255, 255, 0.87);
  --annual-pro-offer-title: var(--color-global-text-opacity-8);
  --annual-pro-offer-price: var(--color-global-text-opacity-8);
  --annual-pro-offer-price-small: var(--color-global-text-opacity-6);

  // AnnualCongratsView
  --annual-pro-congrats-background: #f6f4f3;
  --annual-pro-congrats-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --annual-pro-congrats-title: var(--color-global-text-opacity-8);
  --annual-pro-congrats-text: var(--color-global-text-opacity-8);

  // ShoppingListFree
  --shopping-list-free-text: var(--color-global-text-opacity-8);
  --shopping-list-free-offer-text: var(--color-global-text-opacity-6);
  --shopping-list-free-offer-price: var(--color-global-text-opacity-6);

  // TOSLinkBlock
  --tos-link-block-divider: var(--color-keto-divider);
  --tos-link-block-text: var(--color-keto-accent);

  // CardSaveConfirmation
  --card-save-confirmation-background: #f6f4f3;
  --card-save-confirmation-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --card-save-confirmation-close-icon: #646464;
  --card-save-confirmation-title: var(--color-global-text-opacity-8);
  --card-save-confirmation-text: var(--color-global-text-opacity-8);

  // HelpdeskFooter
  --helpdesk-footer-text: #bbbbbb;
  --helpdesk-footer-link: var(--primary-accent-color);

  // ShoppingListV2
  --offer-shoppinglist-v2-bg: #ffcc70;
  --offer-shoppinglist-v2-banner-bg: #264653;
  --offer-shoppinglist-v2-text-secondary: #2a9d8f;
  --offer-shoppinglist-v2-text-primary: #ffcc70;
  --offer-shoppinglist-v2-text-base: rgba(0, 0, 0, 0.87);
  --offer-shoppinglist-v2-banner-text: #ffcc70;
  --offer-shoppinglist-v2-text-price: rgba(0, 0, 0, 0.6);
  --offer-shoppinglist-v2-percentage: #2a9d8f;
  --offer-shoppinglist-v2-badge-text: #264653;
  --offer-shoppinglist-v2-photo-text: #264653;
  --offer-shoppinglist-v2-text-highlighted: #f65471;

  // UnsubscribeV8
  // Recap
  --recap-bg: #122f2c;
  --recap-card-container-bg: linear-gradient(
    180deg,
    rgba(29, 77, 71, 0) 26.04%,
    #122f2b 39.06%
  );
  --recap-card-container-color: #fff;
  --recap-card-container-bs: rgba(42, 157, 143, 0.5);
  --recap-wrapper-bg: linear-gradient(
    180deg,
    rgba(29, 77, 71, 0) 190px,
    #122f2b 357px
  );
  --recap-footer-container-bg: linear-gradient(
    180deg,
    rgba(18, 47, 44, 0) 0%,
    #122f2c 28.65%
  );
  // Recap - Title
  --recap-title-color: #fff;
  // Recap - BackButton
  --recap-backbtn-text-color: #666666;
  --recap-backbtn-bg-color: #fff;
  --recap-backbtn-bs: 0px -4px 24px 0px rgba(102, 102, 102, 0.7);
  // Recap - NextButton
  --recap-nextbtn-text-color: #fff;
  --recap-nextbtn-bg-color: #000;
  --recap-nextbtn-hover-text-color: #b0b0b0;
  // Recap - Basic
  --recap-basic-bg-color: #fff;
  --recap-basic-bs: 0px 4px 24px 0px rgba(255, 255, 255, 0.16);
  --recap-basic-title-color: #228176;
  --recap-basic-subtitle-color: #228176;
  --recap-basic-text-color: #000;
  --recap-basic-accent-color: #228176;
  --recap-basic-secondary-accent-color: #a12027;
  // Recap - BasicLinked
  --recap-basiclinked-bg-color: #fff;
  --recap-basiclinked-bs: 0px 4px 24px 0px rgba(255, 255, 255, 0.16);
  --recap-basiclinked-title-color: #228176;
  --recap-basiclinked-subtitle-color: #228176;
  --recap-basiclinked-text-color: #000;
  --recap-basiclinked-accent-color: #228176;
  --recap-basiclinked-forwardbtn-color: #228176;
  --recap-basiclinked-forwardbtn-bg-color: #fff;
  --recap-basiclinked-forwardbtn-border-color: #228176;
  --recap-basiclinked-forwardbtn-hover-border-color: hsl(173, 58%, 20%);
  --recap-basiclinked-forwardbtn-hover-color: hsl(173, 58%, 20%);
  // Recap - Journey
  --recap-journey-bg-color: #fff;
  --recap-journey-bs: 0px 4px 24px 0px rgba(255, 255, 255, 0.16);
  --recap-journey-title-color: #000;
  --recap-journey-hero-bg-color: #b2dbd8;
  --recap-journey-name-text-color: #000;
  --recap-journey-age-text-color: #666666;
  --recap-journey-user-weight-text-color: #666666;
  --recap-journey-weight-text-color: #666666;
  --recap-journey-accent-color: #228176;
  // Recap - Results
  --recap-results-title-color: #fff;
  --recap-results-text-color: #fff;
  --recap-results-graph-bg-color: #fff;
  --recap-results-graph-title-color: #000;
  --recap-results-accent-color: #228176;
  --recap-results-footer-bg: linear-gradient(
    180deg,
    rgba(18, 47, 44, 0) 0%,
    #122f2c 28.65%
  );
  // Recap - PercentageSpinner
  --recap-percentagespinner-bg-color: conic-gradient(
    #2a9d8f 3.6deg,
    #d9d9d9 0deg
  );
  --recap-percentagespinner-before-bg-color: #122f2c;
  --recap-percentagespinner-text-color: #fff;
  // Recap - Graph
  --recap-graph-weights-color: #4da58b;
  --recap-graph-weightleft-bg-color: #f5fdfb;
  --recap-graph-weightleft-color: #999;
  --recap-graph-months-bg-color: #f5fdfb;
  --recap-graph-months-color: #999;

  // UnsubscribeV8 - FREE OFFER
  --free-offer-accent-color: #228176;
  --free-offer-title-color: #000;
  --free-offer-subtitle-base-color: #000;
  --free-offer-subtitle-custom-color: #666;
  --free-offer-infoblock-border: #b2dbd8;
  --free-offer-infoblock-bg: #effcfa;
  --free-offer-infoblock-text: #000;
  --free-offer-infoblock-weight: #228176;
  --free-offer-infoblock-subtitle: #666;
  --free-offer-item-heroitem-title: #000;
  --free-offer-item-heroitem-title-accented: #228176;
  --free-offer-item-heroitem-text: #666;
  --free-offer-item-list-text: #000;
  --free-offer-item-list-circle-bg: #2a9d8f;
  --free-offer-modal-bg: #fafafa;
  --free-offer-modal-box-shadow: drop-shadow(0px 11px 15px rgba(0, 0, 0, 0.2))
    drop-shadow(0px 9px 46px rgba(0, 0, 0, 0.12))
    drop-shadow(0px 24px 38px rgba(0, 0, 0, 0.14));
  --free-offer-modal-title: var(--color-black-opacity8);
  --free-offer-modal-text: var(--color-black-opacity8);
  // UnsubscribeReason
  --unsubscribe-reason-title: #228176;
  --unsubscribe-reason-subtitle: #666;
  --unsubscribe-reason-infoblock-bg: #f7f7f7;
  --unsubscribe-reason-infoblock-border: #bbb;
  --unsubscribe-reason-infotext: #000;
  --unsubscribe-reason-secondary-title: #000;
  --unsubscribe-reason-listitem-text: #000;
  --unsubscribe-reason-listitem-bg: #ecfaf6;

  // PhysicalProductUnsubscribed
  --physical-unsub-bg-color: #f6f4f3;
  --physical-unsub-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --physical-unsub-title-color: rgba(0, 0, 0, 0.87);
  --physical-unsub-text-color: rgba(0, 0, 0, 0.87);
  --physical-unsub-link-text-color: rgba(0, 0, 0, 0.87);
  --physical-unsub-link-color: #2a9d8f;
  --unsubscribe-reason-radiobutton-default: #bbb;
  --unsubscribe-reason-button-text: #fff;
  --unsubscribe-reason-button-bg: linear-gradient(262deg, #54ccc2, #2a9d8f);
  --unsubscribe-reason-button-boxshadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --unsubscribe-reason-button-bg-hover: linear-gradient(
    262deg,
    #2a9d8f,
    #1d746a
  );
  --unsubscribe-reason-button-boxshadow-hover: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --unsubscribe-reason-button-bg-active: linear-gradient(
    262deg,
    #257f77 99%,
    #0c433c 0%
  );
  --unsubscribe-reason-button-boxshadow-active: 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --unsubscribe-reason-button-disabled-bg: #b2dbd8;
  --unsubscribe-reason-button-disabled-text: rgba(255, 255, 255, 0.8);
  --unsubscribe-reason-spinner: rgba(255, 255, 255, 0.8);
  --unsubscribe-reason-length-text: #666;
  --unsubscribe-reason-max-capacity: #a12027;

  // GenomPlus - success screen
  --genom-pluss-success-title: #000;
  --genom-pluss-success-text: #666;

  // GenomPlus - offer
  --genom-plus-offer-bg: #228176;
  --genom-plus-main-title: #fff;
  --genom-plus-main-subtitle: #fff;
  --genom-plus-plan-select-bs: 0px 4px 24px 0px rgba(42, 157, 143, 0.12);
  --genom-plus-plan-select-bg: #fff;
  --genom-plus-plan-select-title: #000;
  --genom-plus-plan-select-text: #666;
  --genom-plus-plan-select-border: #b2dbd8;
  --genom-plus-accent-color-main: #2a9d8f;
  --genom-plus-accent-color-secondary: #228176;
  --genom-plus-plan-information-text: #666;
  --genom-plus-secondary-title: #000;
  --genom-plus-secondary-subtitle: #666;
  --genom-plus-symptom-item-bg: #ffe1e1;
  --genom-plus-symptom-item-text: #000;
  --genom-plus-price-text: #666;
  --genom-plus-aging-bg: #ecf6f6;
  --genom-plus-nutrionist-name: #000;
  --genom-plus-nutrionist-text: #000;
  --genom-plus-nutrionist-bubble-text: #fff;
  --genom-plus-tirtary-subtitle: #000;
  --genom-plus-forth-title: #000;
  --genom-plus-forth-subtitle: #666;
  --genom-plus-checkmark-text: #000;

  // GenomPlus - survey
  --genom-plus-survey-btn-disabled-bg: #b2dbd8;
  --genom-plus-survey-btn-disabled-color: #fff;
  --genom-plus-survey-back-color: #2a9d8f;
  --genom-plus-survey-progress-label-color: #666;
  --genom-plus-survey-progress-bar-bg: #eef7f6;
  --genom-plus-survey-title-color: #000;
  --genom-plus-survey-subtitle-color: #666;
  --genom-plus-survey-text-color: #000;
  --genom-plus-survey-email-color: #228176;

  // GenomPlus - settings
  --genom-plus-settings-title-color: var(--color-global-text-opacity-8);
  --genom-plus-settings-info-color: #666;
  --genom-plus-settings-block-border-color: #d6d6d6;
  --genom-plus-settings-block-title-color: var(--color-global-text-opacity-6);
  --genom-plus-settings-block-info-color: #000;

  // PlanDipslayItem (genomplus)
  --genom-plus-display-item-img-bg: #eef7f6;
  --genom-plus-display-item-text: #666;
  --genom-plus-display-item-text-secondary: #228176;
  --genom-plus-display-item-discount-bg: #d4ebe9;
  --genom-plus-display-item-discount-text: #000;
  --genom-plus-display-item-prev-price: #999;

  // PlanSelect (genomplus)
  --genom-plus-plan-selection-bg: #fff;
  --genom-plus-plan-selection-border: 1px solid #d4ebe9;
  --genom-plus-plan-selection-disabled: #eef7f6;
  --genom-plus-plan-selection-selected: #eef7f6;
  --genom-plus-plan-selection-selected-hover: #d4ebe9;
  --genom-plus-plan-selection-selected-hovered-img: #eef7f6;
  --genom-plus-plan-selection-tick-border: 1px solid #b2dbd8;
  --genom-plus-plan-selection-tick-bg: #eef7f6;
  --genom-plus-plan-selection-tick-selected: #2a9d8f;
  --genom-plus-plan-selection-tick-hover: #d4ebe9;
  --genom-plus-plan-selection-base-selected: #2a9d8f;
  --genom-plus-plan-selection-title: #000;
  --genom-plus-plan-selection-accent: #2a9d8f;
  --genom-plus-plan-selection-subtitle: #228176;

  // GenomPlus PopUp screen
  --genom-plus-popup-bg-shadow: rgba(0, 0, 0, 0.6);
  --genom-plus-popup-bg: #f6f4f3;
  --genom-plus-popup-bs: 0px 12px 16px 0px rgba(104, 104, 104, 0.16);
  --genom-plus-popup-title: #000;
  --genom-plus-popup-text: #000;
  --genom-plus-popup-upgrade-bg: #fff;

  // GenomPlus v2 offer
  --genom-plus-v2-title: #000;
  --genom-plus-v2-badge-bg: #dc5e5e;
  --genom-plus-v2-badge-text: #fff;
  --genom-plus-v2-offer-item-text: #666;
  --genom-plus-v2-display-title: #000;
  --genom-plus-v2-display-subtitle: #666;
  --genom-plus-v2-offer-title: #000;
  --genom-plus-v2-offer-subtitle: rgba(70, 70, 70, 0.5);

  // DefaultSuccess screen
  --default-success-bg-shadow: rgba(0, 0, 0, 0.6);
  --default-success-bg: #f6f4f3;
  --default-success-bs: 0px 12px 16px 0px rgba(104, 104, 104, 0.16);
  --default-success-title: #000;
  --default-success-text: #464646;

  // ShoppingListBlock
  --shopping-list-block-border: #d6d6d6;
  --shopping-list-title: rgba(0, 0, 0, 0.87);
  --shopping-list-text: rgba(0, 0, 0, 0.6);

  // PreUnsubscribeShoppinglist
  --preunsubscribe-shoppinglist-background: #fff;
  --preunsubscribe-shoppinglist-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --preunsubscribe-shoppinglist-exit-icon-fill: #646464;
  --preunsubscribe-shoppinglist-text-color: #2e3033;

  // ChatBlock
  --chat-block-border: #d6d6d6;
  --chat-block-title: rgba(0, 0, 0, 0.87);
  --chat-block-text: rgba(0, 0, 0, 0.6);

  // Preunsubscribe Chat
  --preunsubscribe-chat-background: #fff;
  --preunsubscribe-chat-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --preunsubscribe-chat-exit-icon-fill: #646464;
  --preunsubscribe-chat-text-color: #2e3033;

  // CancellationFeeStart
  --start-cancellation-fee-modal-bg: #fff;
  --start-cancellation-fee-modal-bs: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --start-cancellation-fee-modal-title: #000;
  --start-cancellation-fee-modal-text: #666;
  --start-cancellation-fee-modal-link: #2a9d8f;
  --start-cancellation-fee-modal-warning: #ffb228;
  --start-cancellation-fee-modal-subscription-border: #b2dbd8;
  --start-cancellation-fee-modal-subscription-bg: #effcfa;
  --start-cancellation-fee-modal-price: #228176;

  // CancellationFee
  --cancellation-fee-modal-title: #000;
  --cancellation-fee-modal-text: #666;
  --cancellation-fee-modal-link: #2a9d8f;
  --cancellation-fee-modal-bg: #fff;
  --cancellation-fee-modal-bs: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);

  // CancellationFeeModal - SUCCESS
  --cancellation-fee-modal-success-bg-shadow: rgba(0, 0, 0, 0.6);
  --cancellation-fee-modal-success-bg: #f6f4f3;
  --cancellation-fee-modal-success-bs: 0px 12px 16px 0px
    rgba(104, 104, 104, 0.16);
  --cancellation-fee-modal-success-title: #000;
  --cancellation-fee-modal-success-text: #464646;

  // Start: Workouts

  // ProgramWorkout
  --program-workout-active-svg-bg: #2a9d8f;
  --program-workout-active-small-title: #000;
  --program-workout-active-big-title: #000;
  --program-workout-active-benefit-text: #333333;
  --program-workout-inactive-bg: #e4e9e9;
  --program-workout-inactive-bg-video: rgba(42, 157, 143, 0.04);
  --program-workout-inactive-link-text: #000;
  --program-workout-inactive-accent-text: #228176;
  --program-workout-active-thumbnail-bg: rgba(42, 157, 143, 0.04);
  --program-workout-active-rest: #666;

  // ProgramOfferModal
  --program-offer-modal-bg: #f6f4f3;
  --program-offer-modal-bs: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --program-offer-modal-title: rgba(0, 0, 0, 0.87);
  --program-offer-modal-text: rgba(0, 0, 0, 0.87);

  // WorkoutsStartPage
  --workouts-start-bg: #fff;
  --workouts-start-items-bg: rgba(42, 157, 143, 0.04);
  --workouts-start-benefits-bg: #2a9d8f;
  --workouts-start-big-title: #000;
  --workouts-start-small-title: #000;
  --workouts-start-information: #000;
  --workouts-start-exercise-title: #000;
  --workouts-start-periodicity-title: #000;
  --workouts-start-periodicity-text: #000;

  // WorkoutsPreview
  --workouts-preview-bg: #fff;
  --workouts-preview-bg-fog: rgba(38, 70, 83, 0.8);
  --workouts-preview-bs: 0px -12px 24px 0px rgba(43, 43, 43, 0.32);
  --workouts-preview-title: #000;
  --workouts-preview-video-bg: rgba(42, 157, 143, 0.04);
  --workouts-preview-title-small: #000;
  --workouts-preview-description: #000;
  --workouts-preview-mobile-notch-bg: #efefef;

  // WorkoutsInProgress
  --workouts-in-progress-bg: #fff;
  --workouts-in-progress-content-title: #000;
  --workouts-in-progress-info-bg: rgba(42, 157, 143, 0.04);
  --workouts-in-progress-periodicity-title: #228176;
  --workouts-in-progress-reps-subtitle: #000;
  --workouts-in-progress-skip-border: #bbb;
  --workouts-in-progress-skip-bg: #fff;
  --workouts-in-progress-skip-text: #666;
  --workouts-in-progress-skip-bg-hover: #fefefe;

  // WorkoutButton
  --workouts-button-text: #fff;
  --workouts-button-bg: linear-gradient(262deg, #54ccc2, #2a9d8f);
  --unsubscribe-reason-button-boxshadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --workouts-button-bg-hover: linear-gradient(262deg, #2a9d8f, #1d746a);
  --workouts-button-boxshadow-hover: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --workouts-button-bg-active: linear-gradient(262deg, #257f77 99%, #0c433c 0%);
  --workouts-button-boxshadow-active: 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --workouts-button-disabled-bg: #b2dbd8;
  --workouts-button-disabled-text: rgba(255, 255, 255, 0.8);
  --workouts-spinner: rgba(255, 255, 255, 0.8);
  --workout-button-container-bg: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8),
    #fff 50%
  );

  // WorkoutsProgressBar
  --workouts-progress-bar-pill-bg: #efefef;
  --workouts-progress-bar-active-pill-bg: #2a9d8f;

  // WorkoutsHeader
  --workouts-header-title: #000;
  --workouts-header-time: #666;

  // WorkoutsMedia
  --workouts-media-bg: rgba(42, 157, 143, 0.04);

  // WorkoutFirstOverlay
  --workouts-first-overlay-bg: rgba(19, 48, 45, 0.8);
  --workouts-first-overlay-text: #fff;
  --workouts-first-overlay-countdown: #b2dbd8;

  // WorkoutRest
  --workouts-rest-bg: #13302d;
  --workouts-rest-title: #b2dbd8;
  --workouts-rest-countdown: #fff;
  --workouts-rest-seconds-btn: #fff;
  --workouts-rest-seconds-btn-border: #fff;
  --workouts-rest-skip-btn: #fff;
  --workouts-rest-skip-bg: linear-gradient(264deg, #4ec5ba 0%, #2da092 100%);
  --workouts-rest-skip-bs: 0px 2px 4px 0px rgba(105, 159, 153, 0.4);
  --workouts-rest-progress-txt: #b2dbd8;
  --workouts-rest-exercise-title: #fff;
  --workouts-rest-duration: #fff;
  --workouts-rest-video-bg: #b2dbd8;

  // WorkoutComplete
  --workouts-complete-bg: #13302d;
  --workouts-complete-subtitle: #fff;
  --workouts-complete-title: #fff;
  --workouts-complete-summary-value: #b2dbd8;
  --workouts-complete-summary-label: #fff;
  --workouts-complete-question: #fff;
  --workouts-complete-answer-btn-bg: #d4ebe9;
  --workouts-complete-answer-btn-active-bg: #2a9d8f;
  --workouts-complete-answer-label: #fff;

  // End: Workouts

  // Start: KetoChocolateBars
  // Offer -> Announcment
  --keto-chocolate-bars-announcment-bg: linear-gradient(
    180deg,
    #815123 0%,
    #2b1508 100%
  );
  --keto-chocolate-bars-announcment-bg-v2: linear-gradient(
    0deg,
    #673926 0%,
    #aa5620 100%
  );
  --keto-chocolate-bars-announcment-bg-v3: linear-gradient(
    0deg,
    #673926 0%,
    #aa5620 100%
  );
  --keto-chocolate-bars-announcment-hero-image-bg: rgba(42, 157, 143, 0.04);
  --keto-chocolate-bars-announcment-section-bg: #fff;
  --keto-chocolate-bars-announcment-section-bs: 0px -4px 24px 0px rgba(175, 175, 175, 0.25);
  --keto-chocolate-bars-announcment-title: #000;
  --keto-chocolate-bars-announcment-subtitle: #000;
  --keto-chocolate-bars-announcment-button-accept: #fff;
  --keto-chocolate-bars-announcment-button-decline: #bbb;

  // Offer
  --keto-chocolate-bars-offer-bg: #fff;

  // LyteBars
  --lyte-bars-announcment-bg: linear-gradient(0deg, #c0c0c0 0%, #e0e0e0 100%);

  // End: EndChocolateBars

  // LastChanceModal
  --last-chance-modal-bg: #fff;
  --last-chance-modal-title: #000;
  --last-chance-modal-title-accent: #c94141;
  --last-chance-modal-subtitle: #666;
  --last-chance-modal-container-bg: #eef7f6;
  --last-chance-modal-chart-title: #333;
  --last-chance-modal-chart-footer-text: #228176;
  --last-chance-modal-bubble-text: #fff;
  --last-chance-modal-bubble-bg: #bbb;
  --last-chance-modal-bubble-bg-accented: #2a9d8f;
  --last-chance-modal-price-text: #666;
  --last-chance-modal-benefit-border: #b2dbd8;
  --last-chance-modal-benefit-bg: #fff;
  --last-chance-modal-benefit-title: #228176;
  --last-chance-modal-benefit-text: #000;
  --last-chance-modal-green-accent: #228176;
}

body.ketogo {
  // Primary/Secondary colors
  --primary-background-color: var(--color-global-white);
  --primary-title-color: rgba(0, 0, 0, 0.87);
  --primary-text-color: rgba(0, 0, 0, 0.6);
  --primary-accent-color: #2a9d8f;

  // Slider
  --slider-span-lable-color: #fff;

  // Colors
  --color-keto-accent: #2a9d8f;
  --color-keto-accent-2: #264653;
  --color-keto-accent-opacity: rgba(98, 2, 238, 0);
  --color-keto-accent-opacity-2: rgba(187, 187, 187, 0.12);
  --color-keto-accent-opacity-3: rgba(42, 157, 143, 0.2);
  --color-keto-error: #a12027;
  --color-keto-divider: #d6d6d6;

  --color-keto-grey: #bbbbbb;
  --color-keto-grey-2: #909090;
  --color-keto-grey-3: #646464;
  --color-keto-grey-4: #979797;

  --color-keto-white: #f6f4f3;
  --color-keto-white-2: #f5f5f5;

  // MODALS: Start
  // Modal Modal.module.
  --modal-background: var(--color-global-white);

  // Modal - DiaryUnskippable
  --diary-text: var(--color-global-text-opacity-8);
  --diary-background: var(--color-global-white);
  --diary-event: var(--color-keto-accent);
  --diary-calendar-background: #d4ebe9;
  --diary-calendar-boxshadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
  --diary-calendar-border: var(--color-keto-accent);

  // Moadal - PlanUpdate
  --plan-update-title-text: var(--color-global-text-opacity-8);
  --plan-update-text: var(--color-global-text-opacity-8);
  --plan-update-background: var(--color-global-white);
  --plan-update-boxshadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --plan-update-close-icon-active: var(--color-keto-grey-3);

  // Modal - CookieSettings
  --cookie-settings-background: var(--color-global-white);
  --cookie-settings-boxshadow: 0 -11px 15px -7px rgba(0, 0, 0, 0.2),
    0 -9px 46px 8px rgba(0, 0, 0, 0.12), 0 -24px 38px 3px rgba(0, 0, 0, 0.14);
  --cookie-settings-boxshadow-mobile: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --cookie-settings-link-text: var(--color-keto-accent);

  // Modal - Testimonial
  --testimonial-title-text: var(--color-global-text-opacity-8);
  --testimonial-text: var(--color-global-text-opacity-8);
  --testimonial-background: var(--color-global-white);
  --testimonial-boxshadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --testimonial-close-icon-active: var(--color-keto-grey-3);
  // Modal - Pregnant
  --pregnant-subtext: var(--color-global-text-opacity-8);

  --unsubscribev3-modal-background: #f6f4f3;
  --unsubscribev3-modal-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --unsubscribev3-modal-close-svg-hover: #ced5da;
  --unsubscribev3-modal-title: var(--color-black-opacity8);
  --unsubscribev3-modal-subtitle: var(--color-black-opacity8);
  --unsubscribev3-modal-paragraph: var(--color-black-opacity8);
  --unsubscribev3-modal-title-warning: var(--global-warning-color);
  --unsubscribev3-modal-fullprice-warning: var(--color-keto-error);

  // MODALS: End

  // Navbar
  --navbar-background: linear-gradient(
    82deg,
    #264653 27%,
    #2a9d8f 59%,
    #e9c46a
  );
  --navbar-svg: rgba(255, 255, 255, 0.54);

  // Calendar
  --primary-calendar-header: var(--color-keto-accent);
  --primary-calendar-button: var(--color-keto-accent);
  --primary-calendar-event-background: var(--color-keto-accent);
  --primary-calendar-event-text: #ffcc70;
  --primary-calendar-selected-background: var(--color-keto-accent-opacity-3);
  --primary-calendar-selected-boxshadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
  --primary-calendar-resting-background: #fafafa;
  --primary-calendar-resting-background-hover: rgba(42, 157, 143, 0.1);
  --primary-calendar-resting-border: rgba(0, 0, 0, 0.12);
  --primary-calendar-resting-border-hover: rgba(0, 0, 0, 0.24);
  --primary-calendar-past-bg-image: url('~assets/images/program/calendar/keto/past/resting.svg');
  --primary-calendar-past-bg-image-hover: url('~assets/images/program/calendar/keto/past/hover.svg');
  --primary-calendar-past-bg-image-selected: url('~assets/images/program/calendar/keto/past/selected.svg');
  --primary-calendar-mobile-background-left: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 1%,
    rgba(255, 255, 255, 0.99) 100%
  );
  --primary-calendar-mobile-background-right: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 1%,
    rgba(255, 255, 255, 0.99) 100%
  );
  --secondary-calendar-header: var(--color-keto-accent-2);

  // TOS
  --tos-button: var(--color-keto-grey);

  // Loading
  --loading-text: var(--color-keto-accent);

  // Flash message
  --flash-message-text: var(--color-global-white);
  --flash-message-error: var(--color-keto-error);
  --flash-message-success: var(--color-keto-accent);

  // Button
  --button-text: var(--color-keto-grey);
  --button-text-hover: var(--color-keto-grey-2);
  --button-border: var(--color-keto-grey);
  --button-border-hover: var(--color-keto-grey-2);
  --button-background: var(--color-keto-accent-opacity);
  --button-background-hover: var(--color-keto-accent-opacity-2);
  --secondary-button-text: var(--color-keto-accent);
  --secondary-button-border: rgba(42, 157, 143, 0.5);
  --secondary-button-background-hover: rgba(42, 157, 143, 0.04);
  --tertiary-button-contained-text: var(--color-global-white);
  --tertiary-button-contained-text-hover: var(--color-global-white);
  --tertiary-button-contained-background: linear-gradient(
    262deg,
    #54ccc2,
    #2a9d8f
  );
  --tertiary-button-contained-background-hover: linear-gradient(
    262deg,
    #2a9d8f,
    #1d746a
  );
  --tertiary-button-contained-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --tertiary-button-contained-shadow-hover: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);

  // Language dropdown
  --language-dropdown-border: rgba(0, 0, 0, 0.23);
  --language-dropdown-background: var(--color-keto-white);
  --language-dropdown-background-hover: #433e3f;

  // TextInput
  --text-input-backgrond: transparent;
  --text-input-text: var(--color-global-black);
  --text-input-border: rgba(25, 25, 25, 0.32);
  --text-input-border-hover: #433e3f;
  --text-input-border-active: #2a9d8f;

  // LinkButton
  --link-button-text: var(--color-keto-grey);
  --link-button-text-hover: var(--color-keto-grey-2);
  --link-button-border: var(--color-keto-grey);
  --link-button-border-hover: var(--color-keto-grey-2);
  --link-button-background: var(--color-keto-accent-opacity);
  --link-button-background-hover: var(--color-keto-accent-opacity-2);
  // LinkButton-shopping
  --link-button-shopping-text: var(--color-keto-accent);

  // SuccessIcon
  --success-icon-background: linear-gradient(
    45deg,
    #264653 0%,
    #2a9d8f 44%,
    #e9c46a
  );

  // SubmitButton
  --primary-submit-button-background: linear-gradient(262deg, #54ccc2, #2a9d8f);
  --primary-submit-button-text: var(--color-global-white);
  --primary-submit-button-background-loading: #ced5da;
  --primary-submit-button-background-hover: linear-gradient(
    262deg,
    #2a9d8f,
    #1d746a
  );
  --primary-submit-button-background-active: linear-gradient(
    262deg,
    #257f77 99%,
    #0c433c 0%
  );
  --primary-submit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --primary-submit-box-shadow-hover: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --primary-submit-box-shadow-active: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --secondary-submit-button-text: var(--color-keto-grey);
  --secondary-submit-button-text-hover: var(--color-keto-grey-2);
  --secondary-submit-button-border: var(--color-keto-grey);
  --secondary-submit-button-border-hover: var(--color-keto-grey-2);
  --secondary-submit-button-background: var(--color-keto-accent-opacity);
  --secondary-submit-button-background-hover: var(
    --color-keto-accent-opacity-2
  );
  --tertiary-submit-button-text: var(--color-keto-accent);

  // Meal
  --meal-title: var(--color-global-text-opacity-8);
  --meal-info-text: var(--color-global-text-opacity-8);
  --meal-benefit-text: var(--color-global-grey-2);
  --meal-image-container-background: rgba(42, 157, 143, 0.04);
  --meal-lock-icon: var(--color-global-black);
  --meal-lock-icon-background: var(--color-keto-accent);

  // MealChange
  --meal-change-title: var(--color-global-text-opacity-8);
  --meal-change-background: var(--color-keto-white);
  --meal-change-dot: #cccccc;
  --meal-change-dot-selected: var(--color-keto-accent);
  --meal-change-fade-left: linear-gradient(
    to right,
    #ffffff 0%,
    rgba(255, 255, 255, 0.001) 100%
  );
  --meal-change-fade-right: linear-gradient(
    to left,
    #ffffff 0%,
    rgba(255, 255, 255, 0.001) 100%
  );

  // ChangePrompt
  --change-prompt-text: var(--color-global-text-opacity-8);
  --change-prompt-background: var(--color-keto-white);
  --change-prompt-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);

  // ChatPage
  --chat-page-header-backgroud: var(--color-keto-accent-2);
  --chat-page-header-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --chat-page-body-top-fade: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.8) 25%,
    rgba(255, 255, 255, 0.01) 100%
  );
  --chat-page-footer-border: #a6a6a6;
  --chat-page-input-text: var(--color-global-text-opacity-6);
  --chat-page-input-border: rgba(0, 0, 0, 0.32);
  --chat-page-issue-background: var(--color-keto-error);
  --chat-page-issue-border: var(--color-keto-error);
  --chat-page-send-icon: var(--color-keto-accent);

  // ChatLine
  --chat-line-author-text: rgba(0, 0, 0, 0.38);
  --chat-line-user-background: rgba(42, 157, 143, 0.1);
  --chat-line-admin-background: var(--color-keto-white-2);

  // Avatar
  --chat-avatar-user-text: var(--color-keto-accent-2);
  --chat-avatar-user-background: rgba(42, 157, 143, 0.1);
  --chat-avatar-admin-background: var(--color-keto-white-2);

  // SystemMessage
  --system-message-text: var(--color-global-text-opacity-6);

  // Testamonial
  --testamonial-header-text: var(--color-global-text-opacity-8);
  --testamonial-text: var(--color-global-text-opacity-8);
  --testamonial-link-text: var(--color-keto-accent);

  // ShoppingList
  --shopping-list-header-icon: var(--color-keto-accent-2);
  --shopping-list-product-icon: var(--color-keto-accent-2);
  --shopping-list-subtitle-text: var(--color-global-text-opacity-3);
  --shopping-list-item-text: var(--color-global-black);
  --shopping-list-unit-text: var(--color-global-black);

  // SelectButton
  --select-buttons-selected-background: var(--color-keto-accent-opacity-3);

  // ProfilePage
  --profile-page-svg-fill: var(--color-keto-accent-2);

  // GoalBlock
  --goal-block-title-text: var(--color-global-text-opacity-8);

  // AccountBlock
  --account-block-title-text: var(--color-global-text-opacity-8);
  --account-block-link-text: var(--color-keto-accent);

  // EditAccountPage
  --edit-account-page-border: var(--account-block-border);

  // UnsubscribeBlock
  --unsubscribe-block-link: var(--color-keto-accent);
  --unsubscribe-block-warning-text: var(--color-keto-error);

  // CheckBox
  --check-box-selected-background: var(--color-keto-accent-opacity-3);

  // MealSurvey
  --meal-survey-loading-text: var(--color-keto-accent);
  --meal-survey-progress-bar-title-text: var(--color-keto-accent-2);

  // MedicalAgreement
  --medical-agreement-disagree-title-text: var(--color-keto-error);

  // OfferBanner
  --offer-banner-text: var(--color-keto-error);
  --offer-banner-background: #ffcc70;
  --offer-banner-boxshadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14);

  // SubTosPage
  --sub-tos-text: var(--color-global-grey-2);
  --sub-tos-subtitle-text: var(--color-global-text-opacity-8);
  --sub-tos-background: #f6f4f3;

  // Templates: Start
  // Pre_unsubscribe_v3

  // Sub_expired
  --sub-expired-thumb-up: var(--color-keto-accent);
  --sub-expired-thumb-down: var(--color-keto-grey-4);
  --sub-expired-button-background: #fafafa;
  // Templates: End

  //SubscriptionText
  --subscription-warning-text: var(--global-warning-color);
  --subscription-link: var(--primary-accent-color);

  --unsubscribed-modal-bg-color: var(--primary-background-color);
  --discount-modal-bg-color: var(--primary-background-color);

  --chatline-link: #2a9d8f;

  // PostPayment
  --payment-failed-text: var(--color-global-text-opacity-8);
  --payment-failed-background: #f6f4f3;
  --payment-failed-mui-button-text: var(--color-global-text-opacity-8);
  --payment-failed-mui-button-background: #fafafa;
  --payment-failed-mui-button-background-hover: rgba(0, 0, 0, 0.04);

  // Quiz
  --quiz-title-color: var(--color-global-text-opacity-6);
  --quiz-question-color: var(--color-global-text-opacity-8);
  --quiz-selected-answer-background: rgba(255, 158, 0, 0.07);

  --objection-text: var(--color-global-grey-2);
  --objection-title: var(--color-global-text-opacity-8);
  --objection-background: var(--premium-upsell-include-image-background);
  --objection-specialist-text: var(--color-global-text-opacity-6);
  --objection-link-button: var(--primary-accent-color);
  --objection-bubble-background: var(--premium-upsell-main-title-background);

  --quiz-modal-background: var(--color-orange-2);
  --quiz-modal-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --quiz-modal-button-color: var(--color-global-text-opacity-8);

  // PremiumSubscription
  --premium-block-border: var(--color-keto-divider);
  --premium-block-title: var(--color-global-text-opacity-8);
  --premium-block-text: var(--color-global-text-opacity-6);

  // ShoppingList Offer
  --shopping-list-offer-title: var(--color-global-text-opacity-8);
  --shopping-list-offer-title-accent: var(--primary-accent-color);
  --shopping-list-offer-price: var(--color-global-text-opacity-6);
  --shopping-list-offer-list-item: var(--color-global-text-opacity-8);

  --premium-upsell-timer-text: var(--primary-accent-color);

  // FatBuner Offer
  --fat-burner-offer-delivery: var(--primary-accent-color);
  --fat-burner-chat-link: var(--primary-accent-color);
  --fat-burner-success-page-link: var(--primary-accent-color);

  // AnnualProOffer
  --annual-pro-offer-background: #f6f4f3;
  --annual-pro-offer-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --annual-pro-offer-warning-background: #d84f4f;
  --annual-pro-offer-warning-text: rgba(255, 255, 255, 0.87);
  --annual-pro-offer-title: var(--color-global-text-opacity-8);
  --annual-pro-offer-price: var(--color-global-text-opacity-8);
  --annual-pro-offer-price-small: var(--color-global-text-opacity-6);

  // AnnualCongratsView
  --annual-pro-congrats-background: #f6f4f3;
  --annual-pro-congrats-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --annual-pro-congrats-title: var(--color-global-text-opacity-8);
  --annual-pro-congrats-text: var(--color-global-text-opacity-8);

  // ShoppingListFree
  --shopping-list-free-text: var(--color-global-text-opacity-8);
  --shopping-list-free-offer-text: var(--color-global-text-opacity-6);
  --shopping-list-free-offer-price: var(--color-global-text-opacity-6);

  // TOSLinkBlock
  --tos-link-block-divider: var(--color-keto-divider);
  --tos-link-block-text: var(--color-keto-accent);

  // CardSaveConfirmation
  --card-save-confirmation-background: #f6f4f3;
  --card-save-confirmation-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --card-save-confirmation-close-icon: #646464;
  --card-save-confirmation-title: var(--color-global-text-opacity-8);
  --card-save-confirmation-text: var(--color-global-text-opacity-8);

  // HelpdeskFooter
  --helpdesk-footer-text: #bbbbbb;
  --helpdesk-footer-link: var(--primary-accent-color);

  // ShoppingListV2
  --offer-shoppinglist-v2-bg: #ffcc70;
  --offer-shoppinglist-v2-banner-bg: #264653;
  --offer-shoppinglist-v2-text-secondary: #2a9d8f;
  --offer-shoppinglist-v2-text-primary: #ffcc70;
  --offer-shoppinglist-v2-text-base: rgba(0, 0, 0, 0.87);
  --offer-shoppinglist-v2-banner-text: #ffcc70;
  --offer-shoppinglist-v2-text-price: rgba(0, 0, 0, 0.6);
  --offer-shoppinglist-v2-percentage: #2a9d8f;
  --offer-shoppinglist-v2-badge-text: #264653;
  --offer-shoppinglist-v2-photo-text: #264653;
  --offer-shoppinglist-v2-text-highlighted: #f65471;

  // PhysicalProductUnsubscribed
  --physical-unsub-bg-color: #f6f4f3;
  --physical-unsub-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --physical-unsub-title-color: rgba(0, 0, 0, 0.87);
  --physical-unsub-text-color: rgba(0, 0, 0, 0.87);
  --physical-unsub-link-text-color: rgba(0, 0, 0, 0.87);
  --physical-unsub-link-color: #2a9d8f;

  // UnsubscribeV8
  // Recap
  --recap-bg: #122f2c;
  --recap-card-container-bg: linear-gradient(
    180deg,
    rgba(29, 77, 71, 0) 26.04%,
    #122f2b 39.06%
  );
  --recap-card-container-color: #fff;
  --recap-card-container-bs: rgba(42, 157, 143, 0.5);
  --recap-wrapper-bg: linear-gradient(
    180deg,
    rgba(29, 77, 71, 0) 190px,
    #122f2b 357px
  );
  --recap-footer-container-bg: linear-gradient(
    180deg,
    rgba(18, 47, 44, 0) 0%,
    #122f2c 28.65%
  );
  // Recap - Title
  --recap-title-color: #fff;
  // Recap - BackButton
  --recap-backbtn-text-color: #666666;
  --recap-backbtn-bg-color: #fff;
  --recap-backbtn-bs: 0px -4px 24px 0px rgba(102, 102, 102, 0.7);
  // Recap - NextButton
  --recap-nextbtn-text-color: #fff;
  --recap-nextbtn-bg-color: #000;
  --recap-nextbtn-hover-text-color: #b0b0b0;
  // Recap - Basic
  --recap-basic-bg-color: #fff;
  --recap-basic-bs: 0px 4px 24px 0px rgba(255, 255, 255, 0.16);
  --recap-basic-title-color: #228176;
  --recap-basic-subtitle-color: #228176;
  --recap-basic-text-color: #000;
  --recap-basic-accent-color: #228176;
  --recap-basic-secondary-accent-color: #a12027;
  // Recap - BasicLinked
  --recap-basiclinked-bg-color: #fff;
  --recap-basiclinked-bs: 0px 4px 24px 0px rgba(255, 255, 255, 0.16);
  --recap-basiclinked-title-color: #228176;
  --recap-basiclinked-subtitle-color: #228176;
  --recap-basiclinked-text-color: #000;
  --recap-basiclinked-accent-color: #228176;
  --recap-basiclinked-forwardbtn-color: #228176;
  --recap-basiclinked-forwardbtn-bg-color: #fff;
  --recap-basiclinked-forwardbtn-border-color: #228176;
  --recap-basiclinked-forwardbtn-hover-border-color: hsl(173, 58%, 20%);
  --recap-basiclinked-forwardbtn-hover-color: hsl(173, 58%, 20%);
  // Recap - Journey
  --recap-journey-bg-color: #fff;
  --recap-journey-bs: 0px 4px 24px 0px rgba(255, 255, 255, 0.16);
  --recap-journey-title-color: #000;
  --recap-journey-hero-bg-color: #b2dbd8;
  --recap-journey-name-text-color: #000;
  --recap-journey-age-text-color: #666666;
  --recap-journey-user-weight-text-color: #666666;
  --recap-journey-weight-text-color: #666666;
  --recap-journey-accent-color: #228176;
  // Recap - Results
  --recap-results-title-color: #fff;
  --recap-results-text-color: #fff;
  --recap-results-graph-bg-color: #fff;
  --recap-results-graph-title-color: #000;
  --recap-results-accent-color: #228176;
  --recap-results-footer-bg: linear-gradient(
    180deg,
    rgba(18, 47, 44, 0) 0%,
    #122f2c 28.65%
  );
  // Recap - PercentageSpinner
  --recap-percentagespinner-bg-color: conic-gradient(
    #2a9d8f 3.6deg,
    #d9d9d9 0deg
  );
  --recap-percentagespinner-before-bg-color: #122f2c;
  --recap-percentagespinner-text-color: #fff;
  // Recap - Graph
  --recap-graph-weights-color: #4da58b;
  --recap-graph-weightleft-bg-color: #f5fdfb;
  --recap-graph-weightleft-color: #999;
  --recap-graph-months-bg-color: #f5fdfb;
  --recap-graph-months-color: #999;

  // PhysicalProductUnsubscribed
  --physical-unsub-bg-color: #f6f4f3;
  --physical-unsub-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --physical-unsub-title-color: rgba(0, 0, 0, 0.87);
  --physical-unsub-text-color: rgba(0, 0, 0, 0.87);
  --physical-unsub-link-text-color: rgba(0, 0, 0, 0.87);
  --physical-unsub-link-color: #2a9d8f;

  // UnsubscribeReason
  --unsubscribe-reason-title: #228176;
  --unsubscribe-reason-subtitle: #666;
  --unsubscribe-reason-infoblock-bg: #f7f7f7;
  --unsubscribe-reason-infoblock-border: #bbb;
  --unsubscribe-reason-infotext: #000;
  --unsubscribe-reason-secondary-title: #000;
  --unsubscribe-reason-listitem-text: #000;
  --unsubscribe-reason-listitem-bg: #ecfaf6;
  --unsubscribe-reason-radiobutton-default: #bbb;
  --unsubscribe-reason-button-text: #fff;
  --unsubscribe-reason-button-bg: linear-gradient(262deg, #54ccc2, #2a9d8f);
  --unsubscribe-reason-button-boxshadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --unsubscribe-reason-button-bg-hover: linear-gradient(
    262deg,
    #2a9d8f,
    #1d746a
  );
  --unsubscribe-reason-button-boxshadow-hover: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --unsubscribe-reason-button-bg-active: linear-gradient(
    262deg,
    #257f77 99%,
    #0c433c 0%
  );
  --unsubscribe-reason-button-boxshadow-active: 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --unsubscribe-reason-button-disabled-bg: #b2dbd8;
  --unsubscribe-reason-button-disabled-text: rgba(255, 255, 255, 0.8);
  --unsubscribe-reason-spinner: rgba(255, 255, 255, 0.8);
  --unsubscribe-reason-length-text: #666;
  --unsubscribe-reason-max-capacity: #a12027;

  // GenomPlusBlock
  --genom-plus-block-title: var(--color-global-text-opacity-8);
  --genom-plus-block-text: var(--color-global-text-opacity-6);
  --genom-plus-block-border: #d6d6d6;

  // GenomPlus - success screen
  --genom-pluss-success-title: #000;
  --genom-pluss-success-text: #666;

  // GenomPlus - offer
  --genom-plus-offer-bg: #228176;
  --genom-plus-main-title: #fff;
  --genom-plus-main-subtitle: #fff;
  --genom-plus-plan-select-bs: 0px 4px 24px 0px rgba(42, 157, 143, 0.12);
  --genom-plus-plan-select-bg: #fff;
  --genom-plus-plan-select-title: #000;
  --genom-plus-plan-select-text: #666;
  --genom-plus-plan-select-border: #b2dbd8;
  --genom-plus-accent-color-main: #2a9d8f;
  --genom-plus-accent-color-secondary: #228176;
  --genom-plus-plan-information-text: #666;
  --genom-plus-secondary-title: #000;
  --genom-plus-secondary-subtitle: #666;
  --genom-plus-symptom-item-bg: #ffe1e1;
  --genom-plus-symptom-item-text: #000;
  --genom-plus-price-text: #666;
  --genom-plus-aging-bg: #ecf6f6;
  --genom-plus-nutrionist-name: #000;
  --genom-plus-nutrionist-text: #000;
  --genom-plus-nutrionist-bubble-text: #fff;
  --genom-plus-tirtary-subtitle: #000;
  --genom-plus-forth-title: #000;
  --genom-plus-forth-subtitle: #666;
  --genom-plus-checkmark-text: #000;

  // GenomPlus - survey
  --genom-plus-survey-btn-disabled-bg: #b2dbd8;
  --genom-plus-survey-btn-disabled-color: #fff;
  --genom-plus-survey-back-color: #2a9d8f;
  --genom-plus-survey-progress-label-color: #666;
  --genom-plus-survey-progress-bar-bg: #eef7f6;
  --genom-plus-survey-title-color: #000;
  --genom-plus-survey-subtitle-color: #666;
  --genom-plus-survey-text-color: #000;
  --genom-plus-survey-email-color: #228176;

  // GenomPlus - settings
  --genom-plus-settings-title-color: var(--color-global-text-opacity-8);
  --genom-plus-settings-info-color: #666;
  --genom-plus-settings-block-border-color: #d6d6d6;
  --genom-plus-settings-block-title-color: var(--color-global-text-opacity-6);
  --genom-plus-settings-block-info-color: #000;

  // PlanDipslayItem (genomplus)
  --genom-plus-display-item-img-bg: #eef7f6;
  --genom-plus-display-item-text: #666;
  --genom-plus-display-item-text-secondary: #228176;
  --genom-plus-display-item-discount-bg: #d4ebe9;
  --genom-plus-display-item-discount-text: #000;
  --genom-plus-display-item-prev-price: #999;

  // PlanSelect (genomplus)
  --genom-plus-plan-selection-bg: #fff;
  --genom-plus-plan-selection-border: 1px solid #d4ebe9;
  --genom-plus-plan-selection-disabled: #eef7f6;
  --genom-plus-plan-selection-selected: #eef7f6;
  --genom-plus-plan-selection-selected-hover: #d4ebe9;
  --genom-plus-plan-selection-selected-hovered-img: #eef7f6;
  --genom-plus-plan-selection-tick-border: 1px solid #b2dbd8;
  --genom-plus-plan-selection-tick-bg: #eef7f6;
  --genom-plus-plan-selection-tick-selected: #2a9d8f;
  --genom-plus-plan-selection-tick-hover: #d4ebe9;
  --genom-plus-plan-selection-base-selected: #2a9d8f;
  --genom-plus-plan-selection-title: #000;
  --genom-plus-plan-selection-accent: #2a9d8f;
  --genom-plus-plan-selection-subtitle: #228176;

  // GenomPlus PopUp screen
  --genom-plus-popup-bg-shadow: rgba(0, 0, 0, 0.6);
  --genom-plus-popup-bg: #f6f4f3;
  --genom-plus-popup-bs: 0px 12px 16px 0px rgba(104, 104, 104, 0.16);
  --genom-plus-popup-title: #000;
  --genom-plus-popup-text: #000;
  --genom-plus-popup-upgrade-bg: #fff;

  // DefaultSuccess screen
  --default-success-bg-shadow: rgba(0, 0, 0, 0.6);
  --default-success-bg: #f6f4f3;
  --default-success-bs: 0px 12px 16px 0px rgba(104, 104, 104, 0.16);
  --default-success-title: #000;
  --default-success-text: #464646;

  // ShoppingListBlock
  --shopping-list-block-border: #d6d6d6;
  --shopping-list-title: rgba(0, 0, 0, 0.87);
  --shopping-list-text: rgba(0, 0, 0, 0.6);

  // PreUnsubscribeShoppinglist
  --preunsubscribe-shoppinglist-background: #fff;
  --preunsubscribe-shoppinglist-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --preunsubscribe-shoppinglist-exit-icon-fill: #646464;
  --preunsubscribe-shoppinglist-text-color: #2e3033;

  // ChatBlock
  --chat-block-border: #d6d6d6;
  --chat-block-title: rgba(0, 0, 0, 0.87);
  --chat-block-text: rgba(0, 0, 0, 0.6);

  // Preunsubscribe Chat
  --preunsubscribe-chat-background: #fff;
  --preunsubscribe-chat-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --preunsubscribe-chat-exit-icon-fill: #646464;
  --preunsubscribe-chat-text-color: #2e3033;

  // UnsubscribeV8 - FREE OFFER
  --free-offer-accent-color: #228176;
  --free-offer-title-color: #000;
  --free-offer-subtitle-base-color: #000;
  --free-offer-subtitle-custom-color: #666;
  --free-offer-infoblock-border: #b2dbd8;
  --free-offer-infoblock-bg: #effcfa;
  --free-offer-infoblock-text: #000;
  --free-offer-infoblock-weight: #228176;
  --free-offer-infoblock-subtitle: #666;
  --free-offer-item-heroitem-title: #000;
  --free-offer-item-heroitem-title-accented: #228176;
  --free-offer-item-heroitem-text: #666;
  --free-offer-item-list-text: #000;
  --free-offer-item-list-circle-bg: #2a9d8f;
  --free-offer-modal-bg: #fafafa;
  --free-offer-modal-box-shadow: drop-shadow(0px 11px 15px rgba(0, 0, 0, 0.2))
    drop-shadow(0px 9px 46px rgba(0, 0, 0, 0.12))
    drop-shadow(0px 24px 38px rgba(0, 0, 0, 0.14));
  --free-offer-modal-title: var(--color-black-opacity8);
  --free-offer-modal-text: var(--color-black-opacity8);

  // Start: Workouts

  // ProgramWorkout
  --program-workout-active-svg-bg: #2a9d8f;
  --program-workout-active-small-title: #000;
  --program-workout-active-big-title: #000;
  --program-workout-active-benefit-text: #333333;
  --program-workout-inactive-bg: #e4e9e9;
  --program-workout-inactive-bg-video: rgba(42, 157, 143, 0.04);
  --program-workout-inactive-link-text: #000;
  --program-workout-inactive-accent-text: #228176;
  --program-workout-active-thumbnail-bg: rgba(42, 157, 143, 0.04);
  --program-workout-active-rest: #666;

  // ProgramOfferModal
  --program-offer-modal-bg: #f6f4f3;
  --program-offer-modal-bs: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --program-offer-modal-title: rgba(0, 0, 0, 0.87);
  --program-offer-modal-text: rgba(0, 0, 0, 0.87);

  // WorkoutsStartPage
  --workouts-start-bg: #fff;
  --workouts-start-items-bg: rgba(42, 157, 143, 0.04);
  --workouts-start-benefits-bg: #2a9d8f;
  --workouts-start-big-title: #000;
  --workouts-start-small-title: #000;
  --workouts-start-information: #000;
  --workouts-start-exercise-title: #000;
  --workouts-start-periodicity-title: #000;
  --workouts-start-periodicity-text: #000;

  // WorkoutsPreview
  --workouts-preview-bg: #fff;
  --workouts-preview-bg-fog: rgba(38, 70, 83, 0.8);
  --workouts-preview-bs: 0px -12px 24px 0px rgba(43, 43, 43, 0.32);
  --workouts-preview-title: #000;
  --workouts-preview-video-bg: rgba(42, 157, 143, 0.04);
  --workouts-preview-title-small: #000;
  --workouts-preview-description: #000;
  --workouts-preview-mobile-notch-bg: #efefef;

  // WorkoutsInProgress
  --workouts-in-progress-bg: #fff;
  --workouts-in-progress-content-title: #000;
  --workouts-in-progress-info-bg: rgba(42, 157, 143, 0.04);
  --workouts-in-progress-periodicity-title: #228176;
  --workouts-in-progress-reps-subtitle: #000;
  --workouts-in-progress-skip-border: #bbb;
  --workouts-in-progress-skip-bg: #fff;
  --workouts-in-progress-skip-text: #666;
  --workouts-in-progress-skip-bg-hover: #fefefe;

  // WorkoutButton
  --workouts-button-text: #fff;
  --workouts-button-bg: linear-gradient(262deg, #54ccc2, #2a9d8f);
  --unsubscribe-reason-button-boxshadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --workouts-button-bg-hover: linear-gradient(262deg, #2a9d8f, #1d746a);
  --workouts-button-boxshadow-hover: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --workouts-button-bg-active: linear-gradient(262deg, #257f77 99%, #0c433c 0%);
  --workouts-button-boxshadow-active: 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --workouts-button-disabled-bg: #b2dbd8;
  --workouts-button-disabled-text: rgba(255, 255, 255, 0.8);
  --workouts-spinner: rgba(255, 255, 255, 0.8);
  --workout-button-container-bg: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0.8),
    #fff 50%
  );

  // WorkoutsProgressBar
  --workouts-progress-bar-pill-bg: #efefef;
  --workouts-progress-bar-active-pill-bg: #2a9d8f;

  // WorkoutsHeader
  --workouts-header-title: #000;
  --workouts-header-time: #666;

  // WorkoutsMedia
  --workouts-media-bg: rgba(42, 157, 143, 0.04);

  // WorkoutFirstOverlay
  --workouts-first-overlay-bg: rgba(19, 48, 45, 0.8);
  --workouts-first-overlay-text: #fff;
  --workouts-first-overlay-countdown: #b2dbd8;

  // WorkoutRest
  --workouts-rest-bg: #13302d;
  --workouts-rest-title: #b2dbd8;
  --workouts-rest-countdown: #fff;
  --workouts-rest-seconds-btn: #fff;
  --workouts-rest-seconds-btn-border: #fff;
  --workouts-rest-skip-btn: #fff;
  --workouts-rest-skip-bg: linear-gradient(264deg, #4ec5ba 0%, #2da092 100%);
  --workouts-rest-skip-bs: 0px 2px 4px 0px rgba(105, 159, 153, 0.4);
  --workouts-rest-progress-txt: #b2dbd8;
  --workouts-rest-exercise-title: #fff;
  --workouts-rest-duration: #fff;
  --workouts-rest-video-bg: #b2dbd8;

  // WorkoutComplete
  --workouts-complete-bg: #13302d;
  --workouts-complete-subtitle: #fff;
  --workouts-complete-title: #fff;
  --workouts-complete-summary-value: #b2dbd8;
  --workouts-complete-summary-label: #fff;
  --workouts-complete-question: #fff;
  --workouts-complete-answer-btn-bg: #d4ebe9;
  --workouts-complete-answer-btn-active-bg: #2a9d8f;
  --workouts-complete-answer-label: #fff;

  // End: Workouts

  // Start: KetoChocolateBars
  // Offer -> Announcment
  --keto-chocolate-bars-announcment-bg: linear-gradient(
    180deg,
    #815123 0%,
    #2b1508 100%
  );
  --keto-chocolate-bars-announcment-bg-v2: linear-gradient(
    0deg,
    #673926 0%,
    #aa5620 100%
  );
  --keto-chocolate-bars-announcment-bg-v3: linear-gradient(
    0deg,
    #673926 0%,
    #aa5620 100%
  );
  --keto-chocolate-bars-announcment-hero-image-bg: rgba(42, 157, 143, 0.04);
  --keto-chocolate-bars-announcment-section-bg: #fff;
  --keto-chocolate-bars-announcment-section-bs: 0px -4px 24px 0px rgba(175, 175, 175, 0.25);
  --keto-chocolate-bars-announcment-title: #000;
  --keto-chocolate-bars-announcment-subtitle: #000;
  --keto-chocolate-bars-announcment-button-accept: #fff;
  --keto-chocolate-bars-announcment-button-decline: #bbb;

  // Offer
  --keto-chocolate-bars-offer-bg: #fff;

  // LyteBars
  --lyte-bars-announcment-bg: linear-gradient(0deg, #c0c0c0 0%, #e0e0e0 100%);

  // End: EndChocolateBars
}

body.fasting {
  --primary-background-color: var(--color-global-white);
  --primary-title-color: rgba(0, 0, 0, 0.87);
  --primary-text-color: rgba(0, 0, 0, 0.6);
  --primary-accent-color: #29abe2;

  // Slider
  --slider-span-lable-color: #fff;

  --primary-color: #29abe2;
  --primary-color-hover: #1e86b2;
  --primary-color-active: #135f80;
  --secondary-color: #03cea4;
  --tertiary-color: #08605f;
  --quaternary-color: #ffffff;

  --error-color: #b00020;
  --primary-background-color: var(--quaternary-color);
  --divider-color: #d6d6d6;

  // Button
  --button-text: var(--primary-color);
  --button-text-hover: var(--primary-color);
  --button-border: var(--primary-color);
  --button-border-hover: var(--primary-color-hover);
  --button-background: var(--quaternary-color);
  --button-background-hover: rgba(41, 171, 226, 0.12);

  --secondary-button-text: var(--primary-color);
  --secondary-button-border: var(--primary-color);
  --secondary-button-background-hover: rgba(41, 171, 226, 0.12);

  --tertiary-button-contained-text: var(--color-global-white);
  --tertiary-button-contained-text-hover: var(--color-global-white);
  --tertiary-button-contained-background: var(--primary-color);
  --tertiary-button-contained-background-hover: var(--primary-color-hover);
  --tertiary-button-contained-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --tertiary-button-contained-shadow-hover: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);

  // SubmitButton
  --primary-submit-button-background: var(--primary-color);
  --primary-submit-button-text: var(--quaternary-color);
  --primary-submit-button-background-loading: #ced5da;
  --primary-submit-button-background-hover: var(--primary-color-hover);
  --primary-submit-button-background-active: var(--primary-color-active);
  --primary-submit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --primary-submit-box-shadow-hover: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --primary-submit-box-shadow-active: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);

  --secondary-submit-button-text: var(--primary-color);
  --secondary-submit-button-text-hover: var(--primary-color);
  --secondary-submit-button-border: var(--primary-color);
  --secondary-submit-button-border-hover: var(--primary-color-hover);
  --secondary-submit-button-background: var(--quaternary-color);
  --secondary-submit-button-background-hover: rgba(41, 171, 226, 0.12);

  --tertiary-submit-button-text: var(--primary-color);

  // Language dropdown
  --language-dropdown-border: rgba(0, 0, 0, 0.23);
  --language-dropdown-background: var(---quaternary-color);
  --language-dropdown-background-hover: #433e3f;

  // Icon BG
  --success-icon-background: linear-gradient(225deg, #03cea4, #29abe2);

  // TextInput
  --text-input-backgrond: transparent;
  --text-input-text: #464646;
  --text-input-border: #979797;
  --text-input-border-hover: #433e3f;
  --text-input-border-active: var(--primary-color);

  // LinkButton
  --link-button-text: var(--primary-color);
  --link-button-text-hover: var(--primary-color);
  --link-button-border: var(--primary-color);
  --link-button-border-hover: var(--primary-hover-color);
  --link-button-background: var(--quaternary-color);
  --link-button-background-hover: rgba(41, 171, 226, 0.12);
  // LinkButton-shopping
  --link-button-shopping-text: var(--primary-color);

  // MODALS: Start
  // Modal Modal.module.
  --modal-background: var(--color-global-white);

  // Modal - DiaryUnskippable
  --diary-text: var(--color-global-text-opacity-8);
  --diary-background: var(--color-global-white);
  --diary-event: var(--primary-color);
  --diary-calendar-background: #d3f2ff;
  --diary-calendar-boxshadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
  --diary-calendar-border: var(--primary-color);

  // Modal - PlanUpdate
  --plan-update-title-text: var(--color-global-text-opacity-8);
  --plan-update-text: var(--color-global-text-opacity-8);
  --plan-update-background: var(--color-global-white);
  --plan-update-boxshadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --plan-update-close-icon-active: var(--color-grey-26);

  // Modal - CookieSettings
  --cookie-settings-background: var(--color-global-white);
  --cookie-settings-boxshadow: 0 -11px 15px -7px rgba(0, 0, 0, 0.2),
    0 -9px 46px 8px rgba(0, 0, 0, 0.12), 0 -24px 38px 3px rgba(0, 0, 0, 0.14);
  --cookie-settings-boxshadow-mobile: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --cookie-settings-link-text: var(--primary-color);

  // Modal - Testimonial
  --testimonial-title-text: var(--color-global-text-opacity-8);
  --testimonial-text: var(--color-global-text-opacity-8);
  --testimonial-background: var(--color-global-white);
  --testimonial-boxshadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --testimonial-close-icon-active: var(--color-grey-26);
  // Modal - Pregnant
  --pregnant-subtext: var(--color-global-text-opacity-8);

  --unsubscribev3-modal-background: #f6f4f3;
  --unsubscribev3-modal-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --unsubscribev3-modal-close-svg-hover: #ced5da;
  --unsubscribev3-modal-title: var(--color-black-opacity8);
  --unsubscribev3-modal-subtitle: var(--color-black-opacity8);
  --unsubscribev3-modal-paragraph: var(--color-black-opacity8);
  --unsubscribev3-modal-title-warning: var(--global-warning-color);
  --unsubscribev3-modal-fullprice-warning: var(--error-color);

  // StartFasting
  --start-fasting-modal-background: var(--color-global-white);
  --start-fasting-modal-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --start-fasting-modal-cross-icon-hover: #646464;
  --start-fasting-modal-title: var(--color-global-text-opacity-8);
  --start-fasting-modal-text: var(--color-global-text-opacity-8);
  --start-fasting-modal-sub-text: var(--color-global-text-opacity-8);

  // StopFasting
  --stop-fasting-modal-background: var(--color-global-white);
  --stop-fasting-modal-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --stop-fasting-modal-cross-icon-hover: #646464;
  --stop-fasting-modal-title: var(--color-global-text-opacity-8);
  --stop-fasting-modal-text: var(--color-global-text-opacity-8);

  // StopEating
  --stop-eating-modal-background: var(--color-global-white);
  --stop-eating-modal-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --stop-eating-modal-cross-icon-hover: #646464;
  --stop-eating-modal-title: var(--color-global-text-opacity-8);
  --stop-eating-modal-text: var(--color-global-text-opacity-8);

  // FinishFasting
  --finish-fasting-modal-background: var(--color-global-white);
  --finish-fasting-modal-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --finish-fasting-modal-cross-icon-hover: #646464;
  --finish-fasting-modal-title: var(--color-global-text-opacity-8);
  --finish-fasting-modal-text: var(--color-global-text-opacity-8);
  --finish-fasting-cell-title: var(--color-global-text-opacity-8);
  --finish-fasting-cell-value: var(--color-global-text-opacity-8);
  --finish-fasting-time: var(--color-global-text-opacity-8);

  // MODALS: End

  // Navbar
  --navbar-background: linear-gradient(268deg, #03cea4, #29abe2);
  --navbar-svg: rgba(255, 255, 255, 0.54);

  // Calendar
  --primary-calendar-header: var(--primary-color);
  --primary-calendar-button: var(--primary-color);
  --primary-calendar-event-background: var(--primary-color);
  --primary-calendar-event-text: var(--primary-color);
  --primary-calendar-selected-background: #d3f2ff;
  --primary-calendar-selected-boxshadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
  --primary-calendar-resting-background: #fafafa;
  --primary-calendar-resting-background-hover: #d3f2ff;
  --primary-calendar-resting-border: rgba(0, 0, 0, 0.12);
  --primary-calendar-resting-border-hover: rgba(0, 0, 0, 0.24);
  --primary-calendar-past-bg-image: url('~assets/images/program/calendar/fasting/past/resting.svg');
  --primary-calendar-past-bg-image-hover: url('~assets/images/program/calendar/fasting/past/hover.svg');
  --primary-calendar-past-bg-image-selected: url('~assets/images/program/calendar/fasting/past/selected.svg');
  --primary-calendar-mobile-background-left: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 1%,
    rgba(255, 255, 255, 0.99) 100%
  );
  --primary-calendar-mobile-background-right: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 1%,
    rgba(255, 255, 255, 0.99) 100%
  );
  --secondary-calendar-header: #433e3f;

  // TOS
  --tos-button: var(--primary-color);

  // Loading
  --loading-text: var(--secondary-color);

  // FlashMessage
  --flash-message-success: var(--primary-color);
  --flash-message-error: var(--error-color);
  --flash-message-box-shadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);

  // Meal
  --meal-title: var(--color-global-text-opacity-8);
  --meal-info-text: var(--color-global-text-opacity-8);
  --meal-benefit-text: var(--color-global-grey-2);
  --meal-image-container-background: rgba(41, 171, 226, 0.12);
  --meal-lock-icon: var(--color-global-white);
  --meal-lock-icon-background: var(--primary-color);

  // MealChange
  --meal-change-title: var(--color-global-text-opacity-8);
  --meal-change-background: var(--quaternary-color);
  --meal-change-dot: #cccccc;
  --meal-change-dot-selected: var(--primary-color);
  --meal-change-fade-left: linear-gradient(
    to right,
    #ffffff 0%,
    rgba(255, 255, 255, 0.001) 100%
  );
  --meal-change-fade-right: linear-gradient(
    to left,
    #ffffff 0%,
    rgba(255, 255, 255, 0.001) 100%
  );

  // ChangePrompt
  --change-prompt-text: var(--color-global-text-opacity-8);
  --change-prompt-background: var(--quaternary-color);
  --change-prompt-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);

  // ChatPage
  --chat-page-header-backgroud: var(--secondary-color);
  --chat-page-header-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --chat-page-body-top-fade: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.8) 25%,
    rgba(255, 255, 255, 0.01) 100%
  );
  --chat-page-footer-border: #a6a6a6;
  --chat-page-input-text: var(--color-global-text-opacity-6);
  --chat-page-input-border: rgba(0, 0, 0, 0.32);
  --chat-page-issue-background: var(--error-color);
  --chat-page-issue-border: var(--error-color);
  --chat-page-send-icon: var(--secondary-color);

  // ChatLine
  --chat-line-author-text: rgba(0, 0, 0, 0.38);
  --chat-line-user-background: rgba(42, 157, 143, 0.1);
  --chat-line-admin-background: #f5f5f5;

  // Avatar
  --chat-avatar-user-text: var(--secondary-color);
  --chat-avatar-user-background: rgba(42, 157, 143, 0.1);
  --chat-avatar-admin-background: #f5f5f5;

  // SystemMessage
  --system-message-text: var(--color-global-text-opacity-6);

  // Testamonial
  --testamonial-header-text: var(--color-global-text-opacity-8);
  --testamonial-text: var(--color-global-text-opacity-8);
  --testamonial-link-text: var(--primary-color);

  // ShoppingList
  --shopping-list-header-icon: var(--secondary-color);
  --shopping-list-product-icon: var(--secondary-color);
  --shopping-list-subtitle-text: var(--color-global-text-opacity-3);
  --shopping-list-item-text: var(--color-global-black);
  --shopping-list-unit-text: var(--color-global-black);

  // SelectButton
  --select-buttons-selected-background: rgba(41, 171, 226, 0.12);

  // ProfilePage
  --profile-page-svg-fill: var(--secondary-color);

  // GoalBlock
  --goal-block-title-text: var(--color-global-text-opacity-8);

  // AccountBlock
  --account-block-title-text: var(--color-global-text-opacity-8);
  --account-block-link-text: var(--primary-color);

  // EditAccountPage
  --edit-account-page-border: var(--account-block-border);

  // UnsubscribeBlock
  --unsubscribe-block-link: var(--primary-color);
  --unsubscribe-block-warning-text: #08605f;

  // CheckBox
  --check-box-selected-background: rgba(41, 171, 226, 0.12);

  // MealSurvey
  --meal-survey-loading-text: var(--secondary-color);
  --meal-survey-progress-bar-title-text: var(--secondary-color);

  // MedicalAgreement
  --medical-agreement-disagree-title-text: var(--error-color);

  // OfferBanner
  --offer-banner-text: var(--quaternary-color);
  --offer-banner-background: #08605f;
  --offer-banner-boxshadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14);

  // SubTosPage
  --sub-tos-text: var(--color-global-grey-2);
  --sub-tos-subtitle-text: var(--color-global-text-opacity-8);
  --sub-tos-background: #f6f4f3;

  // Templates: Start
  // Pre_unsubscribe_v3

  // Sub_expired
  --sub-expired-thumb-up: var(--secondary-color);
  --sub-expired-thumb-down: #979797;
  --sub-expired-button-background: #fafafa;
  // Templates: End

  //SubscriptionText
  --subscription-warning-text: var(--error-color);
  --subscription-link: var(--primary-color);

  --unsubscribed-modal-bg-color: var(--primary-background-color);
  --discount-modal-bg-color: var(--primary-background-color);

  --chatline-link: var(--primary-color);

  // PostPayment
  --payment-failed-text: var(--color-global-text-opacity-8);
  --payment-failed-background: #f6f4f3;
  --payment-failed-mui-button-text: var(--color-global-text-opacity-8);
  --payment-failed-mui-button-background: #fafafa;
  --payment-failed-mui-button-background-hover: rgba(0, 0, 0, 0.04);

  // Quiz
  --quiz-title-color: var(--color-global-text-opacity-6);
  --quiz-question-color: var(--color-global-text-opacity-8);
  --quiz-selected-answer-background: rgba(255, 158, 0, 0.07);

  --objection-text: var(--color-global-grey-2);
  --objection-title: var(--color-global-text-opacity-8);
  --objection-background: var(--premium-upsell-include-image-background);
  --objection-specialist-text: var(--color-global-text-opacity-6);
  --objection-link-button: var(--primary-accent-color);
  --objection-bubble-background: var(--premium-upsell-main-title-background);

  --quiz-modal-background: var(--color-orange-2);
  --quiz-modal-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --quiz-modal-button-color: var(--color-global-text-opacity-8);

  // PremiumSubscription
  --premium-block-border: #d6d6d6;
  --premium-block-title: var(--color-global-text-opacity-8);
  --premium-block-text: var(--color-global-text-opacity-6);

  // ShoppingList Offer
  --shopping-list-offer-title: var(--color-global-text-opacity-8);
  --shopping-list-offer-title-accent: var(--secondary-color);
  --shopping-list-offer-price: var(--color-global-text-opacity-6);
  --shopping-list-offer-list-item: var(--color-global-text-opacity-8);

  --premium-upsell-timer-text: var(--secondary-color);

  // FatBuner Offer
  --fat-burner-offer-delivery: var(--secondary-color);
  --fat-burner-chat-link: var(--primary-color);
  --fat-burner-success-page-link: var(--primary-color);

  // AnnualProOffer
  --annual-pro-offer-background: #f6f4f3;
  --annual-pro-offer-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --annual-pro-offer-warning-background: #fa7e61;
  --annual-pro-offer-warning-text: rgba(255, 255, 255, 0.87);
  --annual-pro-offer-title: var(--color-global-text-opacity-8);
  --annual-pro-offer-price: var(--color-global-text-opacity-8);
  --annual-pro-offer-price-small: var(--color-global-text-opacity-6);

  // AnnualCongratsView
  --annual-pro-congrats-background: #f6f4f3;
  --annual-pro-congrats-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --annual-pro-congrats-title: var(--color-global-text-opacity-8);
  --annual-pro-congrats-text: var(--color-global-text-opacity-8);

  // ShoppingListFree
  --shopping-list-free-text: var(--color-global-text-opacity-8);
  --shopping-list-free-offer-text: var(--color-global-text-opacity-6);
  --shopping-list-free-offer-price: var(--color-global-text-opacity-6);

  // TOSLinkBlock
  --tos-link-block-divider: var(--divider-color);
  --tos-link-block-text: var(--primary-color);

  // CardSaveConfirmation
  --card-save-confirmation-background: #f6f4f3;
  --card-save-confirmation-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --card-save-confirmation-close-icon: #646464;
  --card-save-confirmation-title: var(--color-global-text-opacity-8);
  --card-save-confirmation-text: var(--color-global-text-opacity-8);

  // StepFastingCycle
  --fasting-cycle-step-title: var(--color-global-text-opacity-8);
  --fasting-cycle-step-subtitle: var(--color-global-text-opacity-6);

  // FastingCard
  --fasting-card-title: var(--color-global-text-opacity-8);
  --fasting-card-border: rgba(0, 0, 0, 0.12);
  --fasting-card-span: var(--color-global-text-opacity-8);
  --fasting-card-p: var(--color-global-text-opacity-8);
  --fasting-card-checmark: var(--primary-color);
  --fasting-card-background: var(--color-global-white);
  --fasting-card-background-selected: rgba(41, 171, 226, 0.12);

  // HelpdeskFooter
  --helpdesk-footer-text: rgba(0, 0, 0, 0.38);
  --helpdesk-footer-link: var(--primary-color);

  // ShoppingListV2
  --offer-shoppinglist-v2-bg: #d8f8ff;
  --offer-shoppinglist-v2-banner-bg: #08605f;
  --offer-shoppinglist-v2-text-secondary: #247ba0;
  --offer-shoppinglist-v2-text-primary: #ffffff;
  --offer-shoppinglist-v2-text-base: rgba(0, 0, 0, 0.87);
  --offer-shoppinglist-v2-banner-text: #d8f8ff;
  --offer-shoppinglist-v2-text-price: rgba(0, 0, 0, 0.6);
  --offer-shoppinglist-v2-percentage: #29abe2;
  --offer-shoppinglist-v2-badge-text: #264653;
  --offer-shoppinglist-v2-photo-text: #247ba0;
  --offer-shoppinglist-v2-text-highlighted: #fa7e61;

  // PhysicalProductUnsubscribed
  --physical-unsub-bg-color: #fafafa;
  --physical-unsub-box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2),
    0px 9px 46px 8px rgba(0, 0, 0, 0.12), 0px 24px 38px 3px rgba(0, 0, 0, 0.14);
  --physical-unsub-title-color: rgba(0, 0, 0, 0.87);
  --physical-unsub-text-color: rgba(0, 0, 0, 0.87);
  --physical-unsub-link-text-color: rgba(0, 0, 0, 0.87);
  --physical-unsub-link-color: #29abe2;

  // UnsubscribeV8
  // Recap
  --recap-bg: #132730;
  --recap-card-container-bg: linear-gradient(
    180deg,
    rgba(29, 65, 77, 0) 26.04%,
    #12262f 39.06%
  );
  --recap-card-container-color: #fff;
  --recap-card-container-bs: rgba(42, 116, 157, 0.5);
  --recap-wrapper-bg: linear-gradient(
    180deg,
    rgba(29, 77, 71, 0) 190px,
    #132730 357px
  );
  --recap-footer-container-bg: linear-gradient(
    180deg,
    rgba(29, 65, 77, 0) 0%,
    #132730 28.65%
  );
  // Recap - Title
  --recap-title-color: #fff;
  // Recap - BackButton
  --recap-backbtn-text-color: #666666;
  --recap-backbtn-bg-color: #fff;
  --recap-backbtn-bs: 0px -4px 24px 0px rgba(102, 102, 102, 0.7);
  // Recap - NextButton
  --recap-nextbtn-text-color: #fff;
  --recap-nextbtn-bg-color: #000;
  --recap-nextbtn-hover-text-color: #b0b0b0;
  // Recap - Basic
  --recap-basic-bg-color: #fff;
  --recap-basic-bs: 0px 4px 24px 0px rgba(255, 255, 255, 0.16);
  --recap-basic-title-color: hsla(198, 76%, 42%, 1);
  --recap-basic-subtitle-color: hsla(198, 76%, 42%, 1);
  --recap-basic-text-color: #000;
  --recap-basic-accent-color: hsla(198, 76%, 42%, 1);
  --recap-basic-secondary-accent-color: #a12027;
  // Recap - BasicLinked
  --recap-basiclinked-bg-color: #fff;
  --recap-basiclinked-bs: 0px 4px 24px 0px rgba(255, 255, 255, 0.16);
  --recap-basiclinked-title-color: hsla(198, 76%, 42%, 1);
  --recap-basiclinked-subtitle-color: hsla(198, 76%, 42%, 1);
  --recap-basiclinked-text-color: #000;
  --recap-basiclinked-accent-color: hsla(198, 76%, 42%, 1);
  --recap-basiclinked-forwardbtn-color: hsla(198, 76%, 42%, 1);
  --recap-basiclinked-forwardbtn-bg-color: #fff;
  --recap-basiclinked-forwardbtn-border-color: hsla(198, 76%, 42%, 1);
  --recap-basiclinked-forwardbtn-hover-border-color: hsla(198, 76%, 30%);
  --recap-basiclinked-forwardbtn-hover-color: hsla(198, 76%, 30%);
  // Recap - Journey
  --recap-journey-bg-color: #fff;
  --recap-journey-bs: 0px 4px 24px 0px rgba(255, 255, 255, 0.16);
  --recap-journey-title-color: #000;
  --recap-journey-hero-bg-color: hsla(198, 76%, 82%, 1);
  --recap-journey-name-text-color: #000;
  --recap-journey-age-text-color: #666666;
  --recap-journey-user-weight-text-color: #666666;
  --recap-journey-weight-text-color: #666666;
  --recap-journey-accent-color: hsla(198, 76%, 42%, 1);
  // Recap - Results
  --recap-results-title-color: #fff;
  --recap-results-text-color: #fff;
  --recap-results-graph-bg-color: #fff;
  --recap-results-graph-title-color: #000;
  --recap-results-accent-color: hsla(198, 76%, 42%, 1);
  --recap-results-footer-bg: linear-gradient(
    180deg,
    rgba(18, 47, 44, 0) 0%,
    #132730 28.65%
  );
  // Recap - PercentageSpinner
  --recap-percentagespinner-bg-color: conic-gradient(
    hsla(198, 76%, 52%, 1) 3.6deg,
    #d9d9d9 0deg
  );
  --recap-percentagespinner-before-bg-color: #132730;
  --recap-percentagespinner-text-color: #fff;
  // Recap - Graph
  --recap-graph-weights-color: hsla(198, 76%, 42%, 1);
  --recap-graph-weightleft-bg-color: hsla(198, 76%, 95%, 1);
  --recap-graph-weightleft-color: #999;
  --recap-graph-months-bg-color: hsla(198, 76%, 95%, 1);
  --recap-graph-months-color: #999;

  // UnsubscribeReason
  --unsubscribe-reason-title: #03cea4;
  --unsubscribe-reason-subtitle: #666;
  --unsubscribe-reason-infoblock-bg: #f7f7f7;
  --unsubscribe-reason-infoblock-border: #bbb;
  --unsubscribe-reason-infotext: #000;
  --unsubscribe-reason-secondary-title: #000;
  --unsubscribe-reason-listitem-text: #000;
  --unsubscribe-reason-listitem-bg: #e9f6fc;
  --unsubscribe-reason-radiobutton-default: #bbb;
  --unsubscribe-reason-button-text: #fff;
  --unsubscribe-reason-button-bg: #29abe2;
  --unsubscribe-reason-button-boxshadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --unsubscribe-reason-button-bg-hover: #1e86b2;
  --unsubscribe-reason-button-boxshadow-hover: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --unsubscribe-reason-button-bg-active: #135f80;
  --unsubscribe-reason-button-boxshadow-active: 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --unsubscribe-reason-button-disabled-bg: #aedff4;
  --unsubscribe-reason-button-disabled-text: rgba(255, 255, 255, 0.8);
  --unsubscribe-reason-spinner: rgba(255, 255, 255, 0.8);
  --unsubscribe-reason-length-text: #666;
  --unsubscribe-reason-max-capacity: #a12027;

  // ExpressPlan program
  --express-plan-text: #000;
  --express-plan-bubble-text: #fff;
  --express-plan-bubble-bg: #29abe2;
  --express-plan-carouesel-bg: #dbf1fa;
  --express-plan-footer-bg: #dbf1fa;
  --express-plan-carousel-item-bg: #f4f9f8;
  --express-plan-carousel-item-title: #222;
  --express-plan-carousel-item-text: #444;
  --express-plan-carousel-item-link: #228176;
  --express-plan-carousel-left-gradient: linear-gradient(
    90deg,
    #dbf1fa 0%,
    rgba(219, 241, 250, 0) 82.42%
  );
  --express-plan-carousel-right-gradient: linear-gradient(
    270deg,
    #dbf1fa 0%,
    rgba(219, 241, 250, 0) 82.42%
  );
  --express-plan-carousel-item-inactive-gradient: linear-gradient(
    to top,
    #f4f9f8 32.14%,
    rgba(244, 249, 248, 0) 100%
  );
  --express-plan-carousel-indicator-inactive: #fff;
  --express-plan-carousel-indicator-active: #29abe2;

  // ShoppingListBlock
  --shopping-list-block-border: #d6d6d6;
  --shopping-list-title: rgba(0, 0, 0, 0.87);
  --shopping-list-text: rgba(0, 0, 0, 0.6);

  // PreUnsubscribeShoppinglist
  --preunsubscribe-shoppinglist-background: #fff;
  --preunsubscribe-shoppinglist-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --preunsubscribe-shoppinglist-exit-icon-fill: #646464;
  --preunsubscribe-shoppinglist-text-color: #2e3033;

  // DefaultSuccess screen
  --default-success-bg-shadow: rgba(0, 0, 0, 0.6);
  --default-success-bg: #f6f4f3;
  --default-success-bs: 0px 12px 16px 0px rgba(104, 104, 104, 0.16);
  --default-success-title: #000;
  --default-success-text: #464646;

  // ChatBlock
  --chat-block-border: #d6d6d6;
  --chat-block-title: rgba(0, 0, 0, 0.87);
  --chat-block-text: rgba(0, 0, 0, 0.6);

  // Preunsubscribe Chat
  --preunsubscribe-chat-background: #fff;
  --preunsubscribe-chat-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --preunsubscribe-chat-exit-icon-fill: #646464;
  --preunsubscribe-chat-text-color: #2e3033;

  // Pause subscription Chat
  --pause-subscription-accent: #1a8cbd;
}

body.healthgenom {
  // Primary/Secondary colors
  --primary-background-color: var(--color-global-white);
  --primary-title-color: rgba(0, 0, 0, 0.87);
  --primary-text-color: rgba(0, 0, 0, 0.6);
  --primary-accent-color: #ffca36;

  // Slider
  --slider-span-lable-color: rgba(0, 0, 0, 0.6);

  // Colors
  --color-keto-accent: #ffca36;
  --color-keto-accent-2: #264653;
  --color-keto-accent-opacity: rgba(98, 2, 238, 0);
  --color-keto-accent-opacity-2: rgba(187, 187, 187, 0.12);
  --color-keto-accent-opacity-3: rgba(42, 157, 143, 0.2);
  --color-keto-error: #b00020;
  --color-keto-divider: #d6d6d6;

  --color-keto-grey: #bbbbbb;
  --color-keto-grey-2: #909090;
  --color-keto-grey-3: #646464;
  --color-keto-grey-4: #979797;
  --color-keto-grey-5: #848789;
  --color-keto-gray-6: #dcefec;
  --color-keto-gray-7: #f0f4f4;

  --color-keto-white: #f6f4f3;
  --color-keto-white-2: #f5f5f5;
  --color-keto-white-3: #f8f8f8;

  // MODALS: Start
  // Modal Modal.module.
  --modal-background: var(--color-global-white);

  // Modal - DiaryUnskippable
  --diary-text: var(--color-global-text-opacity-8);
  --diary-background: var(--color-global-white);
  --diary-event: #b2e2ed;
  --diary-calendar-background: #fff2c0;
  --diary-calendar-boxshadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
  --diary-calendar-border: #d88d28;

  // Moadal - PlanUpdate
  --plan-update-title-text: var(--color-global-text-opacity-8);
  --plan-update-text: var(--color-global-text-opacity-8);
  --plan-update-background: var(--color-global-white);
  --plan-update-boxshadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --plan-update-close-icon-active: var(--color-keto-grey-3);

  // Modal - CookieSettings
  --cookie-settings-background: var(--color-global-white);
  --cookie-settings-boxshadow: 0 -11px 15px -7px rgba(0, 0, 0, 0.2),
    0 -9px 46px 8px rgba(0, 0, 0, 0.12), 0 -24px 38px 3px rgba(0, 0, 0, 0.14);
  --cookie-settings-boxshadow-mobile: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --cookie-settings-link-text: var(--color-keto-accent);

  // Modal - Testimonial
  --testimonial-title-text: var(--color-global-text-opacity-8);
  --testimonial-text: var(--color-global-text-opacity-8);
  --testimonial-background: var(--color-global-white);
  --testimonial-boxshadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --testimonial-close-icon-active: var(--color-keto-grey-3);
  // Modal - Pregnant
  --pregnant-subtext: var(--color-global-text-opacity-8);

  --unsubscribev3-modal-background: #f6f4f3;
  --unsubscribev3-modal-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --unsubscribev3-modal-close-svg-hover: #ced5da;
  --unsubscribev3-modal-title: var(--color-black-opacity8);
  --unsubscribev3-modal-subtitle: var(--color-black-opacity8);
  --unsubscribev3-modal-paragraph: var(--color-black-opacity8);
  --unsubscribev3-modal-title-warning: var(--global-warning-color);
  --unsubscribev3-modal-fullprice-warning: var(--color-keto-error);

  // MODALS: End

  // Navbar
  --navbar-background: linear-gradient(268deg, #eec151, #d88d28 85%);
  --navbar-svg: rgba(255, 255, 255, 0.54);

  // Calendar
  --primary-calendar-header: linear-gradient(268deg, #eec151, #d88d28 85%);
  --primary-calendar-button: var(--color-keto-accent);
  --primary-calendar-event-background: #b2e2ed;
  --primary-calendar-event-text: #ffcc70;
  --primary-calendar-selected-background: #fff2c0;
  --primary-calendar-selected-boxshadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);
  --primary-calendar-resting-background: #fafafa;
  --primary-calendar-resting-background-hover: rgba(255, 242, 192, 0.6);
  --primary-calendar-resting-border: rgba(0, 0, 0, 0.12);
  --primary-calendar-resting-border-hover: rgba(0, 0, 0, 0.24);
  --primary-calendar-past-bg-image: url('~assets/images/program/calendar/healthgenom/past/resting.svg');
  --primary-calendar-past-bg-image-hover: url('~assets/images/program/calendar/healthgenom/past/hover.svg');
  --primary-calendar-past-bg-image-selected: url('~assets/images/program/calendar/healthgenom/past/selected.svg');
  --primary-calendar-mobile-background-left: linear-gradient(
    to left,
    rgba(255, 255, 255, 0) 1%,
    rgba(255, 255, 255, 0.99) 100%
  );
  --primary-calendar-mobile-background-right: linear-gradient(
    to right,
    rgba(255, 255, 255, 0) 1%,
    rgba(255, 255, 255, 0.99) 100%
  );
  --secondary-calendar-header: #b2e2ed;

  // TOS
  --tos-button: var(--color-keto-grey);

  // Loading
  --loading-text: #d88d28;

  // Flash message
  --flash-message-text: #464646;
  --flash-message-text-error: #fff;
  --flash-message-error: var(--color-keto-error);
  --flash-message-success: var(--color-keto-accent);

  // Button
  --button-text: #d88d28;
  --button-text-hover: #d88d28;
  --button-border: #d88d28;
  --button-border-hover: #d88d28;
  --button-background: rgba(98, 2, 238, 0);
  --button-background-hover: rgba(255, 202, 54, 0.12);
  --button-text-active: #d88d28;
  --button-border-active: var(--primary-accent-color);
  --button-background-active: rgba(255, 202, 54, 0.36);
  --secondary-button-text: var(--color-keto-accent);
  --secondary-button-border: rgba(42, 157, 143, 0.5);
  --secondary-button-background-hover: rgba(42, 157, 143, 0.04);

  --tertiary-button-contained-text: #464646;
  --tertiary-button-contained-text-hover: #464646;
  --tertiary-button-contained-text-active: #fff;
  --tertiary-button-contained-background: #ffca36;
  --tertiary-button-contained-background-hover: #e2b12a;
  --tertiary-button-contained-background-active: #a47c0d;
  --tertiary-button-contained-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --tertiary-button-contained-shadow-hover: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);

  // Language dropdown
  --language-dropdown-border: rgba(0, 0, 0, 0.23);
  --language-dropdown-background: var(--color-keto-white);
  --language-dropdown-background-hover: #433e3f;

  // TextInput
  --text-input-backgrond: transparent;
  --text-input-text: #464646;
  --text-input-border: #979797;
  --text-input-border-hover: #433e3f;
  --text-input-border-active: #ffca36;
  --text-input-label-focused-color: #f08700;

  // LinkButton
  --link-button-text: #d88d28;
  --link-button-text-hover: #d88d28;
  --link-button-border: #d88d28;
  --link-button-border-hover: #d88d28;
  --link-button-background: rgba(98, 2, 238, 0);
  --link-button-background-hover: rgba(255, 202, 54, 0.12);
  --link-button-background-text-active: #d88d28;
  --link-button-background-border-active: #d88d28;
  --link-button-background-background-active: rgba(255, 202, 54, 0.36);

  // LinkButton-shopping
  --link-button-shopping-text: var(--color-keto-accent);

  // SuccessIcon
  --success-icon-background: #43a8ba;

  // SubmitButton
  --primary-submit-button-text: #464646;
  --primary-submit-button-text-active: #fff;
  --primary-submit-button-background: var(--primary-accent-color);
  --primary-submit-button-background-loading: #ced5da;
  --primary-submit-button-background-hover: #e2b12a;
  --primary-submit-button-background-active: #a47c0d;
  --primary-submit-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --primary-submit-box-shadow-hover: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --primary-submit-box-shadow-active: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);

  --secondary-submit-button-text: #d88d28;
  --secondary-submit-button-text-hover: #d88d28;
  --secondary-submit-button-border: #d88d28;
  --secondary-submit-button-border-hover: #d88d28;
  --secondary-submit-button-background: rgba(98, 2, 238, 0);
  --secondary-submit-button-background-hover: rgba(255, 202, 54, 0.12);
  --secondary-submit-button-text-active: #d88d28;
  --secondary-submit-button-border-active: #d88d28;
  --secondary-submit-button-background-active: rgba(255, 202, 54, 0.36);

  --tertiary-submit-button-text: #d88d28;

  // Meal
  --meal-title: var(--color-global-text-opacity-8);
  --meal-info-text: var(--color-global-text-opacity-8);
  --meal-benefit-text: var(--color-global-grey-2);
  --meal-image-container-background: rgba(42, 157, 143, 0.04);
  --meal-lock-icon: var(--color-global-black);
  --meal-lock-icon-background: #b2e2ed;

  // MealChange
  --meal-change-title: var(--color-global-text-opacity-8);
  --meal-change-background: var(--color-keto-white);
  --meal-change-dot: #ccc;
  --meal-change-dot-selected: #2884aa;
  --meal-change-fade-left: linear-gradient(
    to right,
    #ffffff 0%,
    rgba(255, 255, 255, 0.001) 100%
  );
  --meal-change-fade-right: linear-gradient(
    to left,
    #ffffff 0%,
    rgba(255, 255, 255, 0.001) 100%
  );

  // ChangePrompt
  --change-prompt-text: var(--color-global-text-opacity-8);
  --change-prompt-background: var(--color-keto-white);
  --change-prompt-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);

  // ChatPage
  --chat-page-header-backgroud: #43a8ba;
  --chat-page-footer-backgroud: #f6f4f3;
  --chat-page-header-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --chat-page-body-top-fade: linear-gradient(
    180deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(255, 255, 255, 0.8) 25%,
    rgba(255, 255, 255, 0.01) 100%
  );
  --chat-page-footer-border: #a6a6a6;
  --chat-page-input-text: var(--color-global-text-opacity-6);
  --chat-page-input-border: rgba(0, 0, 0, 0.32);
  --chat-page-issue-background: var(--color-keto-error);
  --chat-page-issue-border: var(--color-keto-error);
  --chat-page-send-icon: var(--color-keto-accent);

  // ChatLine
  --chat-line-author-text: rgba(0, 0, 0, 0.38);
  --chat-line-user-background: rgba(255, 202, 54, 0.2);
  --chat-line-admin-background: #f8f8f8;
  --chat-line-timestamp: var(--color-keto-grey-5);

  // Avatar
  --chat-avatar-user-text: var(--color-keto-accent-2);
  --chat-avatar-user-background: rgba(42, 157, 143, 0.1);
  --chat-avatar-admin-background: var(--color-keto-white-2);

  // SystemMessage
  --system-message-text: var(--color-global-text-opacity-6);

  // Testamonial
  --testamonial-header-text: var(--color-global-text-opacity-8);
  --testamonial-text: var(--color-global-text-opacity-8);
  --testamonial-link-text: var(--color-keto-accent);

  // ShoppingList
  --shopping-list-header-icon: #2884aa;
  --shopping-list-product-icon: #2884aa;
  --shopping-list-subtitle-text: var(--color-global-text-opacity-3);
  --shopping-list-item-text: var(--color-global-black);
  --shopping-list-unit-text: var(--color-global-black);

  // SelectButton
  --select-buttons-selected-background: rgba(255, 202, 54, 0.15);

  // ProfilePage
  --profile-page-svg-fill: #2884aa;

  // GoalBlock
  --goal-block-title-text: var(--color-global-text-opacity-8);

  // AccountBlock
  --account-block-title-text: var(--color-global-text-opacity-8);
  --account-block-link-text: var(--color-keto-accent);

  // EditAccountPage
  --edit-account-page-border: var(--account-block-border);

  // UnsubscribeBlock
  --unsubscribe-block-link: var(--color-keto-accent);
  --unsubscribe-block-warning-text: var(--color-keto-error);

  // CheckBox
  --check-box-selected-background: rgba(255, 202, 54, 0.15);

  // MealSurvey
  --meal-survey-loading-text: var(--color-keto-accent);
  --meal-survey-progress-bar-title-text: var(--color-keto-accent-2);

  // MedicalAgreement
  --medical-agreement-disagree-title-text: var(--color-keto-error);

  // OfferBanner
  --offer-banner-text: var(--color-global-white);
  --offer-banner-background: #43a8ba;
  --offer-banner-boxshadow: 0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 1px 18px 0 rgba(0, 0, 0, 0.12), 0 6px 10px 0 rgba(0, 0, 0, 0.14);

  // SubTosPage
  --sub-tos-text: var(--color-global-grey-2);
  --sub-tos-subtitle-text: var(--color-global-text-opacity-8);
  --sub-tos-background: #f6f4f3;

  // Templates: Start
  // Pre_unsubscribe_v3

  // Sub_expired
  --sub-expired-thumb-up: #43a8ba;
  --sub-expired-thumb-down: var(--color-keto-grey-4);
  --sub-expired-button-background: #fafafa;
  // Templates: End

  //SubscriptionText
  --subscription-warning-text: var(--global-warning-color);
  --subscription-link: #2884aa;

  --unsubscribed-modal-bg-color: var(--primary-background-color);
  --discount-modal-bg-color: var(--primary-background-color);

  --chatline-link: #2a9d8f;

  // PostPayment
  --payment-failed-text: var(--color-global-text-opacity-8);
  --payment-failed-background: #f6f4f3;
  --payment-failed-mui-button-text: var(--color-global-text-opacity-8);
  --payment-failed-mui-button-background: #fafafa;
  --payment-failed-mui-button-background-hover: rgba(0, 0, 0, 0.04);

  // Quiz
  --quiz-title-color: var(--color-global-text-opacity-6);
  --quiz-question-color: var(--color-global-text-opacity-8);
  --quiz-selected-answer-background: rgba(255, 158, 0, 0.07);

  --objection-text: var(--color-global-grey-2);
  --objection-title: var(--color-global-text-opacity-8);
  --objection-background: var(--premium-upsell-include-image-background);
  --objection-specialist-text: var(--color-global-text-opacity-6);
  --objection-link-button: var(--primary-accent-color);
  --objection-bubble-background: var(--premium-upsell-main-title-background);

  --quiz-modal-background: var(--color-orange-2);
  --quiz-modal-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --quiz-modal-button-color: var(--color-global-text-opacity-8);

  // PremiumSubscription
  --premium-block-border: var(--color-keto-divider);
  --premium-block-title: var(--color-global-text-opacity-8);
  --premium-block-text: var(--color-global-text-opacity-6);

  // ShoppingList Offer
  --shopping-list-offer-title: var(--color-global-text-opacity-8);
  --shopping-list-offer-title-accent: #43a8ba;
  --shopping-list-offer-price: var(--color-global-text-opacity-6);
  --shopping-list-offer-list-item: var(--color-global-text-opacity-8);

  --premium-upsell-timer-text: #2884aa;

  // FatBuner Offer
  --fat-burner-offer-delivery: var(--primary-accent-color);
  --fat-burner-chat-link: var(--primary-accent-color);
  --fat-burner-success-page-link: var(--primary-accent-color);

  // AnnualProOffer
  --annual-pro-offer-background: #f6f4f3;
  --annual-pro-offer-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --annual-pro-offer-warning-background: #ff6542;
  --annual-pro-offer-warning-text: rgba(255, 255, 255, 0.87);
  --annual-pro-offer-title: var(--color-global-text-opacity-8);
  --annual-pro-offer-price: var(--color-global-text-opacity-8);
  --annual-pro-offer-price-small: var(--color-global-text-opacity-6);

  // AnnualCongratsView
  --annual-pro-congrats-background: #f6f4f3;
  --annual-pro-congrats-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --annual-pro-congrats-title: var(--color-global-text-opacity-8);
  --annual-pro-congrats-text: var(--color-global-text-opacity-8);

  // ShoppingListFree
  --shopping-list-free-text: var(--color-global-text-opacity-8);
  --shopping-list-free-offer-text: var(--color-global-text-opacity-6);
  --shopping-list-free-offer-price: var(--color-global-text-opacity-6);

  // TOSLinkBlock
  --tos-link-block-divider: var(--color-keto-divider);
  --tos-link-block-text: var(--color-keto-accent);

  // CardSaveConfirmation
  --card-save-confirmation-background: #f6f4f3;
  --card-save-confirmation-box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
    0 9px 46px 8px rgba(0, 0, 0, 0.12), 0 24px 38px 3px rgba(0, 0, 0, 0.14);
  --card-save-confirmation-close-icon: #646464;
  --card-save-confirmation-title: var(--color-global-text-opacity-8);
  --card-save-confirmation-text: var(--color-global-text-opacity-8);

  // HelpdeskFooter
  --helpdesk-footer-text: #f6f4f3;
  --helpdesk-footer-link: var(--primary-accent-color);

  // ShoppingListV2
  --offer-shoppinglist-v2-bg: #fff4d7;
  --offer-shoppinglist-v2-banner-bg: #d88d28;
  --offer-shoppinglist-v2-text-secondary: #f57b0d;
  --offer-shoppinglist-v2-text-primary: #ffffff;
  --offer-shoppinglist-v2-text-base: rgba(0, 0, 0, 0.87);
  --offer-shoppinglist-v2-banner-text: #fff4d7;
  --offer-shoppinglist-v2-text-price: rgba(0, 0, 0, 0.6);
  --offer-shoppinglist-v2-percentage: #f57b0d;
  --offer-shoppinglist-v2-badge-text: #544e61;
  --offer-shoppinglist-v2-photo-text: #544e61;
  --offer-shoppinglist-v2-text-highlighted: #544e61;

  // UnsubscribeV8
  // Recap
  --recap-bg: #132730;
  --recap-card-container-bg: linear-gradient(
    180deg,
    rgba(29, 65, 77, 0) 26.04%,
    #12262f 39.06%
  );
  --recap-card-container-color: #fff;
  --recap-card-container-bs: rgba(42, 116, 157, 0.5);
  --recap-wrapper-bg: linear-gradient(
    180deg,
    rgba(29, 77, 71, 0) 190px,
    #132730 357px
  );
  --recap-footer-container-bg: linear-gradient(
    180deg,
    rgba(29, 65, 77, 0) 0%,
    #132730 28.65%
  );
  // Recap - Title
  --recap-title-color: #fff;
  // Recap - BackButton
  --recap-backbtn-text-color: #666666;
  --recap-backbtn-bg-color: #fff;
  --recap-backbtn-bs: 0px -4px 24px 0px rgba(102, 102, 102, 0.7);
  // Recap - NextButton
  --recap-nextbtn-text-color: #fff;
  --recap-nextbtn-bg-color: #000;
  --recap-nextbtn-hover-text-color: #b0b0b0;
  // Recap - Basic
  --recap-basic-bg-color: #fff;
  --recap-basic-bs: 0px 4px 24px 0px rgba(255, 255, 255, 0.16);
  --recap-basic-title-color: #2884aa;
  --recap-basic-subtitle-color: #2884aa;
  --recap-basic-text-color: #000;
  --recap-basic-accent-color: #2884aa;
  --recap-basic-secondary-accent-color: #a12027;
  // Recap - BasicLinked
  --recap-basiclinked-bg-color: #fff;
  --recap-basiclinked-bs: 0px 4px 24px 0px rgba(255, 255, 255, 0.16);
  --recap-basiclinked-title-color: #2884aa;
  --recap-basiclinked-subtitle-color: #2884aa;
  --recap-basiclinked-text-color: #000;
  --recap-basiclinked-accent-color: #2884aa;
  --recap-basiclinked-forwardbtn-color: #2884aa;
  --recap-basiclinked-forwardbtn-bg-color: #fff;
  --recap-basiclinked-forwardbtn-border-color: #2884aa;
  --recap-basiclinked-forwardbtn-hover-border-color: hsla(198, 76%, 30%);
  --recap-basiclinked-forwardbtn-hover-color: hsla(198, 76%, 30%);
  // Recap - Journey
  --recap-journey-bg-color: #fff;
  --recap-journey-bs: 0px 4px 24px 0px rgba(255, 255, 255, 0.16);
  --recap-journey-title-color: #000;
  --recap-journey-hero-bg-color: #fff4d7;
  --recap-journey-name-text-color: #000;
  --recap-journey-age-text-color: #666666;
  --recap-journey-user-weight-text-color: #666666;
  --recap-journey-weight-text-color: #666666;
  --recap-journey-accent-color: #2884aa;
  // Recap - Results
  --recap-results-title-color: #fff;
  --recap-results-text-color: #fff;
  --recap-results-graph-bg-color: #fff;
  --recap-results-graph-title-color: #000;
  --recap-results-accent-color: hsla(198, 76%, 42%, 1);
  --recap-results-footer-bg: linear-gradient(
    180deg,
    rgba(18, 47, 44, 0) 0%,
    #132730 28.65%
  );
  // Recap - PercentageSpinner
  --recap-percentagespinner-bg-color: conic-gradient(
    hsla(198, 76%, 52%, 1) 3.6deg,
    #d9d9d9 0deg
  );
  --recap-percentagespinner-before-bg-color: #132730;
  --recap-percentagespinner-text-color: #fff;
  // Recap - Graph
  --recap-graph-weights-color: hsla(198, 76%, 42%, 1);
  --recap-graph-weightleft-bg-color: hsla(198, 76%, 95%, 1);
  --recap-graph-weightleft-color: #999;
  --recap-graph-months-bg-color: hsla(198, 76%, 95%, 1);
  --recap-graph-months-color: #999;

  // UnsubscribeReason
  --unsubscribe-reason-title: #2884aa;
  --unsubscribe-reason-subtitle: #666;
  --unsubscribe-reason-infoblock-bg: #f7f7f7;
  --unsubscribe-reason-infoblock-border: #bbb;
  --unsubscribe-reason-infotext: #000;
  --unsubscribe-reason-secondary-title: #000;
  --unsubscribe-reason-listitem-text: #000;
  --unsubscribe-reason-listitem-bg: #f5eacd;
  --unsubscribe-reason-radiobutton-default: #bbb;
  --unsubscribe-reason-button-text: #464646;
  --unsubscribe-reason-button-bg: #ffca36;
  --unsubscribe-reason-button-boxshadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --unsubscribe-reason-button-bg-hover: #e2b12a;
  --unsubscribe-reason-button-boxshadow-hover: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --unsubscribe-reason-button-bg-active: #a47c0d;
  --unsubscribe-reason-button-boxshadow-active: 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --unsubscribe-reason-button-disabled-bg: #ffe59e;
  --unsubscribe-reason-button-disabled-text: rgba(70, 70, 70, 0.6);
  --unsubscribe-reason-spinner: rgba(70, 70, 70, 0.6);
  --unsubscribe-reason-length-text: #666;
  --unsubscribe-reason-max-capacity: #a12027;
}

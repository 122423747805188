.new_password
  &__title
    font:
      size: 22px
      weight: 500
    color: $dark-text
    text-align: center
    margin-bottom: 15px

  &__sub_title
    font:
      size: 16px
      weight: 500
    color: $dark-text
    text-align: center
    margin-top: 0

    &--email
      color: $blue
      margin-left: 5px

.profile_page
  width: 100%
  overflow: hidden

  .language-dropdown
    margin-top: 30px

  .sub_tos
    max-height: 700px
    height: calc(100vh - 55px)
    display: flex
    flex-direction: column

    &__text
      flex: 1
    &__btns
      margin-top: 10px
    &__title
      font:
        size: 22px
        weight: 500
      color: $dark-text
      text-align: center
      margin-bottom: 15px

    &__sub_title
      font:
        size: 16px
        weight: 500
      color: $dark-text
      text-align: center
      margin-top: 0


  &__container
    position: relative
    width: 450px
    margin: 0 auto 30px auto
    animation:
      name: fade-in-up
      duration: 0.3s
      timing-function: ease-in-out

    &--user, &--auth, &--plan, &--edit, &--subscription
      padding-bottom: 25px
      padding-top: 30px
      border-bottom: 1px solid $grey

      .form__submit_block
        margin-top: 30px

    &--without-border
      padding-bottom: 25px
      padding-top: 30px

      .form__submit_block
        margin-top: 30px

    &__title
      color: $dark-text
      font:
        size: 18px
        weight: 600
      margin: 0 0 30px 0
      text-align: center

    &__info
      dislay: table
      margin-bottom: 20px

      &--left, &--right
        display: table-cell
        vertical-align: middle

      &--right
        line-height: 1px

      &--left
        padding-left: 13px
        [dir="rtl"] &
          padding-right: 13px
          padding-left: 0px


      &__img
        width: 24px

      &__label
        display: block
        color: $dark-text
        font:
          size: 14px
          weight: normal
        margin-bottom: 3px

      &__value
        display: block
        color: $dark-grey
        font:
          size: 16px
          weight: 600

.reg
  &__progress
    text-align: center

    &__bubble
      display: inline-block
      background: var(--color-white)
      width: 12px
      height: 12px
      border-radius: 50%
      border: 2px solid $blue
      margin: 0 7px
      transition: all $animation-speed $animation-type

      &.active
        background: $blue

@media(max-width: $mobile)
  .profile_page
    width: 100%
    padding-bottom: calc(env(safe-area-inset-bottom) + 60px)

    &__container
      width: 100%
      padding: 0 10px
      margin: 0 auto 50px auto

.wrapper {
  flex: 1;
  margin-bottom: 16px;
}

.button {
  width: 100%;
  font-size: 14.3px !important;
  font-weight: 500 !important;
  letter-spacing: 1.25px !important;
}

.premiumUpsellAccept > button > span {
  padding: 15px 15px 13px 15px !important;
  line-height: 1.12;
  letter-spacing: 1.25px;
  font-weight: 500;
}

.premiumUpsellDecline > button > span {
  padding: 15px 8px 13px 8px !important;
  line-height: 1.12;
  letter-spacing: 1.25px;
  font-weight: 500;
}

.compactButton > button {
  padding: 8px 16px !important;
}

.compactButton > button > span {
  line-height: 1.12 !important;
}

.button {
  color: var(--color-blue);
  font-size: 14.3px !important;
  font-weight: 500 !important;
  letter-spacing: 1.25px !important;

  &:hover {
    text-decoration: underline;
  }
}

.right {
  composes: button;

  align-self: flex-end;
}

.contained {
  composes: button;

  &:hover {
    color: var(--color-black);
  }
}

.sameHeight {
  min-height: 100%;
}

.renew_form
  position: relative
  width: 420px
  margin: 50px auto 0 auto
  background: var(--color-white)
  border-radius: 6px
  padding: 15px 50px
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.2)
  animation:
    name: fade-in-up
    duration: 0.5s
    timing-function: ease-in-out

  &.success
    padding-bottom: 50px

  &__title
    font:
      size: 22px
      weight: 500
    color: $dark-text
    text-align: center

  &__text
    font-size: 14px
    font-weight: 500
    color: var(--color-grey-3)
    text-align: center
    margin: 0

@media(max-width: 458px)
  .renew_form
    margin-top: 15px
    width: 350px
    padding: 15px

@media(max-width: 390px)
  .renew_form
    width: 100%
    padding: 15px

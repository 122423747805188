.loading_progress
  padding: 30px 10px 0 10px

  &__bar
    position: relative
    background: transparent
    max-width: 320px
    width: 100%
    height: 10px
    margin: 0 auto
    border: 2px solid $green
    border-radius: 10px
    overflow: hidden

    &--fill
      position: absolute
      top: 0
      left: 0
      height: 6px
      width: 0
      background: $green
      z-index: 3
      transition: all $animation-speed $animation-type

  &__text
    font:
      size: 14px
      weight: normal
    text-align: center
    color: $green
    margin-bottom: 5px

.footerContainer {
  display: flex;
  width: 100%;
  padding: 16px 24px 24px 24px;
  flex-direction: column;
  align-items: center;
  gap: 24px;
  flex-shrink: 0;
  background: var(--workouts-in-progress-bg);

  @media (max-width: 475px) {
    margin-top: -20px;
    border-radius: 12px;
    z-index: 99;
  }
}

.content {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  text-align: center;
}

.titleContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 6px;
  text-align: center;
}

.title {
  color: var(--workouts-in-progress-content-title);
  margin: 0;
  font-size: 20px;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.5px;
  max-width: 275px;
}

.buttonNormalize {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  text-align: inherit;
  font: inherit;
  border-radius: 0;
  appearance: none;
}

.infoButton {
  width: 18px;
  height: 18px;
}

.infoButton:hover {
  cursor: pointer;
}

.infoMark {
  object-fit: cover;
  width: 18px;
  height: 18px;
  width: 100%;
}

.periodiocityTitle {
  color: var(--workouts-in-progress-periodicity-title);
  margin: 0;
  margin-top: 2px;
  font-size: 82px;
  font-style: normal;
  font-weight: 700;
  line-height: 88px;
  letter-spacing: 0.5px;
}

.repsSubtitile {
  margin: 0;
  margin-top: 2px;
  color: var(--workouts-in-progress-reps-subtitle);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;
  text-transform: uppercase;
}

.skipButton {
  margin-top: 16px;
  display: flex;
  height: 40px;
  padding: 4px 16px 4px 12px;
  align-items: center;
  gap: 6px;
  border-radius: 29px;
  border: 1px solid var(--workouts-in-progress-skip-border);
  background: var(--workouts-in-progress-skip-bg);
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.5px;
  color: var(--workouts-in-progress-skip-text);
  text-transform: capitalize;
}

.skipButton:hover {
  background: rgba(182, 182, 182, 0.2);
  transition: 0.2s ease-in-out;
  cursor: pointer;
}

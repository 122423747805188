.wrapper {
  flex: 1;
  margin-bottom: 16px;
}

.button {
  width: 100%;
  font-size: 14.3px !important;
  font-weight: 500 !important;
  letter-spacing: 1.25px !important;
}

.premiumUpsellAccept > button {
  padding: 7px 16px 9px 16px !important;
  line-height: 1.12;
  letter-spacing: 1.25px;
  font-weight: 500;
}

.premiumUpsellDecline > button {
  padding: 8px 16px !important;
  line-height: 1.12;
  letter-spacing: 1.25px;
  font-weight: 500;
}

.compactButton > button {
  padding: 8px 16px !important;
}

.compactButton > button > span {
  line-height: 1.12 !important;
}
.coso-olo {
  font-weight: 100;
}

.standard {
  &:active,
  &:focus {
    color: var(--primary-submit-button-text-active) !important;
  }
}

.txtLarge {
  color: var(--secondary-submit-button-text) !important;
  border-color: var(--secondary-submit-button-border) !important;
  background-color: var(--secondary-submit-button-background) !important;

  &:active,
  &:focus {
    color: var(--secondary-submit-button-text-active) !important;
    border-color: var(--secondary-submit-button-border-active) !important;
    background-color: var(
      --secondary-submit-button-background-active
    ) !important;
  }
}

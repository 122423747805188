.sl_page
  width: 100%
  overflow: hidden

  &__container
    position: relative
    width: 450px
    margin: 30px auto 40px auto
    animation:
      name: fade-in-up
      duration: 0.3s
      timing-function: ease-in-out

    &__list
      padding-inline-start: 0
      margin: 20px
      margin-bottom: 30px

      &__item
        color: var(--color-global-grey)
        font:
          size: 16px
          weight: 500
        margin-bottom: 7px
        list-style: none
        line-height: 20px

        &:before
          content: "• "
          color: var(--color-orange)
          font-size: 42px
          line-height: 20px
          vertical-align: middle

        &__unit
          color: $blue
          font-weight: normal
          margin-left: 5px

    &__header
      text-align: center

      &__img
        width: 128px
        height: 128px

      &__title
        font:
          size: 28px
          weight: 400
        color: var(--color-global-grey)
        margin: 15px 0 10px 0
        text-align: center

      &__sub_title
        font:
          size: 14px
          weight: 400
        color: var(--color-global-grey)
        margin: 0
        text-align: center

    &__presets
      > div > ul
        margin-top: 24px
        padding: 0
      .form__select_buttons
        margin-top: 40px

    &__date_selects
      margin-top: 40px

    &__optional_products_title
      font:
        size: 18px
        weight: 600
      color: var(--color-global-grey)
      margin: 0px 0 10px 0
      text-align: center
      line-height: 1.17

@media(max-width: $mobile)
  .sl_page
    width: 100%
    padding-bottom: calc(env(safe-area-inset-bottom) + 40px)

    &__container
      width: 100%
      padding: 0 10px
      margin: 20px auto 50px auto

.footer {
  margin: 0 auto;
  margin-top: 44px;
  max-width: 400px;
}

.footer > div {
  margin: 0 auto;
  margin-bottom: 12px;
  max-width: 340px;
}

.footerText {
  margin: 0;
  color: #000;
  text-align: center;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;
}

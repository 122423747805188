.overlay {
  max-width: 482px;

  &::before,
  &::after {
    content: none;
  }

  @media (max-width: 460px) {
    &::before,
    &::after {
      content: '';
    }

    &:before {
      left: 0;
      background: linear-gradient(
        90deg,
        rgba(216, 216, 216, 0.99) 1%,
        rgba(238, 238, 238, 0) 100%
      );
    }

    &:after {
      right: 0;
      background: linear-gradient(
        270deg,
        rgba(216, 216, 216, 0.99) 1%,
        rgba(238, 238, 238, 0) 100%
      );
    }
  }
}

.container {
  max-width: 482px;
  margin: 0;
  padding: 12px 0 16px;
}

.button {
  margin: 0 25px;
}

.icon {
  width: 15px;
  height: 24px;
  fill: var(--color-yellow-3);

  &:hover {
    fill: var(--color-yellow-3);
  }
}

.wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}

.overlay {
  max-width: 450px;
  position: relative;
  overflow: hidden;
  overflow-y: visible;
}

.overlay::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  width: 25px;
  background: linear-gradient(
    90deg,
    var(--color-white) 0%,
    rgba(255, 255, 255, 0.001) 100%
  );
  z-index: 1;
}

.overlay::after {
  content: '';
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  width: 25px;
  background: linear-gradient(
    270deg,
    var(--color-white) 0%,
    rgba(255, 255, 255, 0.001) 100%
  );
}

.container {
  max-width: 450px;
  display: flex;
  overflow-x: scroll;
  overflow-y: visible;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
  scroll-snap-type: x mandatory;
  scroll-padding: 92px;
  -webkit-overflow-scrolling: touch;
  padding: 0 0 16px;
  position: relative;
}

.container::-webkit-scrollbar {
  /* WebKit */
  width: 0;
  height: 0;
}

.spacer {
  width: 8px;
  flex-shrink: 0;
}

.buttonWrapper {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

.buttonWrapper:first-child {
  justify-content: flex-end;
}

.button {
  appearance: none;
  background: transparent;
  margin: 0px 9px;
  padding: 5px 5px;
  border: 0;
  cursor: pointer;
  transition: all 0.2s ease-in-out;

  &:focus {
    outline: none;
  }
}

.icon {
  width: 20px;
  height: 44px;
}

@media screen and (max-width: 550px) {
  .button {
    display: none;
  }
}

.container {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 100%;

  .percentage {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 301;

    font-size: 14.3px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.4;
    letter-spacing: 0.25px;
    text-align: center;
    color: rgba(0, 0, 0, 0.87);

    svg {
      transform: rotate(0);
      position: relative;
      margin-top: -10px;
      margin-left: 6px;
    }
  }

  .goalReachedSvg {
    @media (max-width: 371px) {
      margin-top: -10px;
    }

    @media (max-width: 341px) {
      margin-top: -20px;
    }

    @media (max-width: 320px) {
      margin-top: -30px;
    }
  }

  .svgContainer {
    position: absolute;
    width: 130px;
  }

  svg {
    transform: rotate(127deg);
    position: absolute;
    top: 0;
    left: 0;
  }

  .restCircle {
    fill: none;
    stroke: url(#baseBar);
    stroke-width: 22px;
    stroke-dasharray: 250;
    stroke-opacity: 0.25;
  }

  .progressCircle {
    fill: none;
    stroke: url(#progressBar);
    stroke-width: 22px;
    stroke-dasharray: 452;
    stroke-dashoffset: 452;
    stroke-opacity: 1;
  }
}

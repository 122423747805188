.button {
  margin-bottom: 12px !important;
  text-transform: none !important;
  text-align: left;
}

.button:last-child {
  margin-bottom: 0px !important;
}

.label {
  justify-content: flex-start !important;
}

.imageContainer {
  display: flex;
  justify-content: center;
  min-width: 56px;
  margin-right: 12px;
}

.dropdown
  position: relative

  &.one-third
    display: inline-block
    width: 34%

    .Select-value
      right: unset !important
      left: 45px
      max-width: 150px
      text-overflow: ellipsis
      white-space: nowrap
      overflow: hidden

  &__input

    &:hover, &:focus, &:active
      outline: none

    &__img
      position: absolute
      left: 22px
      top: 0
      bottom: 0
      margin: auto
      display: flex
      align-items: center
      z-index: 2
      max-width: 20px
      width: 16px

    .Select-control
      width: 100%
      cursor: pointer

      .Select-input
        width: 100%
        font:
          size: 14px
          weight: 500
        color: $dark-text

        &:after
          position: absolute
          top: 18px
          right: 4px
          content: ""
          width: 0
          height: 0
          border-style: solid
          border-width: 6px 5px 0 5px
          border-color: $dark-text transparent transparent transparent
          z-index: 3

        input
          box-sizing: border-box !important
          position: relative
          width: 100% !important
          appearance: none
          height: 42px
          width: 100%
          background: var(--color-white)
          padding: 0 20px 0 30px
          border: 2px solid $grey
          border-radius: 20px
          z-index: 1
          cursor: pointer
          text-align: right
          border-top-right-radius: 0
          border-bottom-right-radius: 0
          border-right-width: 1px

          &:focus, &:active
            outline: none

      .Select-value, .Select-placeholder
        position: absolute
        right: 20px
        top: 15px
        font:
          size: 14px
          weight: 500
        color: $dark-text
        z-index: 2

      .Select-clear-zone
        display: none

    .Select-menu-outer
      border-bottom-right-radius: 4px
      border-bottom-left-radius: 4px
      background-color: var(--color-white)
      border: 1px solid var(--color-grey-20)
      border-top-color: var(--color-grey-21)
      box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06)
      box-sizing: border-box
      margin-top: -1px
      max-height: 200px
      position: absolute
      top: 100%
      width: 100%
      z-index: 1
      -webkit-overflow-scrolling: touch

    .Select-menu
      max-height: 150px
      overflow-y: auto

    .Select-option
      box-sizing: border-box
      background-color: var(--color-white)
      font:
        size: 14px
        weight: 500
      color: $dark-text
      cursor: pointer
      display: block
      padding: 8px 10px
      text-align: right

      &:last-child
        border-bottom-right-radius: 4px
        border-bottom-left-radius: 4px

      &.is-selected
        background-color: var(--color-grey-22)
        background-color: rgba(0, 126, 255, 0.04)
        color: var(--color-grey)

    .Select-noresults
      box-sizing: border-box
      color: var(--color-grey-19)
      font:
        size: 14px
        weight: 500
      cursor: default
      display: block
      padding: 8px 10px
      text-align: right

  &.has-error
    position: relative
    top: -30px

    .Select-control
      .Select-input
        input
          border-color: $red


.dropdown--default
  .Select-control
    .Select-input
      input
        border-radius: 20px
        padding-left: 58px
        text-align: left

      &:after
        right: 25px

    .Select-value, .Select-placeholder
      right: unset
      left: 60px
      bottom: 0
      top: 0
      margin: auto 0
      display: flex
      align-items: center

  .Select-option
    text-align: left

  &.has-error
    top: 0

@media(max-width: 450px)
  .dropdown
    &.one-third
      width: 48%

.container {
  display: flex;
  padding: 12px 16px 16px 16px;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  border-radius: 8px;
  border: 1px solid #29abe2;
  background: #e9f6fc;
  text-align: center;
}

.timerTitle {
  color: #000;
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.5px;
}

.timerContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
}

.timerBlock {
  display: flex;
  height: 64px;
  padding: 0px 4px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 4px;
  background: linear-gradient(180deg, #93d5f1 0%, #74c8ec 100%);
  min-width: 58px;
}

.timerValue {
  margin: 0;
  color: #202020;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
}

.seperator {
  color: #202020;
  text-align: center;
  font-size: 32px;
  font-style: normal;
  font-weight: 700;
  line-height: 38px;
}

.container {
  background: var(--primary-background-color);
  box-shadow: 0 5px 15px 0 var(--color-black-opacity5);
}

.content {
  color: var(--primary-title-color);
  font-size: 20px;
  font-weight: 500;
  margin: 25px 0 10px 0;
  text-align: center;
}

.subtext {
  color: var(--primary-title-color);
  font-size: 14px;
  font-weight: normal;
  margin: 25px 0 10px 0;
  text-align: center;
}

.red {
  font-weight: bold;
  color: var(--color-red-2);
}

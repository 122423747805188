:root {
  --premium-title-text: var(--color-global-text-opacity-8);

  --premium-subtitle-text: var(--color-global-grey);

  --premium-paragraph-text: var(--color-global-grey-2);

  --premium-nutrition-text: var(--color-global-grey-2);

  --premium-list-text: var(--color-global-grey-2);
  --premium-list-marker: var(--color-global-grey-2);

  --premium-link-title: var(--color-global-text-opacity-8);
  --premium-link-text: var(--color-global-black);
  --premium-link-text-background-color: #ededed;
  --premium-link-hover: #d4d4d4;
  --premium-link-image-background-color: #f8f8f8;
  --premium-link-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);

  --premium-card-title: var(--color-global-text-opacity-8);
  --premium-card-text: var(--color-global-grey-2);
  --premium-card-box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 1px 0 rgba(0, 0, 0, 0.14);
  --premium-card-background-color: #fafafa;
  --premium-card-text-background-color: #ededed;
  --premium-card-image-background-color: #f8f8f8;
}

.container {
  height: 10px;
  margin: 5px 0 5px;
  padding: 0 4px;
  justify-content: space-between;
}

.item {
  height: 10px;
  width: 10px;
  border-radius: 2px;
  background-color: var(--primary-calendar-event-background) !important;
  color: var(--primary-calendar-event-text) !important;
  font-size: 10px;
  font-weight: 900;
  display: flex;
  justify-content: center;
  align-items: center;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 27.5px;
  height: 100vh;

  @media (max-width: 458px) {
    padding-top: 23.5px;
    height: auto;
  }
}

.header {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  text-align: center;
  z-index: 3;

  @media (max-width: 458px) {
    padding: 0 28px;
  }
}

.nutrionistImage {
  width: 80px;
  height: 80px;
}

.nutrionistText {
  padding-top: 8px;
  margin: 0;
  color: var(--express-plan-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;
  padding-bottom: 21px;
}

.nutrionistTextBolded {
  margin: 0;
  color: var(--express-plan-text);
  font-size: 14px;
  font-style: normal;
  font-weight: 700;
  line-height: 22px;
  letter-spacing: 0.5px;
}

.nutrionistBubble {
  position: relative;
  margin: 0;
  padding: 12px;
  width: 100%;
  background-color: var(--express-plan-bubble-bg);
  color: var(--express-plan-bubble-text);
  border-radius: 8px;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 22px;
  letter-spacing: 0.5px;
  margin-bottom: 24px;
}

.nutrionistBubble::before {
  content: '';
  width: 0;
  height: 0;
  left: 48%;
  top: -10px;
  position: absolute;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 10px solid var(--express-plan-bubble-bg);
}

.carousel {
  padding-top: 97px;
  margin-top: -68px;
  min-height: 840px;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  background: var(--express-plan-carouesel-bg);

  @media (max-width: 458px) {
    min-height: 570px;
  }
}

.nonPurchasableCarousel {
  flex: 1;
  min-height: 600px;

  @media (max-width: 458px) {
    padding-bottom: 85px;
  }
}

.footerContainer {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  flex: 1;
  width: 100%;
  background: var(--express-plan-footer-bg);
  padding-bottom: 80px;

  @media (max-width: 458px) {
    padding-left: 8px;
    padding-right: 8px;
  }
}

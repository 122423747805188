.title {
  color: var(--primary-title-color);
  font-size: 24px;
  font-weight: 500;
  text-align: center;
  margin: 15px 0;
}

.buttons > form {
  margin-top: 12px;
}

.contextualModal {
  background: none;
  height: unset;
}

.contextualModal > div:first-of-type {
  width: 100%;
  height: 100%;
  margin-top: 0;
  border-radius: 3px;
}

.contextualModal > div:first-of-type > div:nth-of-type(3) {
  margin-top: 40px;
}

@media (max-width: 460px) {
  .contextualModal > div:first-of-type > div:nth-of-type(2) {
    margin-top: 26px;
  }

  .contextualModal > div:first-of-type > div:nth-of-type(3) {
    margin-top: 45px;
  }
}

.chatUpsell > div:first-of-type {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  margin: 0;
  animation: none;

  @media (max-width: 475px) {
    display: block;
    position: fixed;
    left: 0;
    top: unset;
    transform: unset;
  }
}

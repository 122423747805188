@import './_variables'

@keyframes fade-in
  from
    opacity: 0
  to
    opacity: 1

@keyframes fade-in-up
  from
    opacity: 0
    bottom: -40px
  to
    opacity: 1
    bottom: 0

@keyframes fade-in-down
  from
    opacity: 0
    top: -40px
  to
    opacity: 1
    bottom: 0

@keyframes fade-out-down
  from
    opacity: 1
    bottom: 0
  to
    opacity: 0
    bottom: -40px

.fade-out-down
  animation: 
    name: fade-out-down
    duration: 0.3s
    timing-function: ease-in-out

@keyframes fade-out-up
  from
    opacity: 1
    bottom: 0
  to
    opacity: 0
    top: -40px

@keyframes pop-in
  from
    transform: scale(0)
  to
    transform: scale(1)

@keyframes stroke
  100%
    stroke-dashoffset: 0

@keyframes bounce-in
  0%
    transform: scale(0)
  40%
    transform: scale(1.4)
  60%
    transform: scale(0.7)
  75%
    transform: scale(1.2)
  90%
    transform: scale(0.9)
  100%
    visibility: visible
    transform: scale(1)

@keyframes scale
  0%, 100%
    transform: none
  50%
    transform: scale3d(1.1, 1.1, 1)

@keyframes fill_green
  100%
    box-shadow: inset 0px 0px 0px 30px $green

@keyframes fill_red
  100%
    box-shadow: inset 0px 0px 0px 30px $red

@keyframes countdown
  0%
    top: -50px
    opacity: 0
    transform: scale(1)
  10%
    opacity: 0.6
  50%
    top: 0
    transform: scale(1.3)
    opacity: 1
  90%
    opacity: 60px
  100%
    top: 50px
    transform: scale(1)
    opacity: 0

@keyframes loading
  0%
    transform: rotate(0)
  100%
    transform: rotate(360deg)

@keyframes loading-circle
  0%
    stroke-dashoffset: 0
  100%
    stroke-dashoffset: -600

@keyframes spin-a
  0%
    transform: rotate(90deg)
  50%
    transform: rotate(180deg)
  75%
    transform: rotate(270deg)
  100%
    transform: rotate(360deg)

@keyframes spin-b
  0%
    transform: rotate(90deg)
  25%
    transform: rotate(90deg)
  25%
    transform: rotate(180deg)
  75%
    transform: rotate(270deg)
  100%
    transform: rotate(360deg)

@keyframes spin-c
  0%
    transform: rotate(90deg)
  25%
    transform: rotate(90deg)
  50%
    transform: rotate(180deg)
  50%
    transform: rotate(270deg)
  100%
    transform: rotate(360deg)

@keyframes spin-d
  0%
    transform: rotate(90deg)
  25%
    transform: rotate(90deg)
  50%
    transform: rotate(180deg)
  75%
    transform: rotate(270deg)
  75%
    transform: rotate(360deg)
  100%
    transform: rotate(360deg)

@keyframes popout
  0%
    transform: scale(1)
    opacity: 1
  100%
    transform: scale(3)
    opacity: 0

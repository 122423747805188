.container {
  height: 100%;
  padding: 0 16px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal {
  position: relative;
  width: 100%;
  max-width: 382px;
  padding: 24px;
  border-radius: 4px;
  background: #f6f4f3;
  box-shadow: 0px 12px 16px 0px rgba(104, 104, 104, 0.16);
  padding: 68px 16px 0 16px;
}

.crossIcon {
  position: absolute;
  top: 16px;
  right: 16px;
  cursor: pointer;

  &:hover,
  &:active {
    fill: var(--premium-sub-warning-exit-icon-fill);
  }

  [dir='rtl'] & {
    right: unset;
    left: 16px;
  }
}

.icon {
  position: absolute;
  height: 104px;
  width: 104px;
  left: 50%;
  top: 0;
  margin-left: -52px;
  margin-top: -52px;
}

.title {
  color: #000;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 24px;
}

.body {
  color: #464646;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 24px;
}

.buttons {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  margin-top: 28px;
  width: 100%;
  max-width: 100%;
}

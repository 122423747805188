.container {
  flex-shrink: 0;
  scroll-snap-align: center;
  height: 86px;
  width: 82px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  border-radius: 3px;
  margin: 0 5px;
  border-radius: 10px;
  border: solid 2px var(--color-grey-23);
  background-color: var(--color-grey-23);
  user-select: none;
  color: var(--color-grey-2);
  cursor: pointer;
  outline: none;

  &:focus,
  &.isSelected {
    border-color: darken(#ffe7f2, 6);
    background: var(--color-grey-24);
  }

  &:hover {
    border-color: darken(#ffe7f2, 4);
  }
}

.done {
  composes: container;

  border-color: var(--color-grey-23);
  background: var(--color-grey-23);
}
.today {
  composes: container;

  border-color: darken(#ffe7f2, 6);
  background: var(--color-white);
}

.future {
  composes: container;

  border-color: var(--color-grey-23);
  background: var(--color-white);
}

.dayName {
  margin: 5px 0 0 0;
  font-size: 16px;
  font-weight: normal;
}

.date {
  margin: 0;
  color: var(--primary-title-color);
  font-size: 35px;
  font-weight: 500;
}

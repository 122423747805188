.container {
  background: var(--color-white);
}

.title {
  color: var(--color-grey);
}

.description {
  color: var(--color-grey);
}

.value {
  color: var(--color-blue);
}

.slider {
  color: var(--color-blue);
}

.arrow {
  border-color: transparent transparent transparent var(--color-blue);
}

.input {
  &::-webkit-slider-thumb {
    background: var(--color-blue);
    border: 4px solid var(--color-white);
  }

  &::-moz-range-thumb {
    background: var(--color-blue);
    border: 4px solid var(--color-white);
  }

  &::-moz-range-track {
    background: var(--color-blue);
  }

  &::-ms-thumb {
    background: var(--color-blue);
    border: 4px solid var(--color-white);
  }

  &::-webkit-slider-runnable-track {
    background: var(--color-blue);
  }

  &:focus::-webkit-slider-runnable-track {
    background: var(--color-blue);
  }

  &:active &::-webkit-slider-thumb {
    background: var(--color-blue);
  }
}

.headerContainer {
  padding-top: 12px;
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: center;

  @media (max-width: 475px) {
    padding: 12px 24px 0;
  }
}

.leftSide {
  display: flex;
  flex-direction: column;
  text-align: left;
  gap: 4px;
}

.title {
  margin: 0;
  color: var(--workouts-header-title);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.time {
  margin: 0;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 18px;
  letter-spacing: 0.5px;
  color: var(--workouts-header-time);

  [dir='rtl'] & {
    text-align: right;
  }
}

.rightSide {
  display: flex;
  flex-direction: row;
}

.buttonNormalize {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  text-align: inherit;
  font: inherit;
  border-radius: 0;
  appearance: none;
}

.exitBtn {
  width: 100%;
  height: 100%;
  max-height: 28px;
  max-width: 28px;
}

.exitBtn:hover {
  cursor: pointer;
}

.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  background: var(--color-orange-2);
  border-radius: 12px;
  margin: 100px auto;
  max-width: 420px;
  background-clip: padding-box;
  border: 8px solid transparent;
  padding: 72px 16px 48px;
  outline: none;
}

.calendar {
  position: absolute;
  top: -52px;
  width: 104px;
  height: 104px;
  border-radius: 3px;
  box-shadow:
    0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 1px 18px 0 rgba(0, 0, 0, 0.12),
    0 6px 10px 0 rgba(0, 0, 0, 0.14);
  background-color: var(--color-grey-27);
  border-top: 15px solid var(--color-yellow-2);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 8px 3px 7px;
}

.dayName {
  font-size: 16px;
  color: var(--color-grey);
}

.date {
  font-size: 22px;
  font-weight: bold;
  color: var(--color-grey);
  white-space: nowrap;
}

.events {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

.event {
  width: 15px;
  height: 15px;
  border-radius: 2px;
  background-color: var(--color-yellow-3);
}

.text {
  font-size: 16.4px;
  line-height: 1.47;
  letter-spacing: 0.15px;
  text-align: center;
  color: var(--color-black-opacity8);
}

.question {
  font-size: 20.4px;
  font-weight: 500;
  letter-spacing: 0.25px;
  text-align: center;
  color: var(--color-black-opacity8);
}

.inputs {
  max-width: 140px;
}

.input {
  margin-bottom: 28px !important;
  width: 140px;

  // Remove spinners on Safari, Chrome
  & input[type='number']::-webkit-inner-spin-button {
    appearance: none;
    margin: 0;
  }

  // Remove spinners on Firefox
  & input[type='number'] {
    -moz-appearance: textfield;
  }
}

.dayName {
  text-transform: capitalize;
}

.container {
  height: unset;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 76px;
  margin-bottom: 18px;
}

.logo {
  position: absolute;
}

.text {
  font-size: 12.3px;
  font-weight: 500;
  line-height: 1.3;
  letter-spacing: 2px;
  text-align: center;
  text-transform: uppercase;
  color: var(--loading-text);
  margin: 0;
}

.progressColor {
  > svg {
    color: #569a4f;
  }
}

.container {
  width: 100%;
  overflow: hidden;
}

.slidesContainer {
  display: flex;
  flex-wrap: nowrap;
  transition: 0.3s all ease-out;
}

.slide {
  flex: 0 0 100%;
}

.controls {
  display: flex;
  flex-wrap: nowrap;
  justify-content: center;
}

.controlBtnWrapper,
.controlBtnWrapper:focus-within,
.controlBtnWrapper:hover,
.controlBtnWrapper:active {
  border: 0;
  background: transparent;
  outline: 0;
  box-sizing: border-box;
  display: block;
  padding: 0;
  margin: 0;
}

.defaultControl,
.defaultControlActive {
  border-radius: 50%;
  margin: 0 4px;
}

.defaultControl {
  width: 8px;
  height: 8px;
  background-color: #fff;
}
.defaultControlActive {
  width: 12px;
  height: 12px;
  background-color: #f6c340;
}

.form
  &__timer
    display: block

    &__bg
      position: relative
      background: darken($grey, 30)
      border: none
      width: 100%
      height: 42px
      border-radius: 20px
      margin-top: 10px
      cursor: pointer
      box-shadow: 0px 7px 20px 0px rgba(148, 164, 174, 0.5), inset 0px 3px 15px 0px rgba(0,0,0,0.4)
      transition: background $animation-speed $animation-type
      overflow: hidden
      z-index: 1

      &:hover
        background: darken($grey, 40)
        box-shadow: 0px 7px 20px 0px rgba(148, 164, 174, 0.7), inset 0px 3px 15px 0px rgba(0,0,0,0.4)

      &:active, &:focus
        background: darken($grey, 40)
        outline: none
        box-shadow: 0px 7px 20px 0px rgba(148, 164, 174, 0.4), inset 0px 3px 15px 0px rgba(0,0,0,0.4)

    &__label
      position: relative
      font:
        size: 13px
        weight: 700
        family: 'Poppins', sans-serif
      color: var(--color-white)
      width: 100%
      text-align: center
      z-index: 3
      padding: 15px 10px 13px 10px
      background-color: transparent
      border: none

      &__pop
        position: relative
        margin: 0
        padding: 0
        animation: popout 1s ease-in-out

    &__fill
      position: absolute
      width: 0
      height: 42px
      top: 0
      left: 0
      background: $green
      z-index: 2
      transition: all 1s linear


@media(max-width: $mobile)
  .form
    &__timer
      &.large-fixed
        position: fixed
        background: var(--color-white)
        padding: 7px
        margin: 0
        bottom: calc(60px + env(safe-area-inset-bottom))
        left: 0
        right: 0
        z-index: 1000
        box-shadow: 0px -0px 10px 3px rgba(0,0,0,0.15)

        .form__timer__button
          margin: 0
          box-shadow: none

        .form__timer__bg
          margin: 0
          box-shadow: inset 0px 3px 10px 0px rgba(0,0,0,0.4)

      &.large-in-group
        .form__timer__bg, .form__timer__button
          margin: 0

.container {
  background: var(--color-grey-5);
  background: linear-gradient(
    to bottom,
    var(--color-grey-5) 0%,
    var(--color-grey-5) 100%
  );

  &:hover {
    background: var(--color-grey-30);
  }
}

.container {
  margin: 12px 0;
}

.title {
  font-size: 12px;
  color: var(--color-grey);
  text-align: center;
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 16px;
}

.images {
  display: flex;
  justify-content: center;
  margin: 0;

  svg {
    margin: 0 8px;
  }
}

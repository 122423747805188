.buttonNormalize {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  text-align: inherit;
  font: inherit;
  border-radius: 0;
  appearance: none;
}

.container {
  display: flex;
  flex-direction: column;
  font-style: normal;
}

.container:hover {
  cursor: pointer;
}

.noPointer:hover {
  cursor: default;
}

.topPart {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}

.iconSvg {
  width: 44px;
  height: 44px;
  object-fit: cover;
  background-color: var(--program-workout-active-svg-bg);
  border-radius: 4px;
}

.titles {
  display: flex;
  flex-direction: column;
  text-align: left;
  margin-left: 8px;

  [dir='rtl'] & {
    text-align: unset;
    margin-right: 8px;
  }
}

.smallTitle {
  margin: 0;
  color: var(--program-workout-active-small-title);
  font-size: 14px;
  font-weight: 400;
  line-height: 1.4;
  letter-spacing: 0.5px;
}

.bigTitle {
  margin: 0;
  color: var(--program-workout-active-big-title);
  font-size: 20px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: 0.5px;
}

.midPart {
  margin-top: 4px;
  margin-bottom: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  min-height: 38px;
  gap: 8px;
  flex-wrap: wrap;
  max-width: 450px;
}

.botPart {
  display: flex;
  position: relative;
  border-radius: 3px;
  max-width: 418px;
  width: 100%;
}

.botPartInactive {
  height: unset;
}

.workoutThumbnail {
  border-radius: 3px;
  object-fit: cover;
  height: 176px;
  width: 100%;
  background: var(--program-workout-active-thumbnail-bg);
}

.workoutDone {
  position: absolute;
  right: 0;
  margin-right: 8px;
  margin-bottom: 8px;
  bottom: 0;

  [dir='rtl'] & {
    left: 0;
    right: unset;
    margin-right: unset;
    margin-left: 8px;
    -webkit-transform: scaleX(-1);
    transform: scaleX(-1);
  }
}

.videoContainer {
  display: flex;
  flex-direction: column;
}

.workoutVideo {
  height: 176px;
  width: 100%;
  object-fit: cover;
  border-radius: 3px 3px 0px 0px;
  background: var(--program-workout-inactive-bg-video);
}

.videoDisclaimer {
  display: flex;
  padding: 8px 12px;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;
  border-radius: 0px 0px 3px 3px;
  background: var(--program-workout-inactive-bg);
  margin: 0;
}

.linkText {
  color: var(--program-workout-inactive-link-text);
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;
}

.accentedText {
  color: var(--program-workout-inactive-accent-text);
  text-decoration: underline;
}

.restDayContainer {
  display: flex;
  flex-direction: column;
  gap: 12px;
  width: 100%;
}

.restText {
  color: var(--program-workout-active-rest);
  margin: 0;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 22px;
  letter-spacing: 0.5px;
}

.background {
  background: url('../assets/images/login_bg_hg.jpg') no-repeat center center
    fixed;
  background-size: cover;
  background-image: image-set(
    url('../assets/images/login_bg_hg.jpg') 1x,
    url('../assets/images/login_bg_hg@2x.jpg') 2x,
    url('../assets/images/login_bg_hg@3x.jpg') 3x
  );
}

.standard {
  color: var(--primary-submit-button-text) !important;
  background: var(--primary-submit-button-background) !important;

  &:hover {
    background: var(--primary-submit-button-background-hover) !important;
  }

  &:active,
  &:focus {
    background: var(--primary-submit-button-background-active) !important;
  }
}

.large {
  text-transform: uppercase;
}

.text-large {
  font-size: 15px;
  font-weight: 600;
  width: 100%;
  background: none;
  color: var(--secondary-submit-button-text) !important;
  background: transparent !important;
  box-shadow: none;
  text-align: center;

  &:hover {
    text-decoration: underline;
    background: none;
    box-shadow: none;
  }
}

.txtLarge {
  background: var(--secondary-submit-button-background) !important;
  border-color: var(--secondary-submit-button-border);

  &:hover {
    color: var(--secondary-submit-button-text-hover) !important;
    background-color: var(
      --secondary-submit-button-background-hover
    ) !important;
    text-decoration: underline;
    border-color: var(--secondary-submit-button-border-hover) !important;
  }
}

.text {
  color: var(--tertiary-submit-button-text) !important;
  background: none !important;

  &:hover {
    background: none !important;
    box-shadow: none !important;
  }
}

.premiumUpsellAccept {
  border-radius: 4px !important;
  box-shadow:
    0 1px 3px 0 rgba(0, 0, 0, 0.2),
    0 2px 1px -1px rgba(0, 0, 0, 0.12),
    0 1px 1px 0 rgba(0, 0, 0, 0.14) !important;
  padding: 15px 25px 13px 25px !important;
  line-height: 1.12;
  letter-spacing: 1.25px;
}

.compactButton {
  line-height: 1.12 !important;
  padding: 8px 16px !important;
}

.premiumUpsellDecline {
  margin: 40px 0 !important;
  border: solid 1px #bbbbbb;
  box-shadow: none !important;
  border-radius: 4px !important;
  background: #fff !important;
  color: #4f4f4f !important;
  padding: 15px 25px 13px 25px !important;
  line-height: 1.12;
  letter-spacing: 1.25px;
}

.premiumUpsellDecline:hover {
  background: rgba(0, 0, 0, 0.05) !important;
}

.submitButtonIcon {
  margin-right: 8px;
}

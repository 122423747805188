.form
  &__slider
    display: block
    appearance: none
    width: 200px
    height: 200px
    background: transparent
    margin: 0 auto
    transform: rotate(-90deg)

    &__value
      position: absolute
      width: 38px
      right: 56%
      bottom: 135px
      color: $blue
      font:
        size: 18px
        weight: 600

      &__arrow
        position: absolute
        top: 2px
        left: -15px
        width: 0
        height: 0
        border-style: solid
        border-width: 7px 0 7px 13px
        border-color: transparent transparent transparent $blue


    &::-webkit-slider-thumb
      appearance: none
      height: 42px
      width: 42px
      border-radius: 50%
      background: $blue
      cursor: pointer
      margin-top: -11px
      border: 4px solid var(--color-white)
      transition: all $animation-speed $animation-type

    &::-moz-range-thumb
      height: 42px
      width: 42px
      border-radius: 50%
      background: $blue
      cursor: pointer
      margin-top: -11px
      border: 4px solid var(--color-white)
      transition: all $animation-speed $animation-type

    &::-ms-thumb
      height: 42px
      width: 42px
      border-radius: 50%
      background: $blue
      cursor: pointer
      margin-top: -11px
      border: 4px solid var(--color-white)
      transition: all $animation-speed $animation-type

    &::-webkit-slider-runnable-track
      width: 100%
      height: 20px
      cursor: pointer
      background: $blue
      border-radius: 30px

    &:focus::-webkit-slider-runnable-track
      background: $blue

    &:active
      &::-webkit-slider-thumb
        background: darken($blue, 5)
        transform: scale(1.1)

    &:focus
      outline: none

    &::-ms-track
      width: 100%
      cursor: pointer
      background: transparent
      border-color: transparent
      color: transparent

.modal
  position: fixed
  width: 100%
  height: 100vh
  top: 0
  left: 0
  right: 0
  bottom: 0
  background: var(--color-black-opacity6)
  z-index: 9999
  animation:
    name: fade-in
    duration: 0.5s
    timing-function: easy-in-out

  &__diary
    &__desc
      margin: 10px 0

      &__value
        display: block
        font:
          size: 21px
          weight: 600
        color: $blue
        text-align: center

      &__span
        display: block
        font:
          size: 12px
          weight: normal
        color: $dark-text
        text-align: center

  &.open-true
    display: block

  &.open-false
    display: none

  &__body
    display: block
    position: relative
    background: var(--color-white)
    width: $mobile
    padding: 15px 25px
    border-radius: 10px
    margin: 37px auto 0 auto
    box-shadow: 0 5px 15px 0 rgba(0,0,0,0.5)
    overflow-y: auto
    animation:
      name: fade-in-up
      duration: 0.5s
      timing-function: ease-in-out

    &__close_btn
      position: absolute
      right: 9px
      top: 3px
      width: 25px
      height: 20px
      cursor: pointer

      [dir='rtl'] &
        right: unset
        left: 16px

      &:before, &:after
        content: ''
        position: absolute
        width: 21px
        height: 4px
        background-color: darken($grey, 5)
        border-radius: 2px
        top: 16px

      &:before
        transform: rotate(45deg)
        left: 2px

      &:after
        transform: rotate(-45deg)
        right: 2px

      &:hover, &:active, &:focus
        &:before, &:after
          background-color: darken($grey, 10)

    &__head
      &__title
        color: $dark-text
        font:
          size: 24px
          weight: 500
        text-align: center
        margin: 15px 0

    &__content
      position: relative
      margin: 20px 0

      &__h4
        color: $dark-text
        font:
          size: 20px
          weight: 500
        margin: 25px 0 10px 0
        text-align: center

      &__sub
        font:
          size: 14px
          weight: 500
        color: $light-text
        text-align: center
        margin: 0

      &__text
        color: $dark-text
        font:
          size: 14px
          weight: normal
        margin: 25px 0 10px 0
        text-align: center

    &__footer
      &.with-btn
        padding: 5px 0 15px 0

@media(max-width: $mobile)
  .modal

    &__body
      position: fixed
      bottom: 0
      left: 0
      right: 0
      width: 100%
      height: auto
      max-height: 100%
      padding: 15px 25px 35px 25px
      border-radius: 0
      margin: 0 auto
      overflow: auto


  .form
    &__slider
      &__value
        right: 57.5%

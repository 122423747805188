.wrapper {
  display: block;
  position: sticky;
  top: 0;
  z-index: 99;
}

.container {
  min-height: 40px;
  box-shadow:
    0 3px 5px -1px rgba(0, 0, 0, 0.2),
    0 6px 10px 0 rgba(0, 0, 0, 0.14);
  background: linear-gradient(0deg, #fff17c, #ffdf46);
  z-index: 2;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 16.4px;
  line-height: 1.47;
  letter-spacing: 0.15px;
  color: var(--color-black);
  padding: 8px 16px;
  padding-top: calc(env(safe-area-inset-top) + 8px);
  margin-bottom: calc(env(safe-area-inset-top) * -1);
}

.icon {
  margin-right: 16px;
  color: var(--color-red-6);
}

.link {
  text-decoration: none;

  &:hover,
  &:focus,
  &:active {
    text-decoration: none;
  }
}

.container {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  color: inherit;
  max-width: 100%;
}

.text {
  flex: 0 1 auto;
  min-width: 0;
  overflow: hidden;
  text-overflow: ellipsis;
}

.button,
.button:focus,
.button:focus-visible,
.button:focus-within {
  border: 0;
  padding: 0;
  margin: 0;
  background: none;
  display: flex;
  align-items: center;
  color: inherit;
  margin-left: 4px;
  flex: 0 0 auto;
  [dir='rtl'] & {
    margin-right: 4px;
    margin-left: 0px;
  }
}

.button:hover {
  opacity: 0.75;
}

.button:active {
  opacity: 0.5;
}

.alert {
  border-radius: 6px;
  background: var(--copy-text-alert-bg);
  color: var(--copy-text-alert-color);
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 24px;
  position: fixed;
  padding: 8px 16px;
  top: 24px;
  margin: 0 auto;
  left: 50%;
  transform: translateX(-50%);
  box-shadow: 0px 2px 4px 0px rgb(0 0 0 / 40%);
  opacity: 0;
  display: none;

  @media (min-width: 768px) {
    top: 74px;
  }
}

.alertActive {
  display: block;
  animation: fadeIn 250ms ease-out;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

.link {
  text-decoration: none;
}

.container {
  border-radius: 3px;
  background-color: var(--color-orange-2);
  padding: 16px;

  @media (max-width: 450px) {
    margin-bottom: 40px;
  }
}

.header {
  display: flex;

  .icon {
    height: 48px;
    width: 48px;
  }

  .title {
    flex: 1;
    margin: 0 0 16px 16px;
    font-size: 20.4px;
    font-weight: 500;
    letter-spacing: 0.25px;
    color: var(--color-black-opacity8);
    text-decoration: none;
  }
}

.text {
  margin: 0;
  font-size: 16.4px;
  line-height: 1.47;
  letter-spacing: 0.15px;
  text-align: center;
  color: var(--color-black-opacity8);
}

.meal_survey_page
  width: 100%
  overflow: hidden

  &__container
    position: relative
    width: 450px
    margin: 20px auto 40px auto
    animation:
      name: fade-in-up
      duration: 0.3s
      timing-function: ease-in-out

    &__load
      position: relative
      height: 70px
      margin-top: 20px

    &__step
      position: relative
      margin: 20px 0 20px 0
      animation:
        name: fade-in-up
        duration: 0.3s
        timing-function: ease-in-out

      &__loading
        text-align: center
        color: $green
        font:
          size: 14px
          weight: 500
        margin-top: 30px

      &__title
        font:
          size: 28px
          weight: 400
        color: $dark-text
        margin: 15px 0 20px 0
        text-align: center

        &.with_sub_title
          margin-bottom: 10px

      &__sub_title
        font:
          size: 14px
          weight: 400
        color: $dark-text
        margin: 0 0 25px 0
        text-align: center

  &__progress
    font:
      size: 16px
      weight: 500
      style: italic
    margin-top: 25px
    color: $green
    text-align: center

@media(max-width: $mobile)
  .meal_survey_page
    width: 100%
    padding-bottom: 40px

    &__progress
      margin-top: 20px

    &__container
      width: 100%
      padding: 0 10px
      margin: 10px auto 40px auto

.container {
  height: 100%;
  padding: 0 28px;
  overflow: auto;
}

.loading {
  margin-bottom: 76px;
}

.modal {
  background: #fff;
  border-radius: 12px;
  width: 100%;
  max-width: 358px;
  padding: 24px;
  margin: 0 auto;
  margin-top: 48px;
  margin-bottom: 48px;
}

.title {
  color: #000;
  text-align: center;
  font-size: 24px;
  font-weight: 700;
  line-height: 32px;
  margin-bottom: 8px;
}

.info {
  color: var(--workouts-unsub-info);
  font-size: 14px;
  text-align: center;
  line-height: 22px;
  margin-bottom: 16px;
}

.bold {
  font-weight: 700;
}

.cta {
  background: var(--workouts-unsub-cta-bg);
  border-radius: 16px;
  overflow: hidden;
  padding-bottom: 16px;
}

.transformation {
  margin: 0 auto;
  overflow: hidden;
  height: 196px;
  margin-bottom: 12px;
  position: relative;

  .transformationShadow {
    background: var(--workouts-unsub-shadow);
    position: absolute;
    height: 78px;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 2;
  }
}

.image {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.ctaTitle {
  color: #000;
  text-align: center;
  font-size: 22px;
  font-weight: 700;
  line-height: 32px;
  padding: 0 24px;
  margin-bottom: 8px;
  word-break: break-word;
}

.ctaTitleAccent {
  color: var(--workouts-unsub-cta-accent);
}

.ctaPricing {
  color: #000;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
  line-height: 24px;
  padding: 0 24px;
  margin-bottom: 4px;
}

.standardPrice {
  text-decoration: line-through;
}

.offerPrice {
  color: var(--workouts-unsub-cta-accent);
  font-weight: 700;
}

.ctaSubText {
  color: #000;
  text-align: center;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.buttons {
  margin-top: 32px;

  & > div {
    margin-bottom: 16px;

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.submitButton button {
  min-height: 40px;
}

.link {
  text-decoration: none;
  font-size: 14.3px !important;
  font-weight: 500 !important;
  letter-spacing: 1.25px !important;

  &:hover {
    text-decoration: underline;
  }
}

.block {
  display: block;
  border: none;
  border-radius: 20px;
  padding: 10px 10px 9px 10px;
  margin-top: 10px;
  cursor: pointer;
  float: none;
  text-align: center;
}

.noDecoration {
  text-decoration: none;
  outline: none;
}

.shopping {
  composes: noDecoration;

  & > button {
    color: var(--secondary-accent-color-dk);
    font-size: 14px;
    font-weight: 500;
    line-height: 1.33;
    letter-spacing: 1.05px;
    width: unset;
  }
}

.block {
  flex: 1;
  width: 100%;
}

.blocky {
  composes: link;

  &:active,
  &:focus {
    background: var(--color-yellow);
  }
}

.disabledLink {
  pointer-events: none;
}

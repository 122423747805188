.form
  &__select_buttons
    margin-top: 30px

    &.with-label
      margin-top: 20px

      .form__select_buttons--label
        display: block
        font:
          size: 15px
          weight: 600
        color: $dark-text
        margin: 0 0 5px 20px
    
    &.small
      width: 50%
      display: inline-block
      vertical-align: bottom

      .form__select_buttons__label.left
        border-radius: 0
        border-left-width: 1px
    
    &.error
      margin: 9px 0 18px 0

    &__label
      display: block
      font:
        size: 14px
        weight: 500
      line-height: 19px
      color: $dark-text
      padding: 9.5px
      border: 2px solid $grey
      border-radius: 20px
      cursor: pointer
      width: 100%
      float: left
      text-align: center

      &__desc
        display: block
        font:
          size: 12px
          weight: 400
          style: italic
        line-height: 14px
        margin-top: 3px

      &.left
        width: 50%
        border-top-right-radius: 0
        border-bottom-right-radius: 0
        border-right-width: 1px

      &.right
        width: 50%
        border-top-left-radius: 0
        border-bottom-left-radius: 0
        border-left-width: 1px 

    &__block
      clear: both

      .form__select_buttons__label.large
        border:
          radius: 0
          top-width: 1px
          bottom-width: 1px
      
      &:first-of-type
        .form__select_buttons__label.large
          border-top:
            width: 1px
            left-radius: 20px
            right-radius: 20px

      &.last
        .form__select_buttons__label.large
          border-bottom:
            width: 1px
            left-radius: 20px
            right-radius: 20px

    &__radio
      display: none

      &:checked + .form__select_buttons__label
        background: $blue
        border-color: darken($blue, 3)
        color: var(--color-white)
        text-shadow: 0 1px 2px var(--color-black-opacity6)

.label {
  font-size: 15px;
  font-weight: 600;
  color: var(--primary-title-color);
  margin-left: 20px;
}

.input {
  appearance: none;
  font-size: 14px;
  font-weight: 500;
  color: var(--primary-title-color);
  height: 42px;
  width: 100%;
  padding: 0 15px 0 50px;
  border: 2px solid var(--color-grey-6);
  border-radius: 20px;
}

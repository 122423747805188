.container {
  background: none;
  background-size: 100%;
  background-repeat: no-repeat;
  border: none;
  border-radius: 0;
  height: 72px;
  width: 72px;
  overflow: visible;

  &:before {
    content: '';
    height: 10px;
    width: 100%;
    background-color: var(--secondary-calendar-header);
    border-radius: 2px 2px 0 0;
  }
}

.done {
  background-clip: content-box;
  background-position: center bottom;
  background-image: var(--primary-calendar-past-bg-image);

  &:hover {
    border-color: transparent;
  }

  @media (hover: hover) {
    &:hover {
      background-image: var(--primary-calendar-past-bg-image-hover);
      box-shadow: none;
      filter: none;
    }
  }

  &:focus,
  &.isSelected {
    border: none;
    background: none;
    background-size: 100%;
    background-repeat: no-repeat;
    background-position: center bottom;
    background-image: var(--primary-calendar-past-bg-image-selected);
    box-shadow: none;
  }

  &:before {
    background: none;
  }
}
.today,
.future {
  background-clip: content-box;
  border: solid 1px var(--primary-calendar-resting-border);
  background-color: var(--primary-calendar-resting-background);
  border-radius: 3px;

  &:hover {
    border-color: transparent;
  }

  @media (hover: hover) {
    &:hover {
      box-shadow: none;
      padding: 0;
      border: solid 1px var(--primary-calendar-resting-border-hover);
      background-color: var(--primary-calendar-resting-background-hover);
    }
  }

  &:focus,
  &.isSelected {
    background-clip: none;
    border: none;
    box-shadow: var(--primary-calendar-selected-boxshadow);
    background-color: var(--primary-calendar-selected-background);
  }
}

.today {
  &:before {
    background: var(--primary-calendar-header);
  }
}

.today {
  &:focus,
  &.isSelected {
    border: none;
  }
}

.dayName {
  font-size: 12px;
  margin-top: 6px;
  color: var(--color-global-grey-2);
  text-transform: capitalize;
}

.date {
  font-size: 16px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: var(--color-global-grey-2);
  width: 100%;
  padding: 1px 4px 0;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

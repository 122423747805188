.success {
  background: var(--secondary-accent-color);
  border: 1px solid darken(#52cc7a, 7);
}

.error {
  background: var(--color-red-4);
  border: 1px solid darken(#cc5252, 7);

  @media (max-width: 458px) {
    top: 0;
    width: 100%;
    position: fixed;
  }
}

.content {
  font-size: 20.4px;
  font-weight: 500;
  letter-spacing: 0.25px;
}

.buttons {
  max-width: 140px;
  margin: 28px auto;
}

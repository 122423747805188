.buttonNormalize {
  background-color: transparent;
  border: none;
  margin: 0;
  padding: 0;
  text-align: inherit;
  font: inherit;
  border-radius: 0;
  appearance: none;
}

.backButton {
  display: inline-flex;
  height: 28px;
  padding: 4px 16px 4px 12px;
  align-items: center;
  gap: 6px;
  flex-shrink: 0;
  border-radius: 17px;
  border: 1px solid #bbb;
  background: #fff;
}

.backButton:hover {
  cursor: pointer;
  background-color: rgba(102, 102, 102, 0.1);
}

.backImage {
  width: 12px;
  height: 12px;
  object-fit: cover;

  [dir='rtl'] & {
    transform: scaleX(-1);
  }
}

.backLabel {
  color: #666;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 18px;
  letter-spacing: 0.5px;
}
